import styled from 'styled-components';
import AppBar from '@mui/material/AppBar';
import Button from '@mui/material/Button';
import Toolbar from '@mui/material/Toolbar';

export const Bar = styled(AppBar)`
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  box-shadow: none;
`;

export const ButtonContainer = styled(Button)`
  margin: 0 10px;
  padding: 0;
  color: ${({ theme }) => theme.colors.white};
`;

export const ToolbarWrapper = styled(Toolbar)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 50px;
`;

export const Logo = styled.img`
  height: 25px;
`;

export const LabNameLabel = styled.div`
  color: rgba(0, 0, 0, 0.87);
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.5;
  display: inline-flex;
`;
