import { string, object } from 'yup';

const validationSchema = t =>
  object().shape({
    documentId: string().required(t('etl.step1.required')),
    importTemplateType: string(),
    templateId: string(),
  });

export default validationSchema;
