import mapValues from 'lodash/mapValues';

export default value => {
  const result = mapValues(value, function (o) {
    if (o) {
      return o;
    }

    return '';
  });

  return result;
};
