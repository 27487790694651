import { useCallback, useState } from 'react';
import { ConfirmationDialog } from 'components/ConfirmationDialog';

type Props = {
  bodyText: string;
  confirmationButtonText: string;
  onConfirmClick: () => void;
};

export default function useConfirmationDialog({ bodyText, confirmationButtonText, onConfirmClick }: Props) {
  const [isOpen, setIsOpen] = useState(false);

  const onOpen = () => {
    setIsOpen(true);
  };

  const onClose = () => {
    setIsOpen(false);
  };

  const onConfirm = useCallback(() => {
    onClose();
    onConfirmClick();
  }, [onConfirmClick]);

  const Dialog = useCallback(
    () => (
      <ConfirmationDialog
        bodyText={bodyText}
        isOpen={isOpen}
        onConfirmClick={onConfirm}
        onCancelClick={onClose}
        confirmationButtonText={confirmationButtonText}
      />
    ),
    [isOpen, bodyText, confirmationButtonText, onConfirm],
  );

  return {
    Dialog,
    onOpen,
  };
}
