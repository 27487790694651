import styled from 'styled-components';
import { TableRow, TableContainer, Paper, TableCell, Select } from '@mui/material';

export const PaperWrapper = styled(Paper)`
  margin-bottom: 20px;
`;

export const Cell = styled(TableCell)`
  text-transform: uppercase;
`;

export const Container = styled(TableContainer)`
  max-height: 600px;
`;

export const Row = styled(TableRow)`
  height: 50px;
`;

export const EmptyField = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  height: 800px;
`;

export const TableSelect = styled(Select)`
  margin: 5px 10px 5px 0;
`;
