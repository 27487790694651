import moment from 'moment';

type StartTimeType = string | null | undefined;
type EndTimeType = string | null | undefined;

export const isSameOrBeforeCurrent = (startTime: StartTimeType): boolean => {
  if (!startTime) return true;
  return moment(startTime).isSameOrBefore(moment());
};

export const isSameOrAfterCurrent = (startTime: StartTimeType): boolean => {
  if (!startTime) return true;
  return moment(startTime).isSameOrAfter(moment('1900-01-01'));
};

export const isSameOrAfter = (startTime: StartTimeType, endTime: EndTimeType): boolean => {
  if (!startTime) return true;
  if (!endTime) return true;
  return moment(startTime).isSameOrAfter(moment(endTime));
};

export const isSameOrBefore = (startTime: StartTimeType, endTime: EndTimeType): boolean => {
  if (!startTime) return true;
  if (!endTime) return true;
  return moment(startTime).isSameOrBefore(moment(endTime));
};
