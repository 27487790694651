import { Response } from 'typings/entities/clients';

export type AuthState = {
  clientsList: Response | null;
};

const initialState: AuthState = {
  clientsList: null,
};

export const setClientsList = (state: AuthState, { payload }: { payload: Response }) => ({
  ...state,
  clientsList: payload,
});

export default initialState;
