import { useAsyncFn } from 'react-use';
import { toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import UploadFileOutlinedIcon from '@mui/icons-material/UploadFileOutlined';
import PriorityHighOutlinedIcon from '@mui/icons-material/PriorityHighOutlined';
import ChevronLeftOutlinedIcon from '@mui/icons-material/ChevronLeftOutlined';
import * as Sentry from '@sentry/browser';

import Button from 'components/Button';
import Loader from 'components/Loader';
import { theme } from 'theme';
import * as http from 'http/etl';
import { pageNumberSelector, settingsForPostSelector, transformationSelector } from 'redux/etl/selectors';
import useModalState from 'hooks/useModalState';
import { actions } from 'redux/etl';
import { getError } from 'utils/error';
import Table from '../Table';
import Modal from './Modal';

import * as S from '../styled';

const St5 = () => {
  const { t } = useTranslation('common');
  const { isOpen, onToggle } = useModalState();
  const dispatch = useDispatch();
  const pageNumber = useSelector(pageNumberSelector);
  const settingsForPost = useSelector(settingsForPostSelector);
  const transformation = useSelector(transformationSelector);

  const [postImportState, postImport] = useAsyncFn(async () => {
    try {
      const res = await http.postImport(settingsForPost);

      dispatch(actions.setImportData(res));
      dispatch(actions.setPageNumber(pageNumber + 1));
    } catch (err: any) {
      Sentry.captureException(new Error(getError(err).error));
      toast.error(getError(err).error);
    }
  }, [dispatch, actions]);

  const handleBackPageNumber = () => {
    dispatch(actions.setPageNumber(pageNumber - 1));
  };

  const handleModal = () => {
    onToggle();
  };

  return (
    <S.Container>
      {postImportState.loading && <Loader />}
      <Modal open={isOpen} close={handleModal} data={transformation.invalidRecords} />
      <S.TitleContainer>
        <S.MainTitle>{t('etl.step5.title')}</S.MainTitle>
      </S.TitleContainer>
      <S.PaperContainer elevation={3}>
        <S.SubTitle>
          <S.Title>{t('etl.step5.dataPreview')}</S.Title>
          <div>
            {t('etl.step5.recordsOriginal')} {transformation.validRecords.length + transformation.invalidRecords.length}
            <br />
            {t('etl.step5.validRecords')} {transformation.validRecords.length}
            <br />
            {t('etl.step5.invalidRecords')} {transformation.invalidRecords.length}
          </div>
        </S.SubTitle>
        {transformation && (
          <>
            <S.TableContainer>
              <Table data={transformation.validRecords} />
            </S.TableContainer>
            <S.InvalidRecordsContainer>
              <S.ButtonWrapper>
                <Button
                  textButton={t('etl.step5.viewInvalidRecords')}
                  buttonProps={{
                    bgColor: theme.colors.red,
                    hoverColor: theme.colors.redShadow,
                    variant: 'outlined',
                    disabled: postImportState.loading || false,
                    onClick: handleModal,
                    startIcon: <PriorityHighOutlinedIcon />,
                  }}
                />
              </S.ButtonWrapper>
            </S.InvalidRecordsContainer>
            <S.ContentContainer mx>
              <S.ButtonWrapper>
                <Button
                  textButton={t('back')}
                  buttonProps={{
                    bgColor: theme.colors.primary,
                    hoverColor: theme.colors.greyShadow,
                    variant: 'outlined',
                    disabled: postImportState.loading || false,
                    onClick: handleBackPageNumber,
                    startIcon: <ChevronLeftOutlinedIcon />,
                  }}
                />
              </S.ButtonWrapper>
              <S.ButtonWrapper>
                <Button
                  textButton={t('etl.step5.import')}
                  buttonProps={{
                    bgColor: theme.colors.primary,
                    hoverColor: theme.colors.primaryShadow,
                    variant: 'contained',
                    disabled: postImportState.loading || false,
                    onClick: postImport,
                    startIcon: <UploadFileOutlinedIcon />,
                  }}
                />
              </S.ButtonWrapper>
            </S.ContentContainer>
          </>
        )}
      </S.PaperContainer>
    </S.Container>
  );
};

export default St5;
