import { Response, User, UserDetails } from 'typings/entities/users';
import { USER, ADMIN } from 'constants/roles';

export const users = ({ data }: { data: Response }): Response => {
  const newItems = data.items.map((item: User) => ({
    ...item,
    roles: item.roles[0] === 'ROLE_USER' ? USER : ADMIN,
  }));

  return {
    ...data,
    items: newItems,
  };
};

export const userById = ({ data }: { data: User }): User => {
  const role = data.roles[0] === 'ROLE_USER' ? USER : ADMIN;

  return {
    ...data,
    roles: role,
  };
};
