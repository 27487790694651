import ReactDOM from 'react-dom';
import { Provider as ReduxProvider } from 'react-redux';
import * as Sentry from '@sentry/browser';
import { ThemeProvider } from 'styled-components';

import { addUserLogoutInterceptor } from 'http/index';
import { theme } from 'theme';
import configureStore from 'redux/configureStore';
import AppRouter from './router';
import './index.css';
import './i18n';

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DNS,
  environment: process.env.REACT_APP_STAGE,
});

const store = configureStore();

addUserLogoutInterceptor([401, 403], store.dispatch);

ReactDOM.render(
  <ThemeProvider theme={theme}>
    <ReduxProvider store={store}>
      <AppRouter />
    </ReduxProvider>
  </ThemeProvider>,
  document.getElementById('root'),
);
