import styled from 'styled-components';
import { Paper, Typography } from '@mui/material';

export const Container = styled.div`
  padding: 25px;
`;

export const PaperContainer = styled(Paper)`
  display: flex;
  flex-wrap: wrap;
`;

export const ContentBox = styled.div`
  width: 49%;
`;

export const BlockTitle = styled(Typography)`
  padding: 30px;
`;

export const EmptyField = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  height: 100%;
`;
