import { PatientDetails } from 'typings/entities/patients';
import { http } from '../index';
import * as formatters from './formatters';

type Props = {
  count: number;
  page: number;
  search: string;
  sortDirection: string;
  sortByField: string;
};

export const getPatients = (params: Props) => {
  return http.get('/api/patients/', { params }).then(formatters.patients);
};

export const getPatientById = (id: number | null) => {
  return http.get(`/api/patients/${id}`).then(formatters.patient);
};

export const putPatient = (req: Props, id: number) => {
  return http.put(`/api/patients/${id}`, req);
};

export const removePatient = (id: number | undefined) => {
  return http.delete(`/api/patients/${id}`);
};

export const postPatient = (req: PatientDetails) => {
  return http.post(`/api/patients/`, req);
};
