import Table from 'components/Table';
import { Client } from 'typings/entities/clients';
import { useTranslation } from 'react-i18next';

type Props = {
  clientsList: Client[];
  setRowsPerPage: (value: number) => void;
  rowsPerPage: number;
  setPage: (value: number) => void;
  onRemoveTableRow: (id: number) => void;
  handleSelectedClient: (id: number) => void;
  oneSorTablet: (id: string, sort: Order) => void;
  page: number;
  allRows: number;
  order: Order;
  orderBy: string;
};

type Order = 'asc' | 'desc';

const ClientsTable = ({
  clientsList,
  setRowsPerPage,
  rowsPerPage,
  setPage,
  allRows,
  order,
  page,
  orderBy,
  onRemoveTableRow,
  oneSorTablet,
  handleSelectedClient,
}: Props) => {
  const { t } = useTranslation('common');

  const tableHead = [
    { field: 'name', headerName: t('clientListPage.laboratory') },
    { field: 'email', headerName: t('clientListPage.email') },
    { field: 'status', headerName: t('clientListPage.status') },
  ];

  return (
    <Table<Client>
      onSetSelectedRow={handleSelectedClient}
      tableHead={tableHead}
      tableBody={clientsList}
      setRowsPerPage={setRowsPerPage}
      rowsPerPage={rowsPerPage}
      setPage={setPage}
      page={page}
      order={order}
      orderBy={orderBy}
      allRows={allRows}
      onRemoveTableRow={onRemoveTableRow}
      isShowRemove
      isShowEdit={false}
      oneSorTablet={oneSorTablet}
    />
  );
};

export default ClientsTable;
