import styled from 'styled-components';

export const Container = styled.div``;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: ${({ theme }) => theme.colors.white};
  padding: 35px;
  border-radius: 20px;
`;

export const Title = styled.div`
  font-size: 35px;
  margin-bottom: 30px;
  text-transform: capitalize;
`;
