import { useTranslation } from 'react-i18next';
import Table from 'components/Table';
import { Patient } from 'typings/entities/patients';

type Props = {
  patientsList: Patient[];
  setRowsPerPage: (value: number) => void;
  rowsPerPage: number;
  setPage: (value: number) => void;
  onRemoveTableRow: (id: number) => void;
  handleSelectedPatient: (id: number) => void;
  oneSorTablet: (id: string, sort: Order) => void;
  page: number;
  allRows: number;
  order: Order;
  orderBy: string;
};

type Order = 'asc' | 'desc';

const PatientsTable = ({
  patientsList,
  setRowsPerPage,
  rowsPerPage,
  setPage,
  allRows,
  order,
  page,
  orderBy,
  onRemoveTableRow,
  oneSorTablet,
  handleSelectedPatient,
}: Props) => {
  const { t } = useTranslation('common');

  const tableHead = [
    { field: 'number', headerName: t('patientsPage.number') },
    { field: 'dateOfBirth', headerName: t('patientsPage.dateOfBirth') },
    { field: 'gender', headerName: t('gender') },
  ];

  return (
    <Table<Patient>
      onSetSelectedRow={handleSelectedPatient}
      tableHead={tableHead}
      tableBody={patientsList}
      setRowsPerPage={setRowsPerPage}
      rowsPerPage={rowsPerPage}
      setPage={setPage}
      page={page}
      order={order}
      orderBy={orderBy}
      allRows={allRows}
      onRemoveTableRow={onRemoveTableRow}
      isShowRemove
      isShowEdit={false}
      oneSorTablet={oneSorTablet}
    />
  );
};

export default PatientsTable;
