import React, { useMemo } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';
import { useTranslation } from 'react-i18next';
import { yupResolver } from '@hookform/resolvers/yup';
import TextField from '@mui/material/TextField';
import Button from 'components/Button';

import { routes } from 'services/router';
import { IconButton, InputAdornment } from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { theme } from 'theme';
import validationSchema, { LoginData } from './validationSchema';
import * as S from './styled';

type LoginFormProps = {
  onSubmit: (params: LoginData) => void;
};

const LoginForm = ({ onSubmit }: LoginFormProps) => {
  const { t } = useTranslation('common');
  const [visibility, setVisibility] = React.useState<boolean>(false);
  const schema = useMemo(() => validationSchema(t), [t]);

  const {
    handleSubmit,
    control,
    formState: { isSubmitting, errors },
  } = useForm<LoginData>({
    resolver: yupResolver(schema),
  });

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <S.Container>
        <S.Wrapper>
          <Controller
            name="username"
            control={control}
            render={({ field: { onChange, value } }) => (
              <TextField
                InputLabelProps={{
                  shrink: true,
                  required: true,
                }}
                fullWidth
                onChange={onChange}
                value={value}
                error={!!errors.username}
                label={t('emailPlaceholder')}
                disabled={isSubmitting}
              />
            )}
          />
          <S.Error>
            <ErrorMessage name="username" errors={errors} />
          </S.Error>
        </S.Wrapper>
        <S.Wrapper>
          <Controller
            name="password"
            control={control}
            render={({ field: { onChange, value } }) => (
              <S.PasswordTextField
                id="password"
                type={visibility ? 'text' : 'password'}
                onChange={onChange}
                value={value}
                label={t('passwordPlaceholder')}
                disabled={isSubmitting}
                error={!!errors.password}
                InputLabelProps={{
                  shrink: true,
                  required: true,
                }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        onClick={() => {
                          setVisibility(!visibility);
                        }}
                        edge="end"
                      >
                        {visibility ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                fullWidth
              />
            )}
          />
          <S.Error>
            <ErrorMessage name="password" errors={errors} />
          </S.Error>
        </S.Wrapper>
        <S.Link to={routes.authModule.restorePassword}>{t('forgotPassword')}</S.Link>
        <S.RequiredLabel>{t('fieldsMarkedRequired')}</S.RequiredLabel>
        <S.ButtonWrapper>
          <Button
            textButton={t('signIn')}
            buttonProps={{
              bgColor: theme.colors.primary,
              hoverColor: theme.colors.primaryShadow,
              variant: 'contained',
              type: 'submit',
              disabled: isSubmitting,
            }}
          />
        </S.ButtonWrapper>
      </S.Container>
    </form>
  );
};

export default LoginForm;
