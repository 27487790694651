import { useTranslation } from 'react-i18next';

import Form from './Form';

import * as S from '../styled';

type Props = {
  restore: (email) => void;
};

const FormFilling = ({ restore }: Props) => {
  const { t } = useTranslation('common');

  return (
    <>
      <S.Title>{t('restorePassword.title')}</S.Title>
      <S.SubTitle>{t('restorePassword.enterYourEmail')}</S.SubTitle>
      <Form onSubmit={restore} />
    </>
  );
};

export default FormFilling;
