import { string, object } from 'yup';
import { emailExp, phoneExp } from 'configs/password';

const EditUserProfileSchema = t =>
  object().shape({
    firstname: string().trim().required(t('requiredName')),
    lastname: string().trim().required(t('requiredSurname')),
    email: string().trim().matches(emailExp, t('wrongEmailFormat')).required(t('requiredEmail')),
    phone: string().trim().matches(phoneExp, t('wrongPhoneFormat')).max(12, t('maxPhone')),
  });

export default EditUserProfileSchema;
