import { createSlice } from '@reduxjs/toolkit';

import initialState, * as handlers from './handlers';

const patients = createSlice({
  name: 'patients',
  initialState,
  reducers: {
    setPatientsList: handlers.setPatientsList,
  },
});

export const { actions } = patients;

export default patients.reducer;
