import styled from 'styled-components';
import { Paper, Typography } from '@mui/material';

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  width: 60%;
`;

export const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 25px;
`;

export const PaperContainer = styled(Paper)`
  display: flex;
  flex-wrap: wrap;
`;

export const ContentBox = styled.div`
  width: 49%;
`;

export const BlockTitle = styled(Typography)`
  padding: 30px;
`;

export const EmptyField = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  height: 100%;
`;

export const ContentWrapper = styled(Container)`
  width: ${({ largeSize }: { largeSize?: boolean }) => (largeSize ? '350px' : '300px')};
  margin: 0 0 20px 20px;
`;

export const ContentDescriptionWrapper = styled.div`
  width: 100%;
  margin: 0 0 20px 20px;
`;

export const ContentContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-left: 10px;
  margin-top: ${({ mt }: { mt?: boolean }) => (mt ? '30px' : '0')};
`;

export const Error = styled.div`
  color: ${({ theme }) => theme.colors.red};
  text-align: left;
  margin-left: ${({ ml }: { ml?: boolean }) => (ml ? '20px' : '0')};
`;

export const Text = styled(Container)`
  flex-direction: column;
  width: 100%;
`;

export const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-bottom: 50px;
`;

export const ButtonWrapper = styled.div`
  margin-right: 20px;
  margin-bottom: 10px;
`;
