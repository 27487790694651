import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Dialog, DialogContent, Radio, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useSelector } from 'react-redux';
import ClearOutlinedIcon from '@mui/icons-material/ClearOutlined';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import Button from 'components/Button';
import { theme } from 'theme';
import { allSettingsSelector } from 'redux/etl/selectors';
import FirstRule from '../FirstRule';
import SecondRule from '../SecondRule';
import ThirdRule from '../ThirdRule';

import * as S from './styled';

const configDate = ['dd-mm-yyyy', 'mm-dd-yyyy', 'yyyy-dd-mm', 'yyyy-mm-dd'];
const config = ['<=', '>'];

const Modal = ({ open, close, handleAddRule, isSelectedRule, nameSelectedField }: any) => {
  const { t } = useTranslation('common');
  const allSettings = useSelector(allSettingsSelector);
  const [selectedRadio, setSelectedRadio] = useState('');
  const [selectedDateFrom, setSelectedDateFrom] = useState(configDate[0]);
  const [selectedDateTo, setSelectedDateTo] = useState(configDate[0]);
  const [selectedReplaceConditionsSecondRule, setSelectedReplaceConditionsSecondRule] = useState(
    allSettings.replaceConditions[0],
  );
  const [selectedReplaceConditionsThirdRule, setSelectedReplaceConditionsThirdRule] = useState(
    allSettings.replaceConditionsForStrings[0],
  );
  const [currentValueSecondRule, setCurrentValueSecondRule] = useState('');
  const [targetValueSecondRule, setTargetValueSecondRule] = useState('');
  const [currentValueThirdRule, setCurrentValueThirdRule] = useState('');
  const [targetValueThirdRule, setTargetValueThirdRule] = useState('');

  // eslint-disable-next-line
  const getRules = () => {
    switch (selectedRadio) {
      case '1':
        return {
          firstRule: [
            {
              id: Date.now(),
              selectedDateFrom,
            },
          ],
        };
      case '2':
        return {
          secondRule: [
            {
              id: Date.now(),
              type: 'secondRule',
              compare: currentValueSecondRule,
              condition: selectedReplaceConditionsSecondRule,
              replace: targetValueSecondRule,
            },
          ],
        };
      case '3':
        return {
          thirdRule: [
            {
              id: Date.now(),
              type: 'thirdRule',
              compare: currentValueThirdRule,
              condition: selectedReplaceConditionsThirdRule,
              replace: targetValueThirdRule,
            },
          ],
        };
      default:
    }
  };

  const handleRadioChange = (event: any) => {
    setSelectedRadio(event.target.value);
  };

  const handleDateFromChange = event => {
    setSelectedDateFrom(event.target.value);
  };

  const handleDateToChange = event => {
    setSelectedDateTo(event.target.value);
  };

  const handleReplaceConditionsSecondRuleChange = event => {
    setSelectedReplaceConditionsSecondRule(event.target.value);
  };

  const handleReplaceConditionsThirdRuleChange = event => {
    setSelectedReplaceConditionsThirdRule(event.target.value);
  };

  const handleCurrentValueSecondRuleChange = event => {
    setCurrentValueSecondRule(event.target.value);
  };

  const handleTargetValueSecondRuleChange = event => {
    setTargetValueSecondRule(event.target.value);
  };

  const handleCurrentValueThirdRuleChange = event => {
    setCurrentValueThirdRule(event.target.value);
  };

  const handleTargetValueThirdRuleChange = event => {
    setTargetValueThirdRule(event.target.value);
  };

  const onAddRule = () => {
    const data = getRules();
    handleAddRule(data);
  };

  return (
    <Dialog onClose={close} open={open} fullWidth maxWidth="md">
      <S.ModalTitle sx={{ m: 1 }}>
        {t('etl.step4.selectTheRule')}
        <IconButton onClick={close}>
          <CloseIcon />
        </IconButton>
      </S.ModalTitle>
      <DialogContent>
        <S.Wrapper>
          <S.RadioWrapper>
            <Radio checked={selectedRadio === '1'} onChange={handleRadioChange} value="1" name="radio-buttons" />
            <FirstRule
              selectedDateTo={selectedDateTo}
              selectedDateFrom={selectedDateFrom}
              handleDateFromChange={handleDateFromChange}
              handleDateToChange={handleDateToChange}
            />
          </S.RadioWrapper>
          <S.RadioWrapper>
            <Radio checked={selectedRadio === '2'} onChange={handleRadioChange} value="2" name="radio-buttons" />
            <SecondRule
              handleReplaceConditionsChange={handleReplaceConditionsSecondRuleChange}
              handleCurrentValueChange={handleCurrentValueSecondRuleChange}
              handleTargetValueChange={handleTargetValueSecondRuleChange}
              selectedReplaceConditions={selectedReplaceConditionsSecondRule}
              currentValue={currentValueSecondRule}
              targetValue={targetValueSecondRule}
              replaceConditions={allSettings.replaceConditions}
            />
          </S.RadioWrapper>
          <S.RadioWrapper>
            <Radio checked={selectedRadio === '3'} onChange={handleRadioChange} value="3" name="radio-buttons" />
            <ThirdRule
              currentValue={currentValueThirdRule}
              targetValue={targetValueThirdRule}
              handleReplaceConditionsChange={handleReplaceConditionsThirdRuleChange}
              handleCurrentValueChange={handleCurrentValueThirdRuleChange}
              handleTargetValueChange={handleTargetValueThirdRuleChange}
              selectedReplaceConditions={selectedReplaceConditionsThirdRule}
              replaceConditions={allSettings.replaceConditionsForStrings}
            />
          </S.RadioWrapper>
        </S.Wrapper>
        <S.ButtonContainer>
          <S.ButtonWrapper>
            <Button
              textButton={t('saveButton')}
              buttonProps={{
                variant: 'contained',
                bgColor: theme.colors.primary,
                hoverColor: theme.colors.primaryShadow,
                onClick: onAddRule,
                disabled: selectedRadio === '',
                startIcon: <AddOutlinedIcon />,
              }}
            />
          </S.ButtonWrapper>
          <S.ButtonWrapper>
            <Button
              textButton={t('cancelButton')}
              buttonProps={{
                bgColor: theme.colors.grey,
                hoverColor: theme.colors.greyShadow,
                variant: 'outlined',
                onClick: close,
                startIcon: <ClearOutlinedIcon />,
              }}
            />
          </S.ButtonWrapper>
        </S.ButtonContainer>
      </DialogContent>
    </Dialog>
  );
};

export default Modal;
