import { useState, useLayoutEffect } from 'react';
import { useAsyncFn } from 'react-use';

import { getTokenRestorePassword, restorePassword } from 'http/auth';
import FormFilling from './FormFilling';
import FormSent from './FormSent';

import * as S from './styled';

const RestorePassword = () => {
  const [isSentEmail, setIsSentEmail] = useState(false);

  const [token, getToken] = useAsyncFn(async () => {
    // eslint-disable-next-line consistent-return
    const res = await getTokenRestorePassword();

    return res;
  }, []);

  const [, restore] = useAsyncFn(
    async email => {
      await restorePassword({ ...email, _token: token?.value });
      setIsSentEmail(!isSentEmail);
    },
    [token],
  );

  useLayoutEffect(() => {
    getToken();
  }, [getToken]);

  return (
    <S.Container>
      <S.Wrapper>{!isSentEmail ? <FormFilling restore={restore} /> : <FormSent />}</S.Wrapper>
    </S.Container>
  );
};

export default RestorePassword;
