import { useState } from 'react';
import { Dialog, DialogContent, IconButton, OutlinedInput } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useTranslation } from 'react-i18next';
import ClearOutlinedIcon from '@mui/icons-material/ClearOutlined';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import Button from 'components/Button';
import { theme } from 'theme';

import * as S from '../../styled';

const Modal = ({ open, close, onAddTemplate, isSaveTemplate, sql }: any) => {
  const { t } = useTranslation('common');
  const [name, setName] = useState('');

  const onChange = e => {
    setName(e.target.value);
  };

  return (
    <Dialog onClose={close} open={open} fullWidth maxWidth="sm">
      <S.ModalTitle sx={{ m: 1 }}>
        {isSaveTemplate ? t('etl.step6.templateName') : t('etl.step6.sqlStatements')}
        <IconButton onClick={close}>
          <CloseIcon />
        </IconButton>
      </S.ModalTitle>
      <DialogContent>
        {isSaveTemplate ? (
          <>
            <S.Container>
              <OutlinedInput
                id="search"
                sx={{
                  minWidth: 200,
                }}
                type="text"
                placeholder={t('searchPlaceholder')}
                size="small"
                onChange={onChange}
              />
            </S.Container>
            <S.ContentContainer>
              <S.ButtonWrapper>
                <Button
                  textButton={t('saveButton')}
                  buttonProps={{
                    bgColor: theme.colors.primary,
                    hoverColor: theme.colors.primaryShadow,
                    variant: 'contained',
                    onClick: () => onAddTemplate(name),
                    startIcon: <AddOutlinedIcon />,
                  }}
                />
              </S.ButtonWrapper>
              <S.ButtonWrapper>
                <Button
                  textButton={t('cancelButton')}
                  buttonProps={{
                    bgColor: theme.colors.grey,
                    hoverColor: theme.colors.greyShadow,
                    variant: 'outlined',
                    onClick: close,
                    startIcon: <ClearOutlinedIcon />,
                  }}
                />
              </S.ButtonWrapper>
            </S.ContentContainer>
          </>
        ) : (
          <>
            <S.Container>{sql}</S.Container>
            <S.ContentContainer>
              <S.ButtonWrapper>
                <Button
                  textButton={t('cancelButton')}
                  buttonProps={{
                    bgColor: theme.colors.primary,
                    hoverColor: theme.colors.greyShadow,
                    variant: 'outlined',
                    onClick: close,
                    startIcon: <ClearOutlinedIcon />,
                  }}
                />
              </S.ButtonWrapper>
            </S.ContentContainer>
          </>
        )}
      </DialogContent>
    </Dialog>
  );
};

export default Modal;
