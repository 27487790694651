import { useTranslation } from 'react-i18next';
import Table from 'components/Table';
import { User } from 'typings/entities/users';
import { getRole } from 'services/localStorage';
import { ADMIN, SWITCH_TO_USER } from 'constants/roles';

type Props = {
  usersList: User[];
  setRowsPerPage: (value: number) => void;
  rowsPerPage: number;
  setPage: (value: number) => void;
  onRemoveTableRow: (id: number) => void;
  oneSorTablet: (id: string, sort: Order) => void;
  handleSelectedUser: (id: number) => void;
  page: number;
  allRows: number;
  order: Order;
  orderBy: string;
};

type Order = 'asc' | 'desc';

const UsersTable = ({
  usersList,
  setRowsPerPage,
  rowsPerPage,
  setPage,
  allRows,
  order,
  page,
  orderBy,
  onRemoveTableRow,
  oneSorTablet,
  handleSelectedUser,
}: Props) => {
  const { t } = useTranslation('common');
  const isAdmin = getRole() === SWITCH_TO_USER || getRole() === ADMIN;

  const tableHead = [
    { field: 'fio', headerName: t('userListPage.name') },
    { field: 'email', headerName: t('userListPage.email') },
    { field: 'roles', headerName: t('userListPage.role') },
    { field: 'status', headerName: t('userListPage.active') },
  ];

  return (
    <Table<User>
      onSetSelectedRow={handleSelectedUser}
      tableHead={tableHead}
      tableBody={usersList}
      setRowsPerPage={setRowsPerPage}
      rowsPerPage={rowsPerPage}
      setPage={setPage}
      page={page}
      order={order}
      orderBy={orderBy}
      allRows={allRows}
      onRemoveTableRow={onRemoveTableRow}
      isShowRemove={isAdmin}
      isShowEdit={false}
      oneSorTablet={oneSorTablet}
    />
  );
};

export default UsersTable;
