export type EtlState = {
  settingsForPost: any;
  allSettings: any;
  transformation: any;
  importData: any;
  selectedTemplate: any;
  pageNumber: number;
};

const initialState: EtlState = {
  allSettings: {},
  settingsForPost: {},
  pageNumber: 1,
  transformation: {},
  importData: {},
  selectedTemplate: undefined,
};

export const setAllSettings = (state: EtlState, { payload }: { payload: any }) => ({
  ...state,
  allSettings: payload,
});

export const setSettingsForPost = (state: EtlState, { payload }: { payload: any }) => ({
  ...state,
  settingsForPost: payload,
});

export const setPageNumber = (state: EtlState, { payload }: { payload: any }) => ({
  ...state,
  pageNumber: payload,
});

export const setTransformation = (state: EtlState, { payload }: { payload: any }) => ({
  ...state,
  transformation: payload,
});

export const setImportData = (state: EtlState, { payload }: { payload: any }) => ({
  ...state,
  importData: payload,
});

export const setSelectedTemplate = (state: EtlState, { payload }: { payload: any }) => ({
  ...state,
  selectedTemplate: payload,
});

export const clear = (state: EtlState) => ({
  ...state,
  allSettings: {},
  settingsForPost: {},
  pageNumber: 1,
  transformation: {},
  importData: {},
  selectedTemplate: undefined,
});

export default initialState;
