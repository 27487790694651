import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useAsyncFn } from 'react-use';
import { toast } from 'react-toastify';
import { getError } from 'utils/error';
import debounce from 'lodash/debounce';
import * as Sentry from '@sentry/browser';

import useModalState from 'hooks/useModalState';
import useConfirmationDialog from 'hooks/useConfirmationDialog';
import { getClients, removeClient, getClientById, postClients, putClients } from 'http/clients';
import * as httpAuth from 'http/auth';
import { routes } from 'services/router';
import { actions } from 'redux/clients';
import convertingUndefinedToString from 'utils/convertingUndefinedToString';
import { Filter, ClientDetails } from 'typings/entities/clients';
import Loader from 'components/Loader';
import SubHeader from 'components/SubHeader';
import { SWITCH_TO_USER } from 'constants/roles';
import { setRole, setLab } from 'services/localStorage';
import Modal from './FilterModal';
import ClientsTable from './ClientsTable';
import Tabs from './Tabs';

import * as S from './styled';

type Order = 'asc' | 'desc';

const ClientList = () => {
  const { t } = useTranslation('common');
  const dispatch = useDispatch();
  const history = useHistory();
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);
  const [selectedClientId, setSelectedClientId] = useState<number | null>(null);
  const [search, setSearch] = useState<string>('');
  const [sortDirection, setSortDirection] = useState<Order>('asc');
  const [nameField, setNameField] = useState<string>('');
  const [clientId, setClientId] = useState<number>();
  const [filter, setFilter] = useState({});
  const delayedSetSearch = debounce((string: string) => setSearch(string), 500);
  const { isOpen, onToggle } = useModalState();
  const [selectedTab, setSelectedTab] = useState<string>('1');

  // eslint-disable-next-line consistent-return
  const [, loginAsClient] = useAsyncFn(async (email, name) => {
    try {
      await httpAuth.loginAsClient(email);
      setRole(SWITCH_TO_USER);
      setLab(name);
      history.push(routes.dashboardModule.userList);

      toast.success(t('success'));
    } catch (err: any) {
      Sentry.captureException(new Error(getError(err).error));
      toast.error(getError(err).error);
    }
  }, []);

  // eslint-disable-next-line consistent-return
  const [clientsListState, getClientsList] = useAsyncFn(async () => {
    try {
      const data = await getClients({
        count: rowsPerPage,
        page,
        search,
        sortDirection,
        sortByField: nameField,
        ...filter,
      });
      dispatch(actions.setClientsList(data));

      return data;
    } catch (err: any) {
      Sentry.captureException(new Error(getError(err).error));
      toast.error(getError(err).error);
    }
  }, [page, rowsPerPage, search, sortDirection, nameField, filter]);

  const [, removeClientList] = useAsyncFn(async () => {
    try {
      await removeClient(clientId);
      getClientsList();

      toast.success(t('success'));
    } catch (err: any) {
      Sentry.captureException(new Error(getError(err).error));
      toast.error(getError(err).error);
    }
  }, [clientId]);

  const [clientState, getClientData] = useAsyncFn(
    // eslint-disable-next-line consistent-return
    async (id: number) => {
      try {
        return await getClientById(id);
      } catch (err: any) {
        Sentry.captureException(new Error(getError(err).error));
        toast.error(getError(err).error);
      }
    },
    [selectedClientId],
  );

  const [, createClient] = useAsyncFn(async (req: ClientDetails) => {
    try {
      const newReq: any = convertingUndefinedToString(req);

      await postClients(newReq);
      getClientsList();
      setSelectedClientId(null);

      toast.success(t('success'));
    } catch (err: any) {
      Sentry.captureException(new Error(getError(err).error));
      toast.error(getError(err).error);
    }
  }, []);

  const [, updateClient] = useAsyncFn(
    async (req: ClientDetails) => {
      try {
        const newReq: any = convertingUndefinedToString(req);

        const data = {
          name: newReq.name,
          email: newReq.email,
          country: newReq.country,
          city: newReq.city,
          street: newReq.street,
          zipcode: newReq.zipcode,
          geodata: newReq.geodata,
          status: newReq.status,
        };

        await putClients(data, newReq.id);
        getClientsList();
        setSelectedClientId(null);

        toast.success(t('success'));
      } catch (err: any) {
        Sentry.captureException(new Error(getError(err).error));
        toast.error(getError(err).error);
      }
    },
    [rowsPerPage, page, search, sortDirection],
  );

  useEffect(() => {
    getClientsList();
  }, [getClientsList, rowsPerPage, page, search, sortDirection, nameField]);

  const handleRemoveClient = () => {
    removeClientList();
    setSelectedClientId(null);
  };

  const { Dialog, onOpen } = useConfirmationDialog({
    bodyText: t('confirmationDialog.bodyTextClient'),
    confirmationButtonText: t('confirmationDialog.confirmationButtonText'),
    onConfirmClick: handleRemoveClient,
  });

  const handleFilterChange = (props: Filter) => {
    setFilter(props);
    onToggle();
  };

  const handleResetFilter = () => {
    setFilter({});
    onToggle();
  };

  const handleAddNewClient = () => {
    setSelectedClientId(-1);
    setSelectedTab('1');
  };

  const handleFormCancel = () => {
    setSelectedClientId(null);
  };

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    delayedSetSearch(event.target.value);
  };

  const handleOpenModal = () => {
    onToggle();
  };

  const handleSort = (name: string, sort: Order) => {
    setSortDirection(sort);
    setNameField(name);
  };

  const handleDeleteClick = (id: number) => {
    setClientId(id);
    onOpen();
  };

  const handleSelectedClient = (id: number) => {
    getClientData(id);
    setSelectedClientId(id);
  };

  const handleTabChange = (event: React.SyntheticEvent, newValue: string) => {
    setSelectedTab(newValue);
  };

  const handleFormSubmit = req => {
    if (req.id) {
      updateClient(req);
    } else {
      createClient(req);
    }
  };

  return (
    <S.Container>
      {(clientsListState.loading || clientState.loading) && <Loader />}
      <Dialog />
      <Modal
        open={isOpen}
        close={onToggle}
        handleFilterChange={handleFilterChange}
        handleResetFilter={handleResetFilter}
      />
      <SubHeader
        title={t('clientListPage.title')}
        textButton={t('clientListPage.addClient')}
        onClick={handleAddNewClient}
        onSearchChange={handleSearchChange}
        onFilterChange={handleOpenModal}
      />
      <S.PaperContainer elevation={3}>
        <S.ContentBox>
          {clientsListState.value && (
            <ClientsTable
              order={sortDirection}
              orderBy={nameField}
              clientsList={clientsListState.value.items}
              setRowsPerPage={setRowsPerPage}
              rowsPerPage={rowsPerPage}
              allRows={clientsListState.value.allCount}
              setPage={setPage}
              page={page}
              onRemoveTableRow={handleDeleteClick}
              oneSorTablet={handleSort}
              handleSelectedClient={handleSelectedClient}
            />
          )}
        </S.ContentBox>
        <S.ContentBox>
          {selectedClientId ? (
            <Tabs
              selectedClient={clientState.value}
              selectedClientId={selectedClientId}
              selectedTab={selectedTab}
              handleTabChange={handleTabChange}
              handleFormSubmit={handleFormSubmit}
              handleLoginAsClientSubmit={loginAsClient}
              handleFormCancel={handleFormCancel}
            />
          ) : (
            <S.EmptyField>
              {t('noRecords')}
              <br />
              {t('emptyField')}
            </S.EmptyField>
          )}
        </S.ContentBox>
      </S.PaperContainer>
    </S.Container>
  );
};

export default ClientList;
