import React, { Suspense } from 'react';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom';

import { routes } from 'services/router';
import Main from 'pages/Main';
import NotFound from 'pages/NotFound';
import Auth from 'pages/Auth';
import Dashboard from 'pages/Dashboard';
import PrivateRoute from './PrivateRoute';

const AppRouter: React.FC = () => {
  return (
    <Router>
      <Suspense fallback={<div>Loading...</div>}>
        <Switch>
          <PrivateRoute exact path="/" redirectTo={routes.authModule.auth} component={Main} />
          <Route path={routes.authModule.auth} component={Auth} />
          <PrivateRoute path={routes.dashboardModule.dashboard} component={Dashboard} />
          <Route path={routes.notFound} component={NotFound} />
          <Route component={() => <Redirect to={routes.notFound} />} />
        </Switch>
      </Suspense>
      <ToastContainer position="bottom-right" newestOnTop={false} closeOnClick rtl={false} pauseOnFocusLoss draggable />
    </Router>
  );
};

export default AppRouter;
