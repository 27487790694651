import React, { useMemo, useEffect } from 'react';
import ClearOutlinedIcon from '@mui/icons-material/ClearOutlined';
import DoneOutlinedIcon from '@mui/icons-material/DoneOutlined';
import ListItemText from '@mui/material/ListItemText';
import Button from 'components/Button';
import moment from 'moment';
import { Controller, useForm } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';
import { yupResolver } from '@hookform/resolvers/yup';
import { useTranslation } from 'react-i18next';
import AdapterMoment from '@mui/lab/AdapterMoment';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DatePicker from '@mui/lab/DatePicker';
import { MenuItem, TextField } from '@mui/material';

import { NewExamination, Examination, Method } from 'typings/entities/examination';
import { Virus } from 'typings/entities/viruses';
import { theme } from 'theme';

import validationSchema from './validationSchema';
import * as S from './styled';

type Props = {
  handleFormSubmit: (req: NewExamination) => void;
  addFile: (req) => void;
  handleFormCancel: () => void;
  selectedExaminationId: number;
  selectedPatientId: number | null;
  selectedExamination: Examination | undefined;
  examinationMethodState: Method[];
  virusesState: Virus[];
  files: [];
  errorMessage: any;
  selectedPatientNumber: string | undefined;
};

const PatientForm = ({
  handleFormSubmit,
  selectedExamination,
  selectedExaminationId,
  selectedPatientId,
  handleFormCancel,
  examinationMethodState,
  virusesState,
  addFile,
  files,
  selectedPatientNumber,
  errorMessage,
}: Props) => {
  const { t } = useTranslation('common');
  const schema = useMemo(() => validationSchema(t), [t]);
  const defaultValues = useMemo(() => {
    return selectedExaminationId === -1
      ? {
          sampleNumber: '',
          result: '',
          documents: '',
          uploadDocument: '',
          virus: '',
          date: null,
          method: '',
          comment: '',
        }
      : {
          ...selectedExamination,
          date: moment(selectedExamination?.date).format('MM/DD/YYYY'),
          method: selectedExamination?.method?.id,
          virus: selectedExamination?.virus.id,
        };
  }, [selectedExaminationId, selectedExamination]);

  const {
    handleSubmit,
    control,
    reset,
    setError,
    formState: { isSubmitting, errors },
  } = useForm<any>({
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    if (errorMessage) {
      errorMessage.map(item => setError(item.key, item));
    }
  }, [errorMessage, setError]);

  useEffect(() => {
    reset(defaultValues);
  }, [defaultValues, reset]);

  return (
    <form onSubmit={handleSubmit(handleFormSubmit)}>
      <S.ContentContainer>
        <S.ContentWrapper>
          <S.Content>{t('examination.examinationTitle')}</S.Content>
        </S.ContentWrapper>
      </S.ContentContainer>
      <S.ContentContainer>
        <S.ContentWrapper>
          <S.ContentLabel>{t('examination.patientNumber')}:</S.ContentLabel>
          <S.Content>{selectedPatientNumber}</S.Content>
        </S.ContentWrapper>
        <S.ContentWrapper>
          <Controller
            name="virus"
            control={control}
            render={({ field: { onChange, value } }) => (
              <>
                <TextField
                  select
                  label={t('examination.virusID')}
                  InputLabelProps={{
                    shrink: true,
                    required: true,
                  }}
                  size="small"
                  value={String(value)}
                  onChange={onChange}
                  error={!!errors.virus}
                >
                  {virusesState.map((virus: Virus) => (
                    <MenuItem key={virus.id} value={virus.id}>
                      <ListItemText primary={virus.virusId} secondary={virus.name} />
                    </MenuItem>
                  ))}
                </TextField>
              </>
            )}
          />
          <S.Error>
            <ErrorMessage name="virus" errors={errors} />
          </S.Error>
        </S.ContentWrapper>
      </S.ContentContainer>
      <S.ContentContainer>
        <S.ContentWrapper>
          <Controller
            name="sampleNumber"
            control={control}
            render={({ field: { onChange, value } }) => (
              <TextField
                InputLabelProps={{
                  shrink: true,
                  required: true,
                }}
                size="small"
                onChange={onChange}
                value={value}
                error={!!errors.sampleNumber}
                label={t('examination.sampleNumber')}
                disabled={isSubmitting}
              />
            )}
          />
          <S.Error>
            <ErrorMessage name="sampleNumber" errors={errors} />
          </S.Error>
        </S.ContentWrapper>
        <S.ContentWrapper>
          <Controller
            name="date"
            control={control}
            render={({ field: { onChange, value } }) => (
              <LocalizationProvider dateAdapter={AdapterMoment}>
                <S.DatePickerWrapper>
                  <DatePicker
                    value={value}
                    onChange={e => {
                      onChange(moment(e).format('MM/DD/YYYY'));
                    }}
                    maxDate={moment()}
                    renderInput={params => {
                      return (
                        <TextField
                          {...params}
                          label={t('examination.examinationDate')}
                          InputLabelProps={{
                            shrink: true,
                            required: true,
                          }}
                          size="small"
                          error={errors.date}
                        />
                      );
                    }}
                  />
                </S.DatePickerWrapper>
              </LocalizationProvider>
            )}
          />
          <S.Error>
            <ErrorMessage name="date" errors={errors} />
          </S.Error>
        </S.ContentWrapper>
      </S.ContentContainer>
      <S.ContentContainer>
        <S.ContentWrapper>
          <Controller
            name="result"
            control={control}
            render={({ field: { onChange, value } }) => (
              <TextField
                InputLabelProps={{
                  shrink: true,
                  required: true,
                }}
                size="small"
                onChange={onChange}
                value={value}
                error={!!errors.result}
                label={t('examination.result')}
                disabled={isSubmitting}
              />
            )}
          />
          <S.Error>
            <ErrorMessage name="result" errors={errors} />
          </S.Error>
        </S.ContentWrapper>
        <S.ContentWrapper>
          <Controller
            name="method"
            control={control}
            render={({ field: { onChange, value } }) => (
              <>
                <TextField
                  select
                  label={t('examination.examinationMethod')}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  size="small"
                  onChange={onChange}
                  value={String(value)}
                >
                  {examinationMethodState.map((examination: Method) => (
                    <MenuItem key={examination.id} value={String(examination.id)}>
                      {examination.description}
                    </MenuItem>
                  ))}
                </TextField>
              </>
            )}
          />
        </S.ContentWrapper>
      </S.ContentContainer>
      <S.ContentContainer mb>
        <S.CommentWrapper>
          <Controller
            name="comment"
            control={control}
            render={({ field: { onChange, value } }) => (
              <TextField
                InputLabelProps={{
                  shrink: true,
                }}
                size="small"
                onChange={onChange}
                value={value}
                label={t('comment')}
                disabled={isSubmitting}
                fullWidth
                multiline
                rows={3}
              />
            )}
          />
        </S.CommentWrapper>
      </S.ContentContainer>
      <S.ButtonContainer>
        <S.ButtonWrapper>
          <Button
            textButton={t('saveButton')}
            buttonProps={{
              bgColor: theme.colors.primary,
              hoverColor: theme.colors.primaryShadow,
              variant: 'contained',
              type: 'submit',
              disabled: isSubmitting,
              startIcon: <DoneOutlinedIcon />,
            }}
          />
        </S.ButtonWrapper>
        <S.ButtonWrapper>
          <Button
            textButton={t('cancelButton')}
            buttonProps={{
              bgColor: theme.colors.grey,
              hoverColor: theme.colors.greyShadow,
              variant: 'outlined',
              onClick: handleFormCancel,
              disabled: isSubmitting,
              startIcon: <ClearOutlinedIcon />,
            }}
          />
        </S.ButtonWrapper>
      </S.ButtonContainer>
    </form>
  );
};

export default PatientForm;
