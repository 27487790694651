import { UserProfileDetails, AdminProfileDetails, ProfilePassword } from 'typings/entities/profile';
import { http } from '../index';

export const putUserProfileDetails = (req: UserProfileDetails) => {
  return http.put(`/api/profiles/details`, req);
};

export const putAdminProfileDetails = (req: AdminProfileDetails) => {
  return http.put(`/api/admin/profiles/email`, req);
};

export const putUserProfilePassword = (req: ProfilePassword) => {
  return http.put(`/api/profiles/password`, req);
};

export const putAdminProfilePassword = (req: ProfilePassword) => {
  return http.put(`/api/admin/profiles/password`, req);
};
