import { Response } from 'typings/entities/users';

export type AuthState = {
  usersList: Response | null;
};

const initialState: AuthState = {
  usersList: null,
};

export const setUsersList = (state: AuthState, { payload }: { payload: Response }) => ({
  ...state,
  usersList: payload,
});

export default initialState;
