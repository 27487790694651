import styled from 'styled-components';

export const Link = styled.a`
  color: ${({ theme }) => theme.colors.primaryBlue};
`;

export const Container = styled.div`
  text-align: center;
`;

export const Header = styled.header`
  background-color: ${({ theme }) => theme.colors.darkBlue};
  min-height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: ${({ theme }) => theme.colors.white};
`;

export const Logo = styled.img`
  height: 40vmin;
  pointer-events: none;

  @keyframes App-logo-spin {
    from {
      transform: rotate(0deg);
    }

    to {
      transform: rotate(360deg);
    }
  }

  @media (prefers-reduced-motion: no-preference) {
    animation: App-logo-spin infinite 20s linear;
  }
`;
