import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import Button from 'components/Button';

import { routes } from 'services/router';
import { SUPER_ADMIN } from 'constants/roles';
import { getRole } from 'services/localStorage';
import { theme } from 'theme';

import * as S from '../styled';

const FormSent = () => {
  const { t } = useTranslation('common');
  const history = useHistory();
  const role = getRole();

  const handleClick = () => {
    const url = role === SUPER_ADMIN ? routes.authModule.adminLogin : routes.authModule.login;
    history.push(url);
  };

  return (
    <>
      <S.Title>{t('resetPassword.title')}</S.Title>
      <S.SubTitle>{t('resetPassword.passwordUpdated')}</S.SubTitle>
      <S.ButtonWrapper>
        <Button
          textButton={t('signIn')}
          buttonProps={{
            bgColor: theme.colors.primary,
            hoverColor: theme.colors.primaryShadow,
            variant: 'contained',
            onClick: handleClick,
          }}
        />
      </S.ButtonWrapper>
    </>
  );
};

export default FormSent;
