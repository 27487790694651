import * as yup from 'yup';
import { dateExp, emptyExp } from 'configs/password';
import { isSameOrBeforeCurrent, isSameOrAfter, isSameOrBefore, isSameOrAfterCurrent } from 'utils/dateChecking';

const FilterSchema = t => {
  return yup.object().shape({
    examinationFrom: yup.lazy(data => {
      if (data === 'Invalid date') {
        return yup.string().matches(emptyExp, t('wrongEmailFormat')).nullable();
      }
      return yup
        .string()
        .matches(dateExp, { message: t('wrongEmailFormat'), excludeEmptyString: false })
        .nullable()
        .test('maxFrom', t('maxDateFrom'), function (value) {
          const { examinationTo } = this.parent;
          return isSameOrBefore(value, examinationTo);
        })
        .test('maxCurrent', t('maxCurrentDate'), function (value) {
          return isSameOrBeforeCurrent(value);
        })
        .test('minCurrent', t('minCurrentDate'), function (value) {
          return isSameOrAfterCurrent(value);
        });
    }),
    examinationTo: yup.lazy(data => {
      if (data === 'Invalid date') {
        return yup.string().matches(emptyExp, t('wrongEmailFormat')).nullable();
      }
      return yup
        .string()
        .matches(dateExp, { message: t('wrongEmailFormat'), excludeEmptyString: true })
        .nullable()
        .test('maxTo', t('maxCurrentDate'), function (value) {
          return isSameOrBeforeCurrent(value);
        })
        .test('minTo', t('minDateTo'), function (value) {
          const { examinationFrom } = this.parent;
          return isSameOrAfter(value, examinationFrom);
        })
        .test('minCurrent', t('minCurrentDate'), function (value) {
          return isSameOrAfterCurrent(value);
        });
    }),
  });
};
export default FilterSchema;
