import styled from 'styled-components';

export const Container = styled.div`
  margin-left: 30px;
`;

export const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const FormWrapper = styled.div`
  padding: 20px 0;
`;
