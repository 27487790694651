import styled, { DefaultTheme } from 'styled-components';
import { Button } from '@mui/material';

type Props = {
  theme: DefaultTheme;
  bgColor: string;
  variant: string;
  hoverColor?: string;
};

export const ButtonField = styled(Button)`
  && {
    border-radius: 4px;
    text-align: center;
    color: ${({ theme, bgColor, variant }: Props) => (variant === 'outlined' ? bgColor : theme.colors.white)};
    background-color: ${({ theme, bgColor, variant }: Props) =>
      variant === 'outlined' ? theme.colors.white : bgColor};
    border: 1px solid ${({ bgColor }: Props) => bgColor};

    &:hover {
      color: ${({ theme, bgColor, variant }: Props) => (variant === 'outlined' ? bgColor : theme.colors.white)};
      background-color: ${({ hoverColor }: Props) => hoverColor};
      border: 1px solid ${({ hoverColor, bgColor, variant }: Props) => (variant === 'outlined' ? bgColor : hoverColor)};
    }
    min-width: 130px;
    max-width: 260px;
    min-height: 37px;
    padding: 0 25px;
    line-height: 1;
  }
`;
