import { ClientUserDetails } from 'typings/entities/users';
import { ClientDetails } from 'typings/entities/clients';
import { http } from '../index';
import * as formatters from './formatters';

type ClientProps = {
  count: number;
  page: number;
  search: string;
  sortDirection: string;
  sortByField: string;
};

type UserProps = {
  count: number;
  page: number;
  search: string;
  client: number | null;
  sortDirection: string;
  sortByField: string;
};

export const getClients = (params: ClientProps) => {
  return http.get('/api/admin/clients/', { params }).then(formatters.clients);
};

export const removeClient = (id: number | undefined) => {
  return http.delete(`/api/admin/clients/${id}`);
};

export const getClientById = (id: number | null) => {
  return http.get(`/api/admin/clients/${id}`).then(formatters.client);
};

export const postClients = (req: ClientDetails) => {
  return http.post(`/api/admin/clients/`, req);
};

export const putClients = (req: ClientDetails, id: number | null) => {
  return http.put(`/api/admin/clients/${id}`, req);
};

export const getUsers = (params: UserProps) => {
  return http.get('/api/admin/users/', { params }).then(formatters.users);
};

export const removeUser = (id: number | undefined) => {
  return http.delete(`/api/admin/users/${id}`);
};

export const postUsers = (req: ClientUserDetails) => {
  return http.post(`/api/admin/users/`, req);
};

export const putUsers = (req: ClientUserDetails, id: number) => {
  return http.put(`/api/admin/users/${id}`, req);
};

export const getUserById = (id: number | undefined) => {
  return http.get(`/api/admin/users/${id}`).then(formatters.userById);
};
