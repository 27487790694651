import { dateExp, emptyExp } from 'configs/password';
import { isSameOrAfterCurrent, isSameOrBeforeCurrent } from 'utils/dateChecking';
import * as yup from 'yup';

const PatientProfileSchema = t =>
  yup.object().shape({
    number: yup.string().trim().required(t('required')),
    zipcode: yup.string(),
    dateOfBirth: yup.lazy(data => {
      if (data === 'Invalid date') {
        return yup.string().matches(emptyExp, t('wrongEmailFormat')).nullable();
      }
      return yup
        .string()
        .matches(dateExp, { message: t('wrongEmailFormat'), excludeEmptyString: false })
        .nullable()
        .test('maxCurrent', t('maxCurrentDate'), function (value) {
          return isSameOrBeforeCurrent(value);
        })
        .test('minCurrent', t('minCurrentDate'), function (value) {
          return isSameOrAfterCurrent(value);
        });
    }),
    gender: yup.string(),
    comment: yup.string(),
  });

export default PatientProfileSchema;
