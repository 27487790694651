import React from 'react';
import { useTranslation } from 'react-i18next';
import { InputAdornment, OutlinedInput } from '@mui/material';
import { Search, FilterList } from '@mui/icons-material';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import UploadFileOutlinedIcon from '@mui/icons-material/UploadFileOutlined';
import Button from 'components/Button';
import { theme } from 'theme';

import * as S from './styled';

type Props = {
  title: string;
  textButton: string;
  onSearchChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onFilterChange: () => void;
  onClick: () => void;
  onImportClick?: () => void;
  importButton?: boolean;
  subTitle?: boolean | false;
  partialSubHeader?: boolean;
};

const SubHeader = ({
  title,
  textButton,
  onSearchChange,
  onFilterChange,
  onClick,
  onImportClick,
  importButton,
  subTitle,
  partialSubHeader,
}: Props) => {
  const { t } = useTranslation('common');

  return (
    <S.PageContainer>
      <S.PageHeader>
        <S.Title titleSize={subTitle}>{title}</S.Title>
        {importButton && (
          <S.ImportButtonContainer>
            <Button
              textButton={t('patientsPage.importPatients')}
              buttonProps={{
                hoverColor: theme.colors.primaryShadow,
                bgColor: theme.colors.primary,
                variant: 'contained',
                onClick: onImportClick,
                startIcon: <UploadFileOutlinedIcon />,
              }}
            />
          </S.ImportButtonContainer>
        )}
      </S.PageHeader>
      <S.FormContainer>
        {partialSubHeader ? (
          <>
            <S.FormControlWrapper>
              <OutlinedInput
                id="search"
                sx={{
                  minWidth: 200,
                }}
                type="text"
                placeholder={t('searchPlaceholder')}
                size="small"
                onChange={onSearchChange}
                endAdornment={
                  <InputAdornment position="end">
                    <Search />
                  </InputAdornment>
                }
              />
            </S.FormControlWrapper>
            {textButton && (
              <S.ButtonWrapper ml>
                <Button
                  textButton={textButton}
                  buttonProps={{
                    bgColor: theme.colors.primary,
                    hoverColor: theme.colors.primaryShadow,
                    variant: 'contained',
                    onClick,
                    startIcon: <AddOutlinedIcon />,
                  }}
                />
              </S.ButtonWrapper>
            )}
          </>
        ) : (
          <>
            <S.FormControlWrapper>
              <OutlinedInput
                id="search"
                sx={{
                  minWidth: 200,
                }}
                type="text"
                placeholder={t('searchPlaceholder')}
                size="small"
                onChange={onSearchChange}
                endAdornment={
                  <InputAdornment position="end">
                    <Search />
                  </InputAdornment>
                }
              />
            </S.FormControlWrapper>
            <S.IconButtonWrapper onClick={onFilterChange}>
              <FilterList fontSize="medium" />
            </S.IconButtonWrapper>
            <S.ButtonWrapper>
              <Button
                textButton={textButton}
                buttonProps={{
                  bgColor: theme.colors.primary,
                  hoverColor: theme.colors.primaryShadow,
                  variant: 'contained',
                  onClick,
                  startIcon: <AddOutlinedIcon />,
                }}
              />
            </S.ButtonWrapper>
          </>
        )}
      </S.FormContainer>
    </S.PageContainer>
  );
};

SubHeader.defaultProps = {
  subTitle: false,
  partialSubHeader: false,
  onImportClick: () => {},
  importButton: false,
};

export default SubHeader;
