export const getSettings = ({ data }: { data: any }): any => {
  const keyDelimiters = Object.keys(data.delimiters);
  const newDelimiters = keyDelimiters.map(key => ({
    id: key,
    description: data.delimiters[key],
  }));

  const keyEncodes = Object.keys(data.encodes);
  const newEncodes = keyEncodes.map(key => ({
    id: key,
    description: data.encodes[key],
  }));

  const newEnclosures = data.enclosures.map(item => ({
    id: item,
    description: item,
  }));

  const keyEmptyPatientNumbers = Object.keys(data.emptyPatientNumbers);
  const newEmptyPatientNumbers = keyEmptyPatientNumbers.map(key => ({
    id: key,
    description: data.emptyPatientNumbers[key],
  }));

  const newTemplateTypes = data.templateTypes.map(template => {
    const keyFields = Object.keys(template.fields);
    const newFields = keyFields.map(key => ({
      id: key,
      description: template.fields[key],
    }));

    return { ...template, fields: newFields };
  });

  const newTemplates = data.templates.map(template => {
    const keyFields = Object.keys(template.fileType.fields);
    const newFields = keyFields.map(key => ({
      id: key,
      description: template.fileType.fields[key],
    }));

    return { ...template, fileType: { ...template.fileType, fields: newFields } };
  });

  const newData = {
    ...data,
    delimiters: newDelimiters,
    encodes: newEncodes,
    enclosures: newEnclosures,
    emptyPatientNumbers: newEmptyPatientNumbers,
    templateTypes: newTemplateTypes,
    templates: newTemplates,
  };

  return newData;
};

export const postSettings = ({ data }: { data: any }): any => {
  return data;
};
