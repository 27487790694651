import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import Backend from 'i18next-xhr-backend';
import LanguageDetector from 'i18next-browser-languagedetector';

import { getI18nextLng } from 'services/localStorage';
import { FALLBACK_LANGUAGE } from 'configs/locale';
import { http } from '../http';

const isDevelopment = process.env.NODE_ENV === 'development';

i18n
  .use(LanguageDetector)
  .use(Backend)
  .use(initReactI18next)
  .init({
    react: {
      useSuspense: false,
    },
    lng: getI18nextLng() || FALLBACK_LANGUAGE,
    fallbackLng: 'en',
    debug: isDevelopment,
    ns: ['common'],
    defaultNS: 'common',
    interpolation: {
      escapeValue: false,
    },
  });

i18n.on('languageChanged', () => {
  http.defaults.headers['Accept-Language'] = i18n.language;
});

export default i18n;
