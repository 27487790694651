import { useMemo, useEffect, useLayoutEffect } from 'react';
import { useAsyncFn } from 'react-use';
import { toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import ChevronRightOutlinedIcon from '@mui/icons-material/ChevronRightOutlined';
import ChevronLeftOutlinedIcon from '@mui/icons-material/ChevronLeftOutlined';
import Button from 'components/Button';
import * as Sentry from '@sentry/browser';

import * as http from 'http/etl';
import Loader from 'components/Loader';
import { theme } from 'theme';
import { pageNumberSelector, settingsForPostSelector, allSettingsSelector } from 'redux/etl/selectors';
import { actions } from 'redux/etl';
import { getError } from 'utils/error';
import Form from './Form';
import Table from '../Table';

import * as S from '../styled';

const St2 = () => {
  const { t } = useTranslation('common');
  const dispatch = useDispatch();
  const pageNumber = useSelector(pageNumberSelector);
  const settingsForPost = useSelector(settingsForPostSelector);
  const allSettings = useSelector(allSettingsSelector);

  const [postSettingsState, postSettings] = useAsyncFn(
    // eslint-disable-next-line consistent-return
    async req => {
      try {
        const data = { ...settingsForPost, fileSettings: { ...req } };

        const res = await http.postSettings(data);

        dispatch(actions.setSettingsForPost(data));
        return res;
      } catch (err: any) {
        Sentry.captureException(new Error(getError(err).error));
        toast.error(getError(err).error);
      }
    },
    [dispatch, actions, pageNumber],
  );

  useLayoutEffect(() => {
    postSettings(settingsForPost.fileSettings);
    // eslint-disable-next-line
  }, [postSettings]);

  const handleFormSubmit = props => {
    postSettings(props);
  };

  const handleNextPageNumber = () => {
    dispatch(actions.setPageNumber(pageNumber + 1));
  };

  const handleBackPageNumber = () => {
    dispatch(actions.setPageNumber(pageNumber - 1));
  };

  return (
    <S.Container>
      {(!allSettings || postSettingsState?.loading) && <Loader />}
      <S.TitleContainer>
        <S.MainTitle>{t('etl.step2.mainTitle')}</S.MainTitle>
      </S.TitleContainer>
      <S.PaperContainer elevation={3}>
        <S.Title>{t('etl.step2.title')}</S.Title>
        <Form
          handleFormSubmit={handleFormSubmit}
          allSettings={allSettings}
          defaultValues={settingsForPost.fileSettings}
          isDisabled={postSettingsState?.loading || false}
        />
        {postSettingsState?.value && (
          <>
            {postSettingsState?.value.length > 0 ? (
              <S.TableContainer>
                <Table data={postSettingsState?.value} />
              </S.TableContainer>
            ) : (
              <S.NoResult>{t('noResults')}</S.NoResult>
            )}
          </>
        )}
        <S.ContentContainer mx>
          <S.ButtonWrapper>
            <Button
              textButton={t('back')}
              buttonProps={{
                bgColor: theme.colors.grey,
                hoverColor: theme.colors.greyShadow,
                variant: 'outlined',
                onClick: handleBackPageNumber,
                startIcon: <ChevronLeftOutlinedIcon />,
              }}
            />
          </S.ButtonWrapper>
          <S.ButtonWrapper>
            <Button
              textButton={t('next')}
              buttonProps={{
                bgColor: theme.colors.primary,
                hoverColor: theme.colors.primaryShadow,
                variant: 'contained',
                onClick: handleNextPageNumber,
                disabled: postSettingsState?.value?.length === 0 || !postSettingsState?.value,
                startIcon: <ChevronRightOutlinedIcon />,
              }}
            />
          </S.ButtonWrapper>
        </S.ContentContainer>
      </S.PaperContainer>
    </S.Container>
  );
};

export default St2;
