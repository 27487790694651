import styled from 'styled-components';
import { Typography, Button } from '@mui/material';

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
`;

export const ContentContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-bottom: ${({ mb }: { mb?: boolean }) => (mb ? '40px' : '0')};
`;

export const ContentWrapper = styled(Container)`
  width: 45%;
  min-width: 100px;
  margin-bottom: 20px;
  flex-direction: column;

  @media screen and (max-width: 850px) {
    width: 100%;
  }
`;

export const CommentWrapper = styled(Container)``;

export const ControllerWrapper = styled.div`
  margin-bottom: 20px;
  margin-right: 20px;
`;

export const DatePickerWrapper = styled.div`
  .css-1u3bzj6-MuiFormControl-root-MuiTextField-root {
    height: 40px;
    width: 100%;
  }
`;

export const ButtonWrapper = styled.div`
  margin-right: 10px;
  margin-bottom: 10px;
`;

export const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
`;

export const Error = styled.div`
  color: ${({ theme }) => theme.colors.red};
  text-align: left;
  margin-left: ${({ ml }: { ml?: boolean }) => (ml ? '20px' : '0')};
`;

export const ContentLabel = styled(Typography)`
  width: 300px;
  margin: -10px 0;
  color: rgba(0, 0, 0, 0.6);
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
  font-weight: 400;
  font-size: 13px;
  line-height: 1.4375em;
`;

export const Content = styled(Typography)`
  margin: 10px 0;
`;
