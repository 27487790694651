import { Select, MenuItem, TextField } from '@mui/material';
import { useTranslation } from 'react-i18next';

import * as S from '../../styled';

const ThirdRule = ({
  replaceConditions,
  currentValue,
  targetValue,
  handleCurrentValueChange,
  handleTargetValueChange,
  selectedReplaceConditions,
  handleReplaceConditionsChange,
  data,
}: any) => {
  const { t } = useTranslation('common');

  return (
    <S.ContentContainer>
      {t('etl.step4.ifString')}
      <Select
        onChange={handleReplaceConditionsChange}
        value={data?.condition || selectedReplaceConditions}
        sx={{
          width: 70,
          m: 1,
        }}
        size="small"
      >
        {replaceConditions.map(item => (
          <MenuItem key={item} value={item}>
            {item}
          </MenuItem>
        ))}
      </Select>
      <TextField
        id="outlined-number"
        onChange={handleCurrentValueChange}
        value={data?.compare || currentValue}
        size="small"
        sx={{
          width: 100,
          m: 1,
        }}
      />
      {t('etl.step4.replace')}
      <TextField
        id="outlined-number"
        value={data?.replace || targetValue}
        onChange={handleTargetValueChange}
        size="small"
        sx={{
          width: 100,
          m: 1,
        }}
      />
    </S.ContentContainer>
  );
};

export default ThirdRule;
