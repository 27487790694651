import { useMemo } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';
import { useTranslation } from 'react-i18next';
import { yupResolver } from '@hookform/resolvers/yup';
import TextField from '@mui/material/TextField';
import Button from 'components/Button';
import { theme } from 'theme';

import validationSchema, { Email } from './validationSchema';
import * as S from './styled';

type LoginFormProps = {
  onSubmit: (params: Email) => void;
};

const LoginForm = ({ onSubmit }: LoginFormProps) => {
  const { t } = useTranslation('common');

  const schema = useMemo(() => validationSchema(t), [t]);

  const {
    handleSubmit,
    control,
    formState: { isSubmitting, errors },
  } = useForm<Email>({
    resolver: yupResolver(schema),
  });

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <S.Container>
        <S.Wrapper>
          <Controller
            name="email"
            control={control}
            render={({ field: { onChange, value } }) => (
              <TextField
                InputLabelProps={{
                  shrink: true,
                  required: true,
                }}
                fullWidth
                type="email"
                onChange={onChange}
                value={value}
                error={!!errors.email}
                label={t('restorePassword.emailPlaceholder')}
                disabled={isSubmitting}
              />
            )}
          />
          <S.Error>
            <ErrorMessage name="email" errors={errors} />
          </S.Error>
        </S.Wrapper>
        <S.ButtonWrapper>
          <Button
            textButton={t('restorePassword.restore')}
            buttonProps={{
              bgColor: theme.colors.primary,
              hoverColor: theme.colors.primaryShadow,
              variant: 'contained',
              type: 'submit',
              disabled: isSubmitting,
            }}
          />
        </S.ButtonWrapper>
      </S.Container>
    </form>
  );
};

export default LoginForm;
