import React, { useEffect, useMemo } from 'react';
import { TextField, RadioGroup, Radio, InputAdornment, IconButton } from '@mui/material';
import ClearOutlinedIcon from '@mui/icons-material/ClearOutlined';
import DoneOutlinedIcon from '@mui/icons-material/DoneOutlined';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { Controller, useForm } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';
import { yupResolver } from '@hookform/resolvers/yup';
import { useTranslation } from 'react-i18next';

import Button from 'components/Button';
import { User, ClientUserDetails, UserDetailsForm } from 'typings/entities/users';
import { USER, ADMIN } from 'constants/roles';
import { theme } from 'theme';
import { AddUserProfileSchema, UpdateUserProfileSchema } from './validationSchema';

import * as S from './styled';

type InitialState = {
  newPassword: boolean;
  repeatNewPassword: boolean;
};

type Props = {
  selectedUserId: number | null;
  handleFormSubmit: (req: ClientUserDetails) => void;
  handleFormCancel: () => void;
  selectedUser: User | undefined;
};

const UserProfileForm = ({ selectedUserId, handleFormSubmit, handleFormCancel, selectedUser }: Props) => {
  const { t } = useTranslation('common');
  const schema = useMemo(() => {
    return selectedUserId === -1 ? AddUserProfileSchema(t) : UpdateUserProfileSchema(t);
  }, [selectedUserId, t]);
  const defaultValues = useMemo(() => {
    return selectedUserId === -1
      ? {
          firstname: '',
          RadioGroup: '1',
          lastname: '',
          email: '',
          phone: '',
          status: '1',
          role: USER,
        }
      : {
          ...selectedUser,
          status: String(selectedUser?.status.id),
          role: String(selectedUser?.roles),
          RadioGroup: String(selectedUser?.status.id),
        };
  }, [selectedUserId, selectedUser]);

  const initialState = {
    newPassword: false,
    repeatNewPassword: false,
  };
  const [visibility, setVisibility] = React.useState<InitialState>(initialState);

  const {
    handleSubmit,
    control,
    reset,
    formState: { isSubmitting, errors },
  } = useForm<UserDetailsForm>({
    defaultValues,
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    reset(defaultValues);
  }, [defaultValues, reset]);

  return (
    <form onSubmit={handleSubmit(handleFormSubmit)}>
      <S.ContentContainer>
        <S.ContentWrapper>
          <Controller
            name="firstname"
            control={control}
            render={({ field: { onChange, value } }) => (
              <TextField
                InputLabelProps={{
                  shrink: true,
                  required: true,
                }}
                size="small"
                onChange={onChange}
                value={value}
                error={!!errors.firstname}
                label={t('userListPage.firstNameLabel')}
                disabled={isSubmitting}
              />
            )}
          />
          <S.Error>
            <ErrorMessage name="firstname" errors={errors} />
          </S.Error>
        </S.ContentWrapper>
        <S.ContentWrapper isRadio>
          <Controller
            render={({ field }) => (
              <RadioGroup aria-label="status" {...field}>
                <S.ContentRadioLabel>{t('userListPage.radioLabel')}</S.ContentRadioLabel>
                <S.RadioContainer>
                  <S.RadioControlLabel
                    value="1"
                    control={<Radio size="small" />}
                    label={t('userListPage.activeStatus')}
                  />
                  <S.RadioControlLabel
                    value="2"
                    control={<Radio size="small" />}
                    label={t('userListPage.inactiveStatus')}
                  />
                </S.RadioContainer>
              </RadioGroup>
            )}
            name="status"
            control={control}
          />
          <S.Error style={{ width: '300px' }}>
            <ErrorMessage name="status" errors={errors} />
          </S.Error>
        </S.ContentWrapper>
      </S.ContentContainer>
      <S.ContentContainer>
        <S.ContentWrapper>
          <Controller
            name="lastname"
            control={control}
            render={({ field: { onChange, value } }) => (
              <TextField
                InputLabelProps={{
                  shrink: true,
                  required: true,
                }}
                size="small"
                onChange={onChange}
                value={value}
                error={!!errors.lastname}
                label={t('userListPage.lastNameLabel')}
                disabled={isSubmitting}
              />
            )}
          />
          <S.Error>
            <ErrorMessage name="lastname" errors={errors} />
          </S.Error>
        </S.ContentWrapper>
        <S.ContentWrapper isRadio>
          <Controller
            render={({ field }) => (
              <RadioGroup aria-label="role" {...field}>
                <S.ContentRadioLabel>{t('clientListPage.selectRoleLabel')}</S.ContentRadioLabel>
                <S.RadioContainer>
                  <S.RadioControlLabel
                    value={USER}
                    control={<Radio size="small" />}
                    label={t('clientListPage.userRole')}
                  />
                  <S.RadioControlLabel
                    value={ADMIN}
                    control={<Radio size="small" />}
                    label={t('clientListPage.adminRole')}
                  />
                </S.RadioContainer>
              </RadioGroup>
            )}
            name="role"
            control={control}
          />
          <S.Error style={{ width: '300px' }}>
            <ErrorMessage name="role" errors={errors} />
          </S.Error>
        </S.ContentWrapper>
      </S.ContentContainer>
      <S.ContentContainer>
        <S.ContentWrapper>
          <Controller
            name="email"
            control={control}
            render={({ field: { onChange, value } }) => (
              <TextField
                InputLabelProps={{
                  shrink: true,
                  required: true,
                }}
                size="small"
                type="email"
                onChange={onChange}
                value={value}
                error={!!errors.email}
                label={t('userListPage.emailLabel')}
                disabled={isSubmitting}
              />
            )}
          />
          <S.Error>
            <ErrorMessage name="email" errors={errors} />
          </S.Error>
        </S.ContentWrapper>
      </S.ContentContainer>
      <S.ContentContainer>
        <S.ContentWrapper>
          <Controller
            name="phone"
            control={control}
            render={({ field: { onChange, value } }) => (
              <TextField
                InputLabelProps={{
                  shrink: true,
                }}
                size="small"
                onChange={onChange}
                value={value}
                error={!!errors.phone}
                label={t('userListPage.phoneLabel')}
                disabled={isSubmitting}
              />
            )}
          />
          <S.Error>
            <ErrorMessage name="phone" errors={errors} />
          </S.Error>
        </S.ContentWrapper>
      </S.ContentContainer>
      <S.ContentContainer>
        <S.ContentWrapper>
          <Controller
            name="plainPassword.first"
            control={control}
            render={({ field: { onChange, value } }) => (
              <S.PasswordTextField
                id="first"
                type={visibility.newPassword ? 'text' : 'password'}
                value={value}
                error={!!errors.plainPassword?.first}
                onChange={onChange}
                label={t('userListPage.newPassword')}
                disabled={isSubmitting}
                size="small"
                InputLabelProps={{
                  shrink: true,
                  required: true,
                }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        onClick={() => {
                          setVisibility({
                            newPassword: !visibility.newPassword,
                            repeatNewPassword: visibility.repeatNewPassword,
                          });
                        }}
                        edge="end"
                      >
                        {visibility.newPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                fullWidth
              />
            )}
          />
          <S.Error>
            <ErrorMessage name="plainPassword.first" errors={errors} />
          </S.Error>
        </S.ContentWrapper>
        <S.ContentWrapper>
          <Controller
            name="plainPassword.second"
            control={control}
            render={({ field: { onChange, value } }) => (
              <S.PasswordTextField
                id="second"
                type={visibility.repeatNewPassword ? 'text' : 'password'}
                value={value}
                error={!!errors.plainPassword?.second}
                onChange={onChange}
                label={t('userListPage.repeatNewPassword')}
                disabled={isSubmitting}
                size="small"
                InputLabelProps={{
                  shrink: true,
                  required: true,
                }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        onClick={() => {
                          setVisibility({
                            newPassword: visibility.newPassword,
                            repeatNewPassword: !visibility.repeatNewPassword,
                          });
                        }}
                        edge="end"
                      >
                        {visibility.repeatNewPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                fullWidth
              />
            )}
          />
          <S.Error>
            <ErrorMessage name="plainPassword.second" errors={errors} />
          </S.Error>
        </S.ContentWrapper>
      </S.ContentContainer>
      <S.ButtonContainer>
        <S.ButtonWrapper>
          <Button
            textButton={t('saveButton')}
            buttonProps={{
              bgColor: theme.colors.primary,
              hoverColor: theme.colors.primaryShadow,
              variant: 'contained',
              type: 'submit',
              disabled: isSubmitting,
              startIcon: <DoneOutlinedIcon />,
            }}
          />
        </S.ButtonWrapper>
        <S.ButtonWrapper>
          <Button
            textButton={t('cancelButton')}
            buttonProps={{
              bgColor: theme.colors.grey,
              hoverColor: theme.colors.greyShadow,
              variant: 'outlined',
              onClick: handleFormCancel,
              disabled: isSubmitting,
              startIcon: <ClearOutlinedIcon />,
            }}
          />
        </S.ButtonWrapper>
      </S.ButtonContainer>
    </form>
  );
};

export default UserProfileForm;
