import axios, { AxiosResponse, AxiosError } from 'axios';

import { FALLBACK_LANGUAGE } from 'configs/locale';
import { actions as authActions } from 'redux/auth';
import { getI18nextLng } from 'services/localStorage';

const baseURL = process.env.REACT_APP_BASE_API;

export const http = axios.create({
  baseURL,
  withCredentials: true,
  headers: {
    'Accept-Language': getI18nextLng() || FALLBACK_LANGUAGE,
  },
});

export const addUserLogoutInterceptor = (statusCodes: number[], dispatch) => {
  http.interceptors.response.use(
    (response: AxiosResponse) => response,
    (error: AxiosError) => {
      const status = error?.response?.status;

      if (status && statusCodes.includes(status)) {
        localStorage.removeItem('user');
        localStorage.removeItem('token');

        dispatch(authActions.signOut());
      }

      return Promise.reject(error);
    },
  );
};
