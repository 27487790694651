export const routes = {
  notFound: '/not-found',
  authModule: {
    auth: '/auth',
    adminLogin: '/auth/admin/login',
    login: '/auth/login',
    resetPassword: '/auth/reset-password',
    restorePassword: '/auth/restore-password',
  },
  dashboardModule: {
    dashboard: '/dashboard',
    editProfile: '/dashboard/edit-profile',
    clientList: '/dashboard/client-list',
    etl: '/dashboard/etl',
    patientList: '/dashboard/patient-list',
    userList: '/dashboard/user-list',
    virusesList: '/dashboard/viruses-list',
  },
};
