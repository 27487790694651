import React, { useEffect, useState, useRef } from 'react';
import { useAsyncFn } from 'react-use';
import { toast } from 'react-toastify';
import debounce from 'lodash/debounce';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { Paper } from '@mui/material';
import * as Sentry from '@sentry/browser';

import {
  getExaminations,
  postExaminations,
  getExaminationById,
  deleteExaminationById,
  putExamination,
  getExaminationMethod,
} from 'http/examination';
import { NewExamination, Filter } from 'typings/entities/examination';
import { postFile, getFile } from 'http/files';
import { getAllViruses } from 'http/viruses';
import useConfirmationDialog from 'hooks/useConfirmationDialog';
import useModalState from 'hooks/useModalState';
import { getError } from 'utils/error';
import convertingUndefinedToString from 'utils/convertingUndefinedToString';
import Loader from 'components/Loader';
import SubHeader from 'components/SubHeader';
import ExaminationTable from './ExaminationTable';
import Modal from './FilterModal';
import ExaminationForm from './ExaminationForm';

import * as S from './styled';

type Order = 'asc' | 'desc';

const Examination = ({
  selectedPatientId,
  selectedPatientNumber,
}: {
  selectedPatientId: number | null;
  selectedPatientNumber: string | undefined;
}) => {
  const { t } = useTranslation('common');
  const formRef = useRef(null);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);
  const [selectedExaminationId, setSelectedExaminationId] = useState<number | null>(null);
  const [search, setSearch] = useState<string>('');
  const [sortDirection, setSortDirection] = useState<Order>('asc');
  const [nameField, setNameField] = useState<string>('');
  const [files, setFiles] = useState<[]>([]);
  const [examinationId, setExaminationId] = useState<number>();
  const [filter, setFilter] = useState({});
  const delayedSetSearch = debounce((string: string) => setSearch(string), 500);
  const { isOpen, onToggle } = useModalState();
  const [errorMessage, setErrorMessage] = useState<any>(undefined);
  const scrollToRef = ref => window.scrollTo(0, ref.current.offsetTop);
  const executeScroll = () => setTimeout(() => scrollToRef(formRef), 500);

  // eslint-disable-next-line consistent-return
  const [examinationsListState, getExaminationsList] = useAsyncFn(async () => {
    try {
      if (!selectedPatientId || selectedPatientId === -1) return;

      const data = await getExaminations({
        id: selectedPatientId,
        count: rowsPerPage,
        page,
        search,
        sortDirection,
        sortByField: nameField,
        ...filter,
      });

      // eslint-disable-next-line consistent-return
      return data;
    } catch (err: any) {
      Sentry.captureException(new Error(getError(err).error));
      toast.error(getError(err).error);
    }
  }, [page, rowsPerPage, search, sortDirection, nameField, filter, selectedPatientId]);

  const [examinationState, getExaminationData] = useAsyncFn(
    // eslint-disable-next-line consistent-return
    async (id: number) => {
      try {
        const res = await getExaminationById(id);
        executeScroll();
        return res;
      } catch (err: any) {
        Sentry.captureException(new Error(getError(err).error));
        toast.error(getError(err).error);
      }
    },
    [],
  );

  const [examinationMethodState, getExaminationMethodList] = useAsyncFn(
    // eslint-disable-next-line consistent-return
    async () => {
      try {
        return await getExaminationMethod();
      } catch (err: any) {
        Sentry.captureException(new Error(getError(err).error));
        toast.error(getError(err).error);
      }
    },
    [],
  );

  // eslint-disable-next-line consistent-return
  const [virusesState, getVirusesList] = useAsyncFn(async () => {
    try {
      return await getAllViruses();
    } catch (err: any) {
      Sentry.captureException(new Error(getError(err).error));
      toast.error(getError(err).error);
    }
  }, []);

  const [, removeExaminationById] = useAsyncFn(async () => {
    try {
      await deleteExaminationById(examinationId);
      getExaminationsList();

      toast.success(t('success'));
    } catch (err: any) {
      Sentry.captureException(new Error(getError(err).error));
      toast.error(getError(err).error);
    }
  }, [examinationId]);

  // eslint-disable-next-line consistent-return
  const [createExaminationState, createExamination] = useAsyncFn(
    async (req: NewExamination) => {
      try {
        const newReq: any = convertingUndefinedToString(req);
        delete newReq.uploadDocument;

        await postExaminations(
          { ...newReq, documents: [], patient: selectedPatientId, date: moment(req.date).format('Y-M-D') },
          selectedPatientId,
        );
        getExaminationsList();
        setSelectedExaminationId(null);

        toast.success(t('success'));
      } catch (err: any) {
        Sentry.captureException(new Error(getError(err).error));
        const errors = getError(err);

        toast.error(errors.error);
        setErrorMessage(errors.errorsForm);
      }
    },
    [selectedPatientId],
  );

  const [, getFileById] = useAsyncFn(async (id: number) => {
    try {
      await getFile(id);
    } catch (err: any) {
      Sentry.captureException(new Error(getError(err).error));
      toast.error(getError(err).error);
    }
  }, []);

  const [, addFile] = useAsyncFn(async req => {
    try {
      const bodyFormData = new FormData();
      bodyFormData.append('file', req);

      const res = await postFile(bodyFormData);
      setFiles(res);
    } catch (err: any) {
      Sentry.captureException(new Error(getError(err).error));
      toast.error(getError(err).error);
    }
  }, []);

  // eslint-disable-next-line consistent-return
  const [updateExaminationState, updateExamination] = useAsyncFn(
    async (req: NewExamination) => {
      try {
        const newReq: any = convertingUndefinedToString(req);
        delete newReq.uploadDocument;
        delete newReq.id;

        await putExamination(
          { ...newReq, documents: [], patient: selectedPatientId, date: moment(req.date).format('Y-M-D') },
          req.id,
        );
        getExaminationsList();
        setSelectedExaminationId(null);

        toast.success(t('success'));
      } catch (err: any) {
        Sentry.captureException(new Error(getError(err).error));
        const errors = getError(err);

        toast.error(errors.error);
        setErrorMessage(errors.errorsForm);
      }
    },
    [selectedPatientId, rowsPerPage, page, search, sortDirection],
  );

  useEffect(() => {
    getExaminationsList();
  }, [getExaminationsList, rowsPerPage, page, search, sortDirection, nameField, selectedPatientId]);

  useEffect(() => {
    setSelectedExaminationId(null);
  }, [selectedPatientId]);

  useEffect(() => {
    getExaminationMethodList();
    getVirusesList();
  }, [getExaminationMethodList, getVirusesList]);

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    delayedSetSearch(event.target.value);
  };

  const handleRemoveExamination = () => {
    removeExaminationById();
    setSelectedExaminationId(null);
  };

  const { Dialog, onOpen } = useConfirmationDialog({
    bodyText: t('confirmationDialog.bodyTextExamination'),
    confirmationButtonText: t('confirmationDialog.confirmationButtonText'),
    onConfirmClick: handleRemoveExamination,
  });

  const handleSort = (name: string, sort: Order) => {
    setSortDirection(sort);
    setNameField(name);
  };

  const handleDeleteClick = (id: number) => {
    setExaminationId(id);
    onOpen();
  };

  const handleFormSubmit = (req: NewExamination) => {
    if (req.id) {
      updateExamination(req);
    } else {
      createExamination(req);
    }
  };

  const handleOpenModal = () => {
    onToggle();
  };

  const handleFilterChange = (props: Filter) => {
    setFilter(props);
    onToggle();
  };

  const handleResetFilter = () => {
    setFilter({});
    onToggle();
  };

  const handleSelectedExamination = (id: number) => {
    getExaminationData(id);
    setSelectedExaminationId(id);
  };

  const handleAddNewExamination = () => {
    executeScroll();
    setSelectedExaminationId(-1);
  };

  const handleFormCancel = () => {
    setSelectedExaminationId(null);
  };

  return (
    <S.Container>
      {(examinationsListState.loading ||
        examinationState.loading ||
        updateExaminationState.loading ||
        createExaminationState.loading) && <Loader />}
      <Dialog />
      {virusesState?.value?.items && (
        <Modal
          open={isOpen}
          close={onToggle}
          handleFilterChange={handleFilterChange}
          handleResetFilter={handleResetFilter}
          virusesState={virusesState.value.items}
        />
      )}
      <SubHeader
        title=""
        subTitle
        textButton={t('examination.addExamination')}
        onClick={handleAddNewExamination}
        onSearchChange={handleSearchChange}
        onFilterChange={handleOpenModal}
      />
      <Paper>
        {examinationsListState.value && (
          <ExaminationTable
            order={sortDirection}
            orderBy={nameField}
            examinationsList={examinationsListState.value.items}
            setRowsPerPage={setRowsPerPage}
            rowsPerPage={rowsPerPage}
            allRows={examinationsListState.value.allCount}
            setPage={setPage}
            page={page}
            onRemoveTableRow={handleDeleteClick}
            oneSorTablet={handleSort}
            handleSelectedExamination={handleSelectedExamination}
          />
        )}
      </Paper>
      <div ref={formRef}>
        {virusesState?.value?.items && examinationMethodState?.value && selectedExaminationId && (
          <S.FormWrapper>
            <ExaminationForm
              virusesState={virusesState.value.items}
              selectedPatientNumber={selectedPatientNumber}
              examinationMethodState={examinationMethodState.value}
              handleFormSubmit={handleFormSubmit}
              selectedExamination={examinationState.value}
              selectedExaminationId={selectedExaminationId}
              selectedPatientId={selectedPatientId}
              handleFormCancel={handleFormCancel}
              addFile={addFile}
              files={files}
              errorMessage={errorMessage}
            />
          </S.FormWrapper>
        )}
      </div>
    </S.Container>
  );
};

export default Examination;
