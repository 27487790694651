import React, { useMemo, useState } from 'react';
import ClearOutlinedIcon from '@mui/icons-material/ClearOutlined';
import DoneOutlinedIcon from '@mui/icons-material/DoneOutlined';
import RestartAltOutlinedIcon from '@mui/icons-material/RestartAltOutlined';
import Button from 'components/Button';
import { useTranslation } from 'react-i18next';
import { ErrorMessage } from '@hookform/error-message';
import moment from 'moment';
import AdapterMoment from '@mui/lab/AdapterMoment';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DatePicker from '@mui/lab/DatePicker';
import { yupResolver } from '@hookform/resolvers/yup';
import { Controller, useForm } from 'react-hook-form';
import { Dialog, DialogContent, TextField, Select, MenuItem, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

import { Filter } from 'typings/entities/examination';
import { Virus } from 'typings/entities/viruses';
import { theme } from 'theme';

import validationSchema from './validationSchema';
import * as S from './styled';

type Props = {
  open: boolean;
  close: () => void;
  handleFilterChange: (props: Filter) => void;
  handleResetFilter: () => void;
  virusesState: Virus[];
};

const Modal = ({ open, close, handleFilterChange, handleResetFilter, virusesState }: Props) => {
  const { t } = useTranslation('common');
  const schema = useMemo(() => validationSchema(t), [t]);
  const [dateFrom, setDateFrom] = useState<moment.Moment | string | null>(null);
  const [dateTo, setDateTo] = useState<moment.Moment | string | null>(moment());

  const defaultValues = {
    examinationFrom: null,
    examinationTo: null,
    virus: '',
  };

  const {
    handleSubmit,
    control,
    reset,
    formState: { isSubmitting, errors },
  } = useForm<any>({
    resolver: yupResolver(schema),
    defaultValues,
  });

  const onReset = () => {
    setDateFrom(null);
    setDateTo(moment());
    reset();
    handleResetFilter();
  };

  const handleSubmitFilter = (filterProps: Filter) => {
    handleFilterChange({
      ...filterProps,
      examinationFrom: moment(filterProps.examinationFrom).format('YYYY-MM-DD'),
      examinationTo: moment(filterProps.examinationTo).format('YYYY-MM-DD'),
    });
  };

  return (
    <Dialog onClose={close} open={open} fullWidth maxWidth="sm">
      <S.ModalTitle sx={{ m: 1 }}>
        {t('filter')}
        <IconButton onClick={close}>
          <CloseIcon />
        </IconButton>
      </S.ModalTitle>
      <DialogContent>
        <form onSubmit={handleSubmit(handleSubmitFilter)}>
          <S.Wrapper />
          <S.Wrapper>
            <S.ContextContainer>
              <S.ContextText>{t('examination.examinationDateFrom')}</S.ContextText>
              <S.ContextPicker>
                <Controller
                  name="examinationFrom"
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <LocalizationProvider dateAdapter={AdapterMoment}>
                      <DatePicker
                        value={value || null}
                        onChange={e => {
                          setDateFrom(e ? moment(e).format('MM/DD/YYYY') : null);
                          onChange(e ? moment(e).format('MM/DD/YYYY') : null);
                        }}
                        renderInput={params => {
                          return <TextField {...params} error={!!errors.examinationFrom} />;
                        }}
                        maxDate={moment(dateTo)}
                      />
                    </LocalizationProvider>
                  )}
                />
                <S.Error>
                  <ErrorMessage name="examinationFrom" errors={errors} />
                </S.Error>
              </S.ContextPicker>
            </S.ContextContainer>
          </S.Wrapper>
          <S.Wrapper>
            <S.ContextContainer>
              <S.ContextText>{t('examination.examinationDateTo')}</S.ContextText>
              <S.ContextPicker>
                <Controller
                  name="examinationTo"
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <LocalizationProvider dateAdapter={AdapterMoment}>
                      <DatePicker
                        value={value || null}
                        onChange={e => {
                          setDateTo(e ? moment(e).format('MM/DD/YYYY') : null);
                          onChange(e ? moment(e).format('MM/DD/YYYY') : null);
                        }}
                        renderInput={params => {
                          return <TextField {...params} error={!!errors.examinationTo} />;
                        }}
                        maxDate={moment()}
                        minDate={moment(dateFrom)}
                      />
                    </LocalizationProvider>
                  )}
                />
                <S.Error>
                  <ErrorMessage name="examinationTo" errors={errors} />
                </S.Error>
              </S.ContextPicker>
            </S.ContextContainer>
          </S.Wrapper>
          <S.Wrapper>
            <S.ContextContainer>
              <S.ContextText>{t('examination.virusID')}</S.ContextText>
              <S.ContextPicker>
                <Controller
                  name="virus"
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <>
                      <Select
                        onChange={onChange}
                        value={String(value)}
                        sx={{
                          width: 231,
                        }}
                      >
                        {virusesState.map((virus: Virus) => (
                          <MenuItem key={virus.id} value={String(virus.id)}>
                            {virus.virusId}
                          </MenuItem>
                        ))}
                      </Select>
                    </>
                  )}
                />
              </S.ContextPicker>
            </S.ContextContainer>
          </S.Wrapper>
          <S.ButtonContainer>
            <S.ButtonWrapper>
              <Button
                textButton={t('saveButton')}
                buttonProps={{
                  bgColor: theme.colors.primary,
                  hoverColor: theme.colors.primaryShadow,
                  variant: 'contained',
                  type: 'submit',
                  disabled: isSubmitting,
                  startIcon: <DoneOutlinedIcon />,
                }}
              />
            </S.ButtonWrapper>
            <S.ButtonWrapper>
              <Button
                textButton={t('userListPage.resetButton')}
                buttonProps={{
                  bgColor: theme.colors.red,
                  hoverColor: theme.colors.redShadow,
                  variant: 'outlined',
                  onClick: onReset,
                  disabled: isSubmitting,
                  startIcon: <RestartAltOutlinedIcon />,
                }}
              />
            </S.ButtonWrapper>
            <S.ButtonWrapper>
              <Button
                textButton={t('cancelButton')}
                buttonProps={{
                  bgColor: theme.colors.grey,
                  hoverColor: theme.colors.greyShadow,
                  variant: 'outlined',
                  onClick: close,
                  disabled: isSubmitting,
                  startIcon: <ClearOutlinedIcon />,
                }}
              />
            </S.ButtonWrapper>
          </S.ButtonContainer>
        </form>
      </DialogContent>
    </Dialog>
  );
};

export default Modal;
