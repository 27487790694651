import React, { useMemo, useEffect } from 'react';
import { TextField, RadioGroup, FormControlLabel, Radio } from '@mui/material';
import ClearOutlinedIcon from '@mui/icons-material/ClearOutlined';
import DoneOutlinedIcon from '@mui/icons-material/DoneOutlined';
import Button from 'components/Button';
import { Controller, useForm } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';
import { yupResolver } from '@hookform/resolvers/yup';
import { useTranslation } from 'react-i18next';
import AdapterMoment from '@mui/lab/AdapterMoment';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DatePicker from '@mui/lab/DatePicker';
import moment from 'moment';
import { PatientDetails, Patient } from 'typings/entities/patients';
import { theme } from 'theme';

import validationSchema from './validationSchema';
import * as S from './styled';

type Props = {
  handleFormSubmit: (req: PatientDetails) => void;
  handleFormCancel: () => void;
  selectedPatientId: number | null;
  errorMessage: any;
  selectedPatient: Patient | undefined;
};

const PatientForm = ({
  handleFormSubmit,
  selectedPatient,
  selectedPatientId,
  handleFormCancel,
  errorMessage,
}: Props) => {
  const { t } = useTranslation('common');
  const schema = useMemo(() => validationSchema(t), [t]);
  const defaultValues = useMemo(() => {
    return selectedPatientId === -1
      ? {
          number: '',
          zipcode: '',
          comment: '',
          gender: '1',
          dateOfBirth: null,
        }
      : {
          ...selectedPatient,
          dateOfBirth: selectedPatient?.dateOfBirth ? moment(selectedPatient?.dateOfBirth).format('MM/DD/YYYY') : null,
          gender: String(selectedPatient?.gender.id),
        };
  }, [selectedPatientId, selectedPatient]);

  const {
    handleSubmit,
    control,
    reset,
    setError,
    formState: { isSubmitting, errors },
  } = useForm<PatientDetails>({
    defaultValues,
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    if (errorMessage) {
      errorMessage.map(item => setError(item.key, item));
    }
  }, [errorMessage, setError]);

  useEffect(() => {
    reset(defaultValues);
  }, [defaultValues, reset]);

  return (
    <form onSubmit={handleSubmit(handleFormSubmit)}>
      <S.ContentContainer>
        <S.Wrapper>
          <Controller
            name="number"
            control={control}
            render={({ field: { onChange, value } }) => (
              <TextField
                InputLabelProps={{
                  shrink: true,
                  required: true,
                }}
                size="small"
                onChange={onChange}
                value={value}
                error={!!errors.number}
                label={t('patientsPage.patientNumber')}
                disabled={isSubmitting}
              />
            )}
          />
          <S.Error>
            <ErrorMessage name="number" errors={errors} />
          </S.Error>
        </S.Wrapper>
        <S.Wrapper>
          <Controller
            name="gender"
            control={control}
            render={({ field }) => (
              <RadioGroup aria-label={t('gender')} {...field}>
                <S.ContentRadioLabel>{t('gender')}</S.ContentRadioLabel>
                <S.RadioContainer>
                  <FormControlLabel value="1" control={<Radio size="small" />} label={t('male')} />
                  <FormControlLabel value="2" control={<Radio size="small" />} label={t('female')} />
                </S.RadioContainer>
              </RadioGroup>
            )}
          />
          <S.Error>
            <ErrorMessage name="gender" errors={errors} />
          </S.Error>
        </S.Wrapper>
      </S.ContentContainer>
      <S.ContentContainer>
        <S.Wrapper>
          <Controller
            name="zipcode"
            control={control}
            render={({ field: { onChange, value } }) => (
              <TextField
                InputLabelProps={{
                  shrink: true,
                }}
                size="small"
                onChange={onChange}
                value={value}
                label={t('patientsPage.zipcode')}
                disabled={isSubmitting}
              />
            )}
          />
        </S.Wrapper>
        <S.Wrapper>
          <Controller
            name="dateOfBirth"
            control={control}
            render={({ field: { onChange, value } }) => (
              <LocalizationProvider dateAdapter={AdapterMoment}>
                <DatePicker
                  value={value || null}
                  onChange={e => {
                    onChange(e ? moment(e).format('MM/DD/YYYY') : null);
                  }}
                  renderInput={params => {
                    return (
                      <TextField
                        {...params}
                        label={t('patientsPage.dateOfBirth')}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        size="small"
                      />
                    );
                  }}
                  maxDate={moment()}
                />
              </LocalizationProvider>
            )}
          />
          <S.Error>
            <ErrorMessage name="dateOfBirth" errors={errors} />
          </S.Error>
        </S.Wrapper>
      </S.ContentContainer>
      <S.CommentContainer>
        <Controller
          name="comment"
          control={control}
          render={({ field: { onChange, value } }) => (
            <TextField
              InputLabelProps={{
                shrink: true,
              }}
              size="small"
              onChange={onChange}
              value={value}
              multiline
              rows={3}
              fullWidth
              label={t('comment')}
              disabled={isSubmitting}
            />
          )}
        />
        <S.Error>
          <ErrorMessage name="comment" errors={errors} />
        </S.Error>
      </S.CommentContainer>
      <S.ButtonContainer>
        <S.ButtonWrapper>
          <Button
            textButton={t('saveButton')}
            buttonProps={{
              bgColor: theme.colors.primary,
              hoverColor: theme.colors.primaryShadow,
              variant: 'contained',
              type: 'submit',
              disabled: isSubmitting,
              startIcon: <DoneOutlinedIcon />,
            }}
          />
        </S.ButtonWrapper>
        <S.ButtonWrapper>
          <Button
            textButton={t('cancelButton')}
            buttonProps={{
              bgColor: theme.colors.grey,
              hoverColor: theme.colors.greyShadow,
              variant: 'outlined',
              onClick: handleFormCancel,
              disabled: isSubmitting,
              startIcon: <ClearOutlinedIcon />,
            }}
          />
        </S.ButtonWrapper>
      </S.ButtonContainer>
    </form>
  );
};

export default PatientForm;
