import styled from 'styled-components';
import { FormControlLabel, Typography } from '@mui/material';

type WrapperType = {
  isRadio?: boolean;
};

export const ContentContainer = styled.div`
  margin-top: 24px;
  margin-left: 10px;
  margin-bottom: 40px;
`;

export const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  flex-direction: row;
`;

export const Wrapper = styled(Container)<WrapperType>`
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
  width: ${({ isRadio }) => (isRadio ? '300px' : '200px')};

  .css-1u3bzj6-MuiFormControl-root-MuiTextField-root {
    height: 40px;
    width: 100%;
  }
`;

export const Error = styled.div`
  color: ${({ theme }) => theme.colors.red};
  text-align: left;
  width: 100%;
`;

export const ContentWrapper = styled(Container)`
  width: 350px;
  margin: 0 0 20px;
`;

export const ContentRadioLabel = styled(Typography)`
  width: 300px;
  margin: -10px 0;
  color: rgba(0, 0, 0, 0.6);
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
  font-weight: 400;
  font-size: 13px;
  line-height: 1.4375em;
`;

export const RadioContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 5px;
`;

export const RadioControlLabel = styled(FormControlLabel)`
  width: 120px;
`;

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  margin-bottom: 30px;
`;

export const ButtonWrapper = styled.div`
  margin-right: 10px;
  margin-bottom: 10px;
`;
