import { useLayoutEffect } from 'react';
import { useAsyncFn } from 'react-use';
import { useHistory, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import * as Sentry from '@sentry/browser';

import { SUPER_ADMIN, ADMIN } from 'constants/roles';
import * as httpAuth from 'http/auth';
import { routes } from 'services/router';
import { login } from 'http/auth';
import { useDispatch } from 'react-redux';
import { actions } from 'redux/auth';
import {
  setUser,
  getToken,
  setRole,
  getRole,
  setToken,
  setOpenedMenu,
  setSelectedMenuItemId,
} from 'services/localStorage';
import { getError } from 'utils/error';
import Form from './Form';
import { LoginData } from './Form/validationSchema';

import * as S from './styled';

const Login = () => {
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  const { t } = useTranslation('common');
  const isSuperAdmin = getRole() === SUPER_ADMIN;

  const [, getCSRFToken] = useAsyncFn(async () => {
    try {
      const data = await httpAuth.getToken();

      setToken(data);
    } catch (err: any) {
      Sentry.captureException(new Error(getError(err).error));
      console.error(err);
    }
  }, []);

  useLayoutEffect(() => {
    const items = location.pathname.split('/');
    const role = items[2];
    getCSRFToken();

    if (role === 'admin') {
      setRole(SUPER_ADMIN);
    } else {
      setRole('');
    }
  }, [location.pathname, dispatch, getCSRFToken]);

  const [, authenticate] = useAsyncFn(
    async (values?: LoginData) => {
      try {
        if (!values) {
          return;
        }

        const token = getToken();
        const data = await login({ _csrf_token: token, ...values, isAdmin: isSuperAdmin });

        dispatch(actions.authenticate(data));
        setUser(data);
        setRole(data?.roles[0]);

        let url;

        switch (data?.roles[0]) {
          case SUPER_ADMIN:
            url = routes.dashboardModule.clientList;
            setOpenedMenu(['administration']);
            setSelectedMenuItemId(1);
            break;
          case ADMIN:
            url = routes.dashboardModule.userList;
            setOpenedMenu(['administration']);
            setSelectedMenuItemId(3);
            break;
          default:
            url = routes.dashboardModule.patientList;
            setOpenedMenu(['data']);
            setSelectedMenuItemId(5);
        }

        history.push(url);
      } catch (err: any) {
        Sentry.captureException(new Error(getError(err).error));
        toast.error(getError(err).error);
      }
    },
    [isSuperAdmin],
  );

  return (
    <S.Container>
      <S.Wrapper>
        <S.Title>{t('signIn')}</S.Title>
        <Form onSubmit={authenticate} />
      </S.Wrapper>
    </S.Container>
  );
};

export default Login;
