import { CircularProgress } from '@mui/material';

import * as S from './styled';

const Loader = () => {
  return (
    <S.LoaderField>
      <CircularProgress />
    </S.LoaderField>
  );
};

export default Loader;
