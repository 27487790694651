import { SessionUser } from 'typings/entities/user';
import { ADMIN, SUPER_ADMIN, USER } from 'constants/roles';

export const login = ({ data }: { data: SessionUser }): SessionUser => {
  const role = data.roles.map((item: string) => {
    switch (item) {
      case 'ROLE_USER':
        return USER;
      case 'ROLE_ADMIN':
        return ADMIN;
      default:
        return SUPER_ADMIN;
    }
  });

  return {
    ...data,
    roles: role,
  };
};

export const token = ({ data }): string => data.csrf_token;
