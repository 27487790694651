import { Response as ClientResponse, Client } from 'typings/entities/clients';
import { Response as UserResponse, User } from 'typings/entities/users';
import { ADMIN, USER } from 'constants/roles';

export const clients = ({ data }: { data: ClientResponse }): ClientResponse => data;

export const client = ({ data }: { data: Client }): Client => data;

export const users = ({ data }: { data: UserResponse }): UserResponse => {
  const newItems = data.items.map((item: User) => ({
    ...item,
    roles: item.roles[0] === 'ROLE_USER' ? USER : ADMIN,
  }));

  return {
    ...data,
    items: newItems,
  };
};

export const userById = ({ data }: { data: User }): User => {
  const role = data.roles[0] === 'ROLE_USER' ? USER : ADMIN;

  return {
    ...data,
    roles: role,
  };
};
