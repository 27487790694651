import { Select, MenuItem } from '@mui/material';
import { useTranslation } from 'react-i18next';

import * as S from '../../styled';

const configDate = ['dd-mm-yyyy', 'dd.mm.yyyy', 'dd.mm.y', 'mm-dd-yyyy', 'yyyy-dd-mm', 'yyyy-mm-dd'];

const FirstRule = ({ selectedDateFrom, handleDateFromChange, data }: any) => {
  const { t } = useTranslation('common');

  return (
    <S.ContentContainer>
      {t('etl.step4.convertDateFrom')}
      <Select
        onChange={handleDateFromChange}
        value={data?.selectedDateFrom || selectedDateFrom}
        size="small"
        sx={{
          m: 1,
        }}
      >
        {configDate.map(item => (
          <MenuItem key={item} value={item}>
            {item}
          </MenuItem>
        ))}
      </Select>
      {/* {t('etl.step4.to')} */}
      {/* <Select
        onChange={handleDateToChange}
        value={data?.selectedDateTo || selectedDateTo}
        size="small"
        sx={{
          m: 1,
        }}
      >
        {configDate.map(item => (
          <MenuItem key={item} value={item}>
            {item}
          </MenuItem>
        ))}
      </Select> */}
    </S.ContentContainer>
  );
};

export default FirstRule;
