import { string, object } from 'yup';

const UserDetailsSchema = t =>
  object().shape({
    name: string().trim().required(t('required')),
    email: string().trim().required(t('required')),
    country: string().trim().required(t('required')),
    city: string().trim().required(t('required')),
    street: string().trim().required(t('required')),
    zipcode: string().trim().required(t('required')),
    geodata: string(),
    status: string().required(t('required')),
  });

export default UserDetailsSchema;
