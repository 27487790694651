import { getRole } from 'services/localStorage';
import { SUPER_ADMIN } from 'constants/roles';
import User from './User';
import Admin from './Admin';

function EditProfile() {
  const role = getRole();
  const isSuperAdmin = role === SUPER_ADMIN;

  return isSuperAdmin ? <Admin /> : <User />;
}

export default EditProfile;
