import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAsyncFn } from 'react-use';
import { toast } from 'react-toastify';
import { Divider } from '@mui/material';
import * as Sentry from '@sentry/browser';

import debounce from 'lodash/debounce';
import { getVirusesAdmin, removeVirusAdmin, getVirusByIdAdmin, postVirusAdmin, putVirusAdmin } from 'http/viruses';
import { getError } from 'utils/error';
import Loader from 'components/Loader';
import convertingUndefinedToString from 'utils/convertingUndefinedToString';
import useConfirmationDialog from 'hooks/useConfirmationDialog';
import { VirusDetails, Virus } from 'typings/entities/viruses';
import SubHeader from 'components/SubHeader';
import VirusAdminForm from './VirusAdminForm';
import Table from '../VirusTable';
import * as S from './styled';

type Order = 'asc' | 'desc';

const AdminVirusesList = () => {
  const { t } = useTranslation('common');
  const [selectedVirusId, setSelectedVirusId] = useState<number | null>(null);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);
  const [search, setSearch] = useState<string>('');
  const [sortDirection, setSortDirection] = useState<Order>('asc');
  const [nameField, setNameField] = useState<string>('');
  const [virusId, setVirusId] = useState<number>();
  const delayedSetSearch = debounce((string: string) => setSearch(string), 500);

  // eslint-disable-next-line consistent-return
  const [virusesListState, getVirusesList] = useAsyncFn(async () => {
    try {
      const data = await getVirusesAdmin({
        count: rowsPerPage,
        page,
        search,
        sortDirection,
        sortByField: nameField,
      });

      return data;
    } catch (err: any) {
      Sentry.captureException(new Error(getError(err).error));
      toast.error(getError(err).error);
    }
  }, [page, rowsPerPage, search, sortDirection, nameField]);

  const [, removeVirusList] = useAsyncFn(async () => {
    try {
      await removeVirusAdmin(virusId);
      getVirusesList();

      toast.success(t('success'));
    } catch (err: any) {
      Sentry.captureException(new Error(getError(err).error));
      toast.error(getError(err).error);
    }
  }, [virusId]);

  const [virusState, getVirusData] = useAsyncFn(
    // eslint-disable-next-line consistent-return
    async (id: number) => {
      try {
        return await getVirusByIdAdmin(id);
      } catch (err: any) {
        Sentry.captureException(new Error(getError(err).error));
        toast.error(getError(err).error);
      }
    },
    [selectedVirusId],
  );

  const [, createVirus] = useAsyncFn(async (req: VirusDetails) => {
    try {
      const newReq: any = convertingUndefinedToString(req);

      await postVirusAdmin(newReq);
      setSelectedVirusId(null);
      getVirusesList();

      toast.success(t('success'));
    } catch (err: any) {
      Sentry.captureException(new Error(getError(err).error));
      toast.error(getError(err).error);
    }
  }, []);

  const [, updateVirus] = useAsyncFn(
    async (req: Virus) => {
      try {
        const newReq: any = convertingUndefinedToString(req);

        const data = {
          name: newReq.name,
          virusId: newReq.virusId,
          description: newReq.description,
        };

        await putVirusAdmin(data, req.id);
        setSelectedVirusId(null);
        getVirusesList();

        toast.success(t('success'));
      } catch (err: any) {
        Sentry.captureException(new Error(getError(err).error));
        toast.error(getError(err).error);
      }
    },
    [rowsPerPage, page, search, sortDirection],
  );

  useEffect(() => {
    getVirusesList();
  }, [getVirusesList, rowsPerPage, page, search, sortDirection, nameField]);

  const handleRemoveVirus = () => {
    removeVirusList();
    setSelectedVirusId(null);
  };

  const { Dialog, onOpen } = useConfirmationDialog({
    bodyText: t('confirmationDialog.bodyTextVirus'),
    confirmationButtonText: t('confirmationDialog.confirmationButtonText'),
    onConfirmClick: handleRemoveVirus,
  });

  const handleDeleteClick = (id: number) => {
    setVirusId(id);
    onOpen();
  };

  const handleSort = (name: string, sort: Order) => {
    setSortDirection(sort);
    setNameField(name);
  };

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    delayedSetSearch(event.target.value);
  };

  const handleSelectedVirus = (id: number) => {
    getVirusData(id);
    setSelectedVirusId(id);
  };

  const handleFormSubmit = (req: any) => {
    if (req.id) {
      updateVirus(req);
    } else {
      createVirus(req);
    }
  };

  const handleAddNewVirus = () => {
    setSelectedVirusId(-1);
  };

  const handleFormCancel = () => {
    setSelectedVirusId(null);
  };

  return (
    <S.PageContainer>
      {(virusesListState.loading || virusState.loading) && <Loader />}
      <Dialog />
      <SubHeader
        title={t('virusListPage.title')}
        onSearchChange={handleSearchChange}
        textButton={t('virusListPage.addVirusButton')}
        onFilterChange={() => {}}
        onClick={handleAddNewVirus}
        partialSubHeader
      />
      <S.PaperContainer elevation={3}>
        <S.ContentBox>
          {virusesListState.value && (
            <Table
              order={sortDirection}
              orderBy={nameField}
              virusesList={virusesListState.value.items}
              setRowsPerPage={setRowsPerPage}
              rowsPerPage={rowsPerPage}
              allRows={virusesListState.value.allCount}
              setPage={setPage}
              page={page}
              oneSorTablet={handleSort}
              onRemoveTableRow={handleDeleteClick}
              handleSelectedVirus={handleSelectedVirus}
            />
          )}
        </S.ContentBox>
        <Divider orientation="vertical" variant="middle" flexItem />
        <S.ContentBox>
          {selectedVirusId ? (
            <>
              <S.BlockTitle>{t('virusListPage.virusFormTitle')}</S.BlockTitle>
              <VirusAdminForm
                handleFormSubmit={handleFormSubmit}
                selectedVirusId={selectedVirusId}
                selectedVirus={virusState.value}
                handleFormCancel={handleFormCancel}
              />
            </>
          ) : (
            <S.EmptyField>
              {t('noRecords')}
              <br />
              {t('emptyField')}
            </S.EmptyField>
          )}
        </S.ContentBox>
      </S.PaperContainer>
    </S.PageContainer>
  );
};

export default AdminVirusesList;
