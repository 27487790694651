import { VirusDetails } from 'typings/entities/viruses';
import * as formatters from './formatters';
import { http } from '../index';

type Props = {
  count: number;
  page: number;
  search: string;
  sortDirection: string;
  sortByField: string;
};

export const getVirusesUser = (params?: Props) => {
  return http.get(`/api/viruses/`, { params }).then(formatters.viruses);
};

export const getAllViruses = () => {
  return http.get(`/api/viruses/all`).then(formatters.viruses);
};

export const getVirusByIdUser = (id: number | null) => {
  return http.get(`/api/viruses/${id}`).then(formatters.virus);
};

export const getVirusesAdmin = (params: Props) => {
  return http.get('/api/admin/viruses/', { params }).then(formatters.viruses);
};

export const removeVirusAdmin = (id: number | undefined) => {
  return http.delete(`/api/admin/viruses/${id}`);
};

export const getVirusByIdAdmin = (id: number | null) => {
  return http.get(`/api/admin/viruses/${id}`).then(formatters.virus);
};

export const postVirusAdmin = (req: VirusDetails) => {
  return http.post(`/api/admin/viruses/`, req);
};

export const putVirusAdmin = (req: VirusDetails, id: number | null) => {
  return http.put(`/api/admin/viruses/${id}`, req);
};
