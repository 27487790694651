import React, { useEffect, useRef, useState } from 'react';
import { useAsyncFn } from 'react-use';
import { toast } from 'react-toastify';
import debounce from 'lodash/debounce';
import { useTranslation } from 'react-i18next';
import { Paper } from '@mui/material';
import * as Sentry from '@sentry/browser';

import { getUsers, removeUser, postUsers, putUsers, getUserById } from 'http/clients';
import { ClientUserDetails, User, Filter } from 'typings/entities/users';
import useConfirmationDialog from 'hooks/useConfirmationDialog';
import useModalState from 'hooks/useModalState';
import Loader from 'components/Loader';
import { getError } from 'utils/error';
import convertingUndefinedToString from 'utils/convertingUndefinedToString';
import SubHeader from 'components/SubHeader';
import { USER } from 'constants/roles';
import Modal from './UserModal';
import UsersTable from './UserTable';
import UserForm from './UserForm';

import * as S from './styled';

type Order = 'asc' | 'desc';

const UsersForm = ({ selectedClientId }: { selectedClientId: number }) => {
  const { t } = useTranslation('common');
  const formRef = useRef(null);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);
  const [selectedUserId, setSelectedUserId] = useState<number | null>(null);
  const [search, setSearch] = useState<string>('');
  const [sortDirection, setSortDirection] = useState<Order>('asc');
  const [nameField, setNameField] = useState<string>('');
  const [userId, setUserId] = useState<number>();
  const [filter, setFilter] = useState({});
  const delayedSetSearch = debounce((string: string) => setSearch(string), 500);
  const { isOpen, onToggle } = useModalState();
  const scrollToRef = ref => window.scrollTo(0, ref.current.offsetTop);
  const executeScroll = () => setTimeout(() => scrollToRef(formRef), 500);

  // eslint-disable-next-line consistent-return
  const [usersListState, getUsersList] = useAsyncFn(async () => {
    try {
      const data = await getUsers({
        count: rowsPerPage,
        page,
        search,
        sortDirection,
        sortByField: nameField,
        client: selectedClientId,
        ...filter,
      });

      return data;
    } catch (err: any) {
      Sentry.captureException(new Error(getError(err).error));
      toast.error(getError(err).error);
    }
  }, [page, rowsPerPage, search, sortDirection, nameField, filter, selectedClientId]);

  const [userState, getUserData] = useAsyncFn(
    // eslint-disable-next-line consistent-return
    async (id: number) => {
      try {
        const res = await getUserById(id);
        executeScroll();
        return res;
      } catch (err: any) {
        Sentry.captureException(new Error(getError(err).error));
        toast.error(getError(err).error);
      }
    },
    [],
  );

  const [, removeUserById] = useAsyncFn(async () => {
    try {
      await removeUser(userId);
      getUsersList();

      toast.success(t('success'));
    } catch (err: any) {
      Sentry.captureException(new Error(getError(err).error));
      toast.error(getError(err).error);
    }
  }, [userId]);

  const [, createUser] = useAsyncFn(
    async (req: ClientUserDetails) => {
      try {
        const newReq: any = convertingUndefinedToString(req);

        const data = {
          firstname: newReq.firstname,
          lastname: newReq.lastname,
          phone: newReq.phone,
          email: newReq.email,
          client: selectedClientId,
          status: newReq.status,
          role: newReq.role === USER ? 'ROLE_USER' : 'ROLE_ADMIN',
          plainPassword: newReq.plainPassword,
        };

        await postUsers(data);
        getUsersList();
        setSelectedUserId(null);

        toast.success(t('success'));
      } catch (err: any) {
        Sentry.captureException(new Error(getError(err).error));
        toast.error(getError(err).error);
      }
    },
    [selectedClientId],
  );

  const [, updateUser] = useAsyncFn(
    async (req: User) => {
      try {
        const newReq: any = convertingUndefinedToString(req);

        const data = {
          firstname: newReq.firstname,
          lastname: newReq.lastname,
          phone: newReq.phone,
          email: newReq.email,
          client: newReq.client.id,
          status: newReq.status,
          role: newReq.role === USER ? 'ROLE_USER' : 'ROLE_ADMIN',
          plainPassword: newReq.plainPassword,
        };

        if (newReq.plainPassword.first === undefined) {
          delete data.plainPassword;
        }

        await putUsers(data, req.id);
        getUsersList();
        setSelectedUserId(null);

        toast.success(t('success'));
      } catch (err: any) {
        Sentry.captureException(new Error(getError(err).error));
        toast.error(getError(err).error);
      }
    },
    [selectedClientId, rowsPerPage, page, search, sortDirection],
  );

  useEffect(() => {
    getUsersList();
  }, [getUsersList, rowsPerPage, page, search, sortDirection, nameField]);

  useEffect(() => {
    setSelectedUserId(null);
  }, [selectedClientId]);

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    delayedSetSearch(event.target.value);
  };

  const handleRemoveUser = () => {
    removeUserById();
    setSelectedUserId(null);
  };

  const { Dialog, onOpen } = useConfirmationDialog({
    bodyText: t('confirmationDialog.bodyText'),
    confirmationButtonText: t('confirmationDialog.confirmationButtonText'),
    onConfirmClick: handleRemoveUser,
  });

  const handleSort = (name: string, sort: Order) => {
    setSortDirection(sort);
    setNameField(name);
  };

  const handleDeleteClick = (id: number) => {
    setUserId(id);
    onOpen();
  };

  const handleFormSubmit = (req: any) => {
    if (req.id) {
      updateUser(req);
    } else {
      createUser(req);
    }
  };

  const handleOpenModal = () => {
    onToggle();
  };

  const handleFilterChange = (props: Filter) => {
    setFilter(props);
    onToggle();
  };

  const handleResetFilter = () => {
    setFilter({});
    onToggle();
  };

  const handleSelectedUser = (id: number) => {
    getUserData(id);
    setSelectedUserId(id);
  };

  const handleAddNewUser = () => {
    executeScroll();
    setSelectedUserId(-1);
  };

  const handleFormCancel = () => {
    setSelectedUserId(null);
  };

  return (
    <S.MainContainer>
      {(usersListState.loading || userState.loading) && <Loader />}
      <Dialog />
      <Modal
        open={isOpen}
        close={onToggle}
        handleFilterChange={handleFilterChange}
        handleResetFilter={handleResetFilter}
      />
      <SubHeader
        title={t('clientListPage.labUsers')}
        subTitle
        textButton={t('clientListPage.addUsers')}
        onClick={handleAddNewUser}
        onSearchChange={handleSearchChange}
        onFilterChange={handleOpenModal}
      />
      <Paper>
        {usersListState.value && (
          <UsersTable
            order={sortDirection}
            orderBy={nameField}
            usersList={usersListState.value.items}
            setRowsPerPage={setRowsPerPage}
            rowsPerPage={rowsPerPage}
            allRows={usersListState.value.allCount}
            setPage={setPage}
            page={page}
            onRemoveTableRow={handleDeleteClick}
            oneSorTablet={handleSort}
            handleSelectedUser={handleSelectedUser}
          />
        )}
      </Paper>
      <div ref={formRef}>
        {selectedUserId && usersListState?.value?.items && (
          <S.FormWrapper>
            <UserForm
              handleFormSubmit={handleFormSubmit}
              selectedUserId={selectedUserId}
              selectedUser={userState.value}
              handleFormCancel={handleFormCancel}
            />
          </S.FormWrapper>
        )}
      </div>
    </S.MainContainer>
  );
};

export default UsersForm;
