import styled from 'styled-components';
import { Typography, DialogTitle } from '@mui/material';

export const Wrapper = styled.div`
  margin-bottom: 20px;
  margin-right: 20px;
`;

export const ContentRadioLabel = styled(Typography)`
  width: 300px;
  margin: -10px 0;
  color: rgba(0, 0, 0, 0.6);
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
  font-weight: 400;
  font-size: 13px;
  line-height: 1.4375em;
`;

export const RadioContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 5px;
`;

export const Error = styled.div`
  color: ${({ theme }) => theme.colors.red};
  text-align: left;
  margin-left: 20px;
`;

export const ModalTitle = styled(DialogTitle)`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const ContextContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

export const ContextText = styled(Typography)`
  margin-top: 20px;
  margin-left: 20px;
  width: 220px;
`;

export const ContextPicker = styled.div`
  width: 300px;
`;

export const ButtonWrapper = styled.div`
  margin-right: 10px;
  margin-left: 10px;
  margin-bottom: 10px;
`;

export const ContextRadio = styled(Typography)`
  margin-top: 10px;
  width: 300px;
`;

export const ButtonContainer = styled.div`
  position: absolute;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 50px;
`;

export const ButtonFilterContainer = styled.div`
  display: flex;
  align-items: center;
  margin-right: 10px;
  justify-content: space-between;
  margin-top: 50px;
`;
