import { combineReducers } from 'redux';

import auth from './auth';
import patients from './patients';
import users from './users';
import clients from './clients';
import etl from './etl';

const rootReducer = combineReducers({
  auth,
  patients,
  users,
  clients,
  etl,
});

export type RootState = ReturnType<typeof rootReducer>;

declare module 'react-redux' {
  export interface DefaultRootState extends RootState {}
}

export default rootReducer;
