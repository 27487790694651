import { useTranslation } from 'react-i18next';
import Table from 'components/Table';
import { Virus } from 'typings/entities/viruses';
import React from 'react';
import { getRole } from 'services/localStorage';
import { SUPER_ADMIN } from 'constants/roles';

type Props = {
  virusesList: Virus[];
  setRowsPerPage: (value: number) => void;
  rowsPerPage: number;
  setPage: (value: number) => void;
  oneSorTablet: (id: string, sort: Order) => void;
  handleSelectedVirus: (id: number) => void;
  page: number;
  onRemoveTableRow: (value: number) => void;
  allRows: number;
  order: Order;
  orderBy: string;
};

type Order = 'asc' | 'desc';

const VirusTable = ({
  virusesList,
  setRowsPerPage,
  rowsPerPage,
  setPage,
  allRows,
  order,
  page,
  orderBy,
  oneSorTablet,
  onRemoveTableRow,
  handleSelectedVirus,
}: Props) => {
  const { t } = useTranslation('common');
  const isAdmin = getRole() === SUPER_ADMIN;

  const tableHead = [
    { field: 'virusId', headerName: t('virusListPage.virusId') },
    { field: 'name', headerName: t('virusListPage.name') },
  ];

  return (
    <Table<Virus>
      onSetSelectedRow={handleSelectedVirus}
      tableHead={tableHead}
      tableBody={virusesList}
      setRowsPerPage={setRowsPerPage}
      rowsPerPage={rowsPerPage}
      setPage={setPage}
      page={page}
      order={order}
      orderBy={orderBy}
      allRows={allRows}
      isShowRemove={isAdmin}
      isShowEdit={false}
      onRemoveTableRow={onRemoveTableRow}
      oneSorTablet={oneSorTablet}
    />
  );
};

export default VirusTable;
