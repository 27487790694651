import { useTranslation } from 'react-i18next';

import Form from './Form';
import { Password } from './Form/validationSchema';

import * as S from '../styled';

type Props = {
  reset: (params: Password) => void;
};

const FormFilling = ({ reset }: Props) => {
  const { t } = useTranslation('common');

  return (
    <>
      <S.Title>{t('resetPassword.title')}</S.Title>
      <S.SubTitle>{t('resetPassword.enterPassword')}</S.SubTitle>
      <Form onSubmit={reset} />
    </>
  );
};

export default FormFilling;
