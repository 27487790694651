import { string, object } from 'yup';

const VirusSchema = t =>
  object().shape({
    name: string().trim().required(t('requiredName')),
    virusId: string().trim().required(t('requiredId')),
    description: string(),
  });

export default VirusSchema;
