import * as yup from 'yup';
import { dateExp, emptyExp } from 'configs/password';
import { isSameOrAfter, isSameOrAfterCurrent, isSameOrBefore, isSameOrBeforeCurrent } from 'utils/dateChecking';

const FilterSchema = t => {
  return yup.object().shape({
    dateOfBirthFrom: yup.lazy(data => {
      if (data === 'Invalid date') {
        return yup.string().matches(emptyExp, t('wrongEmailFormat')).nullable();
      }
      return yup
        .string()
        .matches(dateExp, { message: t('wrongEmailFormat'), excludeEmptyString: false })
        .nullable()
        .test('maxFrom', t('maxDateFrom'), function (value) {
          const { registrationTo } = this.parent;
          return isSameOrBefore(value, registrationTo);
        })
        .test('maxCurrent', t('maxCurrentDate'), function (value) {
          return isSameOrBeforeCurrent(value);
        })
        .test('minCurrent', t('minCurrentDate'), function (value) {
          return isSameOrAfterCurrent(value);
        });
    }),
    dateOfBirthTo: yup.lazy(data => {
      if (data === 'Invalid date') {
        return yup.string().matches(emptyExp, t('wrongEmailFormat')).nullable();
      }
      return yup
        .string()
        .matches(dateExp, { message: t('wrongEmailFormat'), excludeEmptyString: true })
        .nullable()
        .test('minTo', t('minDateTo'), function (value) {
          const { registrationFrom } = this.parent;
          return isSameOrAfter(value, registrationFrom);
        })
        .test('maxTo', t('maxCurrentDate'), function (value) {
          return isSameOrBeforeCurrent(value);
        })
        .test('minCurrent', t('minCurrentDate'), function (value) {
          return isSameOrAfterCurrent(value);
        });
    }),
  });
};
export default FilterSchema;
