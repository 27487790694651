import { http } from '../index';
import * as formatters from './formatters';

export const getFile = id => {
  return http.get(`/api/files/${id}`).then(formatters.files);
};

export const postFile = req => {
  return http
    .post(`/api/files/`, req, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
    .then(formatters.files);
};

export const downloadFile = (id: number) => {
  return http.get(`/api/files/${id}/download`);
};

export const deleteFile = (id: number) => {
  return http.delete(`/api/files/${id}`);
};
