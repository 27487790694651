import React, { useMemo, useState } from 'react';
import ClearOutlinedIcon from '@mui/icons-material/ClearOutlined';
import DoneOutlinedIcon from '@mui/icons-material/DoneOutlined';
import RestartAltOutlinedIcon from '@mui/icons-material/RestartAltOutlined';
import Button from 'components/Button';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { yupResolver } from '@hookform/resolvers/yup';
import { ErrorMessage } from '@hookform/error-message';
import AdapterMoment from '@mui/lab/AdapterMoment';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DatePicker from '@mui/lab/DatePicker';
import { Controller, useForm } from 'react-hook-form';
import { Dialog, DialogContent, RadioGroup, TextField, Radio, FormControlLabel, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { Filter } from 'typings/entities/patients';
import { theme } from 'theme';

import validationSchema from './validationSchema';
import * as S from './styled';

type Props = {
  open: boolean;
  close: () => void;
  handleFilterChange: (props: Filter) => void;
  handleResetFilter: () => void;
};

const Modal = ({ open, close, handleFilterChange, handleResetFilter }: Props) => {
  const { t } = useTranslation('common');
  const schema = useMemo(() => validationSchema(t), [t]);
  const [dateFrom, setDateFrom] = useState<moment.Moment | string | null>(null);
  const [dateTo, setDateTo] = useState<moment.Moment | string | null>(moment());

  const defaultValues = {
    dateOfBirthTo: null,
    dateOfBirthFrom: null,
    gender: '',
  };

  const {
    handleSubmit,
    control,
    reset,
    formState: { isSubmitting, errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues,
  });

  const onReset = () => {
    setDateFrom(null);
    setDateTo(moment());
    reset();
    handleResetFilter();
  };

  const handleSubmitFilter = (filterProps: Filter) => {
    handleFilterChange({
      ...filterProps,
      dateOfBirthFrom: moment(filterProps.dateOfBirthFrom).format('YYYY-MM-DD'),
      dateOfBirthTo: moment(filterProps.dateOfBirthTo).format('YYYY-MM-DD'),
    });
  };

  return (
    <Dialog onClose={close} open={open} fullWidth maxWidth="sm">
      <S.ModalTitle sx={{ m: 1 }}>
        {t('filter')}
        <IconButton onClick={close}>
          <CloseIcon />
        </IconButton>
      </S.ModalTitle>
      <DialogContent>
        <form onSubmit={handleSubmit(handleSubmitFilter)}>
          <S.Wrapper />
          <S.Wrapper>
            <S.ContextContainer>
              <S.ContextText>{t('patientsPage.dateOfBirthFrom')}</S.ContextText>
              <S.ContextPicker>
                <Controller
                  name="dateOfBirthFrom"
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <LocalizationProvider dateAdapter={AdapterMoment}>
                      <DatePicker
                        value={value || null}
                        maxDate={moment(dateTo)}
                        onChange={e => {
                          setDateFrom(e ? moment(e).format('MM/DD/YYYY') : null);
                          onChange(e ? moment(e).format('MM/DD/YYYY') : null);
                        }}
                        renderInput={params => {
                          return <TextField {...params} error={!!errors.dateOfBirthFrom} />;
                        }}
                      />
                    </LocalizationProvider>
                  )}
                />
                <S.Error>
                  <ErrorMessage name="registrationFrom" errors={errors} />
                </S.Error>
              </S.ContextPicker>
            </S.ContextContainer>
          </S.Wrapper>
          <S.Wrapper>
            <S.ContextContainer>
              <S.ContextText>{t('patientsPage.dateOfBirthTo')}</S.ContextText>
              <S.ContextPicker>
                <Controller
                  name="dateOfBirthTo"
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <LocalizationProvider dateAdapter={AdapterMoment}>
                      <DatePicker
                        value={value || null}
                        minDate={moment(dateFrom)}
                        maxDate={moment()}
                        onChange={e => {
                          setDateTo(e ? moment(e).format('MM/DD/YYYY') : null);
                          onChange(e ? moment(e).format('MM/DD/YYYY') : null);
                        }}
                        renderInput={params => {
                          return <TextField {...params} error={!!errors.dateOfBirthTo} />;
                        }}
                      />
                    </LocalizationProvider>
                  )}
                />
                <S.Error>
                  <ErrorMessage name="registrationTo" errors={errors} />
                </S.Error>
              </S.ContextPicker>
            </S.ContextContainer>
          </S.Wrapper>
          <S.Wrapper>
            <S.ContextContainer>
              <S.ContextText>{t('gender')}</S.ContextText>
              <S.ContextRadio>
                <Controller
                  name="gender"
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <RadioGroup aria-label={t('gender')} value={value} onChange={onChange}>
                      <S.RadioContainer>
                        <FormControlLabel value="1" control={<Radio size="small" />} label={t('male')} />
                        <FormControlLabel value="2" control={<Radio size="small" />} label={t('female')} />
                      </S.RadioContainer>
                    </RadioGroup>
                  )}
                />
              </S.ContextRadio>
            </S.ContextContainer>
          </S.Wrapper>
          <S.ButtonFilterContainer>
            <S.ButtonWrapper>
              <Button
                textButton={t('saveButton')}
                buttonProps={{
                  bgColor: theme.colors.primary,
                  hoverColor: theme.colors.primaryShadow,
                  variant: 'contained',
                  type: 'submit',
                  disabled: isSubmitting,
                  startIcon: <DoneOutlinedIcon />,
                }}
              />
            </S.ButtonWrapper>
            <S.ButtonWrapper>
              <Button
                textButton={t('userListPage.resetButton')}
                buttonProps={{
                  bgColor: theme.colors.red,
                  hoverColor: theme.colors.redShadow,
                  variant: 'outlined',
                  onClick: onReset,
                  disabled: isSubmitting,
                  startIcon: <RestartAltOutlinedIcon />,
                }}
              />
            </S.ButtonWrapper>
            <S.ButtonWrapper>
              <Button
                textButton={t('cancelButton')}
                buttonProps={{
                  bgColor: theme.colors.grey,
                  hoverColor: theme.colors.greyShadow,
                  variant: 'outlined',
                  onClick: close,
                  disabled: isSubmitting,
                  startIcon: <ClearOutlinedIcon />,
                }}
              />
            </S.ButtonWrapper>
          </S.ButtonFilterContainer>
        </form>
      </DialogContent>
    </Dialog>
  );
};

export default Modal;
