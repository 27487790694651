import styled from 'styled-components';
import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';

export const ProfileButton = styled(Button)`
  && {
    transition: all 0.2s ease-in-out 0s;

    :hover {
      background: rgb(33, 150, 243);
      color: rgb(227, 242, 253);
    }

    border-radius: 27px;
    height: 30px;
    color: rgb(33, 150, 243);
    background-color: rgb(227, 242, 253);
  }
`;

export const Name = styled(MenuItem)`
  && {
    color: rgb(33, 150, 243);
    opacity: 1 !important;
  }
`;

export const Title = styled(MenuItem)`
  padding: 0 16px;
  font-size: 0.8rem;
`;
