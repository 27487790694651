import React, { useMemo } from 'react';
import { useAsyncFn } from 'react-use';
import { toast } from 'react-toastify';
import { IconButton, InputAdornment, TextField } from '@mui/material';
import DoneOutlinedIcon from '@mui/icons-material/DoneOutlined';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { Controller, useForm } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';
import { yupResolver } from '@hookform/resolvers/yup';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import * as Sentry from '@sentry/browser';

import { authSelector } from 'redux/auth/selectors';
import convertingUndefinedToString from 'utils/convertingUndefinedToString';
import { getError } from 'utils/error';
import { ProfilePassword } from 'typings/entities/profile';
import { putUserProfilePassword, putAdminProfilePassword } from 'http/profile';
import Button from 'components/Button';
import { theme } from 'theme';

import validationSchema from './validationSchema';
import * as S from '../../styled';

type InitialState = {
  currentPassword: boolean;
  newPassword: boolean;
  repeatNewPassword: boolean;
};

const PasswordForm = () => {
  const initialState = {
    currentPassword: false,
    newPassword: false,
    repeatNewPassword: false,
  };
  const { t } = useTranslation('common');
  const schema = useMemo(() => validationSchema(t), [t]);
  const { isSuperAdmin } = useSelector(authSelector);
  const [visibility, setVisibility] = React.useState<InitialState>(initialState);

  const [, updateUserProfilePassword] = useAsyncFn(async (req: ProfilePassword) => {
    try {
      const newReq: any = convertingUndefinedToString(req);

      const data = {
        currentPassword: newReq.currentPassword,
        plainPassword: newReq.plainPassword,
      };

      await putUserProfilePassword(data);
    } catch (err: any) {
      Sentry.captureException(new Error(getError(err).error));
      toast.error(getError(err).error);
    }
  }, []);

  const [, updateAdminProfilePassword] = useAsyncFn(async (req: ProfilePassword) => {
    try {
      const newReq: any = convertingUndefinedToString(req);

      const data = {
        currentPassword: newReq.currentPassword,
        plainPassword: newReq.plainPassword,
      };

      await putAdminProfilePassword(data);
    } catch (err: any) {
      Sentry.captureException(new Error(getError(err).error));
      toast.error(getError(err).error);
    }
  }, []);

  const {
    handleSubmit,
    control,
    reset,
    formState: { isSubmitting, errors },
  } = useForm<ProfilePassword>({
    resolver: yupResolver(schema),
  });

  const handleEditPassword = (req: any) => {
    if (isSuperAdmin) {
      updateAdminProfilePassword(req);
    } else {
      updateUserProfilePassword(req);
    }
    reset({ currentPassword: '', plainPassword: { first: '', second: '' } });
  };

  return (
    <form onSubmit={handleSubmit(handleEditPassword)}>
      <S.Container>
        <S.Wrapper>
          <S.Text>
            <Controller
              name="currentPassword"
              control={control}
              render={({ field: { onChange, value } }) => (
                <S.PasswordTextField
                  id="current-password"
                  type={visibility.currentPassword ? 'text' : 'password'}
                  value={value}
                  onChange={onChange}
                  disabled={isSubmitting}
                  label={t('profilePage.currentPassword')}
                  size="small"
                  error={!!errors.currentPassword}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          onClick={() => {
                            setVisibility({
                              currentPassword: !visibility.currentPassword,
                              newPassword: visibility.newPassword,
                              repeatNewPassword: visibility.repeatNewPassword,
                            });
                          }}
                          edge="end"
                        >
                          {visibility.currentPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                  fullWidth
                />
              )}
            />
            <S.Error>
              <ErrorMessage name="currentPassword" errors={errors} />
            </S.Error>
          </S.Text>
        </S.Wrapper>
        <S.Wrapper>
          <S.Text>
            <Controller
              name="plainPassword.first"
              control={control}
              render={({ field: { onChange, value } }) => (
                <S.PasswordTextField
                  id="first"
                  type={visibility.newPassword ? 'text' : 'password'}
                  value={value}
                  onChange={onChange}
                  disabled={isSubmitting}
                  label={t('profilePage.newPassword')}
                  size="small"
                  error={!!errors.plainPassword?.first}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          onClick={() => {
                            setVisibility({
                              currentPassword: visibility.currentPassword,
                              newPassword: !visibility.newPassword,
                              repeatNewPassword: visibility.repeatNewPassword,
                            });
                          }}
                          edge="end"
                        >
                          {visibility.newPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                  fullWidth
                />
              )}
            />
            <S.Error>
              <ErrorMessage name="plainPassword.first" errors={errors} />
            </S.Error>
          </S.Text>
        </S.Wrapper>
        <S.Wrapper>
          <S.Text>
            <Controller
              name="plainPassword.second"
              control={control}
              render={({ field: { onChange, value } }) => (
                <S.PasswordTextField
                  id="second"
                  type={visibility.repeatNewPassword ? 'text' : 'password'}
                  value={value}
                  onChange={onChange}
                  label={t('profilePage.repeatNewPassword')}
                  disabled={isSubmitting}
                  size="small"
                  error={!!errors.plainPassword?.second}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          onClick={() => {
                            setVisibility({
                              currentPassword: visibility.currentPassword,
                              newPassword: visibility.newPassword,
                              repeatNewPassword: !visibility.repeatNewPassword,
                            });
                          }}
                          edge="end"
                        >
                          {visibility.repeatNewPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                  fullWidth
                />
              )}
            />
            <S.Error>
              <ErrorMessage name="plainPassword.second" errors={errors} />
            </S.Error>
          </S.Text>
        </S.Wrapper>
      </S.Container>
      <S.Container>
        <S.ContentWrapper>
          <Button
            textButton={t('profilePage.updateButton')}
            buttonProps={{
              bgColor: theme.colors.primary,
              hoverColor: theme.colors.primaryShadow,
              variant: 'contained',
              disabled: isSubmitting,
              type: 'submit',
              startIcon: <DoneOutlinedIcon />,
            }}
          />
        </S.ContentWrapper>
      </S.Container>
    </form>
  );
};

export default PasswordForm;
