import styled from 'styled-components';
import { DialogTitle, FormControlLabel, Paper, Typography, Icon, TextField } from '@mui/material';

type ContentContainerProps = {
  isRadio?: boolean;
};

export const Container = styled.div`
  display: flex;
  flex-direction: row;
`;

export const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-right: 10px;
  margin-top: 50px;
`;

export const ContentWrapper = styled(Container)<ContentContainerProps>`
  flex-direction: column;
  width: 100%;
  width: ${({ isRadio }) => (isRadio ? '300px' : '200px')};
  margin: 0 0 20px 20px;
`;

export const ContentContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-left: 10px;
`;

export const Wrapper = styled(Container)`
  width: ${({ largeSize }: { largeSize?: boolean }) => (largeSize ? '350px' : '200px')};
`;

export const RadioLabel = styled(Typography)`
  width: 75px;
  margin: auto 20px;
`;

export const ContentRadioLabel = styled(Typography)`
  width: 300px;
  margin: -10px 0;
  color: rgba(0, 0, 0, 0.6);
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
  font-weight: 400;
  font-size: 13px;
  line-height: 1.4375em;
  word-wrap: break-word;
`;

export const RadioContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 5px;
`;

export const Error = styled.div`
  color: ${({ theme }) => theme.colors.red};
  text-align: left;
  margin-left: ${({ ml }: { ml?: boolean }) => (ml ? '20px' : '0')};
`;

export const RadioControlLabel = styled(FormControlLabel)`
  width: 120px;
`;

export const ModalTitle = styled(DialogTitle)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 10px;
`;

export const ButtonBox = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin: 30px;
`;

export const ButtonWrapper = styled.div`
  margin-right: 10px;
`;

export const PasswordTextField = styled(TextField)`
  .css-b52kj1::-ms-reveal,
  .css-19qh8xo-MuiInputBase-input-MuiOutlinedInput-input::-ms-reveal {
    display: none;
  }
`;
