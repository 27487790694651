import { string, object, ref } from 'yup';
import { emailExp, passwordExp, phoneExp } from 'configs/password';

function emptyStringToUndefined(value, originalValue) {
  if (typeof originalValue === 'string' && originalValue === '') {
    return undefined;
  }
  return value;
}

export const AddUserProfileSchema = t =>
  object().shape({
    firstname: string().trim().required(t('requiredName')),
    lastname: string().trim().required(t('requiredSurname')),
    phone: string().trim().matches(phoneExp, t('wrongPhoneFormat')).max(12, t('maxPhone')),
    email: string().trim().matches(emailExp, t('wrongEmailFormat')).required(t('requiredEmail')),
    status: string().required(t('requiredActiveStatus')),
    role: string().required(t('requiredActiveStatus')),
    plainPassword: object().shape({
      first: string()
        .min(8, t('minPassword'))
        .max(16, t('maxPassword'))
        .matches(passwordExp, t('weakPassword'))
        .required(t('requiredPassword')),
      second: string()
        .oneOf([ref('first')], t('matchPassword'))
        .required(t('required')),
    }),
  });

export const UpdateUserProfileSchema = t =>
  object().shape({
    firstname: string().trim().required(t('requiredName')),
    lastname: string().trim().required(t('requiredSurname')),
    phone: string().trim().matches(phoneExp, t('wrongPhoneFormat')).max(12, t('maxPhone')),
    email: string().trim().matches(emailExp, t('wrongEmailFormat')).required(t('requiredEmail')),
    status: string().required(t('requiredActiveStatus')),
    role: string().required(t('requiredActiveStatus')),
    plainPassword: object().shape({
      first: string()
        .oneOf([ref('second')], t('matchPassword'))
        .min(8, t('minPassword'))
        .max(16, t('maxPassword'))
        .transform(emptyStringToUndefined)
        .oneOf([ref('second')], t('matchPassword')),

      second: string()
        .oneOf([ref('first')], t('matchPassword'))
        .transform(emptyStringToUndefined),
    }),
  });
