import styled from 'styled-components';
import { DialogTitle, FormControlLabel, Typography } from '@mui/material';

type ContentContainerProps = {
  largeSize?: boolean;
  m?: boolean;
};

export const MainContainer = styled.div`
  padding: 0;
`;

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  width: 60%;
`;

export const ModalWrapper = styled(Container)`
  width: ${({ largeSize }: { largeSize?: boolean }) => (largeSize ? '350px' : '200px')};
`;

export const FormWrapper = styled.div`
  padding: 50px 0 0;
`;

export const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-right: 10px;
  margin-top: 50px;
`;

export const ModalTitle = styled(DialogTitle)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 10px;
`;

export const RadioLabel = styled(Typography)`
  width: 75px;
  margin: auto 20px;
`;

export const RadioContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 5px;
`;

export const RadioControlLabel = styled(FormControlLabel)`
  width: 120px;
`;

export const ContentWrapper = styled(Container)`
  margin-bottom: 20px;
  margin-left: 10px;
  flex-direction: column;
  width: ${({ isRadio }: { isRadio?: boolean }) => (isRadio ? '300px' : '200px')};

  .css-1u3bzj6-MuiFormControl-root-MuiTextField-root {
    height: 40px;
    width: 100%;
  }
`;

export const ContentContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
`;

export const Error = styled.div`
  color: ${({ theme }) => theme.colors.red};
  text-align: left;
  margin-left: ${({ ml }: { ml?: boolean }) => (ml ? '20px' : '0')};
`;

export const ContentRadioLabel = styled(Typography)`
  width: 300px;
  margin: -10px 0;
  color: rgba(0, 0, 0, 0.6);
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
  font-weight: 400;
  font-size: 13px;
  line-height: 1.4375em;
`;

export const ButtonWrapper = styled.div`
  margin-left: 10px;
  margin-bottom: 10px;
`;
