import React, { useState, useEffect } from 'react';
import { useAsyncFn } from 'react-use';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import i18n from 'i18next';
import * as Sentry from '@sentry/browser';

import { pageNumberSelector, allSettingsSelector } from 'redux/etl/selectors';
import { actions } from 'redux/etl';
import { postFile } from 'http/files';
import Loader from 'components/Loader';
import useConfirmationDialog from 'hooks/useConfirmationDialog';
import * as http from 'http/etl';
import { getError } from 'utils/error';
import EtlForm from './Form';

import * as S from './styled';

const St1 = () => {
  const { t } = useTranslation('common');
  const dispatch = useDispatch();
  const pageNumber = useSelector(pageNumberSelector);
  const allSettings = useSelector(allSettingsSelector);
  const [files, setFiles] = useState<any>({});
  const [selectedTemplateId, setSelectedTemplateId] = useState<number>();

  // eslint-disable-next-line consistent-return
  const [allSettingsState, getAllSettings] = useAsyncFn(async () => {
    try {
      const res = await http.getSettings();

      dispatch(actions.setAllSettings(res));
      return res;
    } catch (err: any) {
      Sentry.captureException(new Error(getError(err).error));
      toast.error(getError(err).error);
    }
  }, [i18n.language]);

  const [addFileState, addFile] = useAsyncFn(async req => {
    try {
      const bodyFormData = new FormData();
      bodyFormData.append('file', req);

      const res = await postFile(bodyFormData);
      setFiles(res);
    } catch (err: any) {
      Sentry.captureException(new Error(getError(err).error));
      toast.error(getError(err).error);
    }
  }, []);

  const [postSettingsState, postSettings] = useAsyncFn(
    async req => {
      try {
        const data = { fileUpload: { ...req, documentId: files.id } };

        const res = await http.postSettings(data);

        const selectedTemplate = allSettings.templates.find(item => Number(item.id) === Number(req.templateId));

        if (selectedTemplate) {
          dispatch(actions.setSelectedTemplate(selectedTemplate));
          dispatch(
            actions.setSettingsForPost({
              ...selectedTemplate.conditions,
              selectedTemplateType: allSettings.templateTypes.find(
                template => Number(template.id) === Number(selectedTemplate.fileType.id),
              ),
              ...data,
            }),
          );
        } else {
          dispatch(
            actions.setSettingsForPost({
              ...data,
              fileSettings: allSettings.fileSettings,
              mapping: allSettings.mapping,
              selectedTemplateType: allSettings.templateTypes.find(
                template => Number(template.id) === Number(req.importTemplateType),
              ),
            }),
          );
        }

        // dispatch(actions.setSettingsForPost(data));
        dispatch(actions.setPageNumber(pageNumber + 1));
      } catch (err: any) {
        Sentry.captureException(new Error(getError(err).error));
        toast.error(getError(err).error);
      }
    },
    [files, dispatch, actions, pageNumber],
  );

  const [templateRemoveState, templateRemove] = useAsyncFn(async () => {
    try {
      if (selectedTemplateId) {
        await http.templateDelete(selectedTemplateId);
        getAllSettings();
      }
    } catch (err: any) {
      Sentry.captureException(new Error(getError(err).error));
      toast.error(getError(err).error);
    }
  }, [selectedTemplateId]);

  const { Dialog, onOpen } = useConfirmationDialog({
    bodyText: t('confirmationDialog.bodyTextTemplates'),
    confirmationButtonText: t('confirmationDialog.confirmationButtonText'),
    onConfirmClick: templateRemove,
  });

  const handleDeleteClick = (id: number) => {
    setSelectedTemplateId(id);
    onOpen();
  };

  useEffect(() => {
    getAllSettings();
  }, [getAllSettings]);

  const handleFormSubmit = props => {
    postSettings(props);
  };

  const handleUploadClick = e => {
    addFile(e.target.files[0]);
  };

  return (
    <S.Container>
      {(addFileState.loading ||
        allSettingsState.loading ||
        postSettingsState.loading ||
        templateRemoveState.loading) && <Loader />}
      <Dialog />
      <S.Title>{t('etl.step1.title')}</S.Title>
      <S.PaperContainer elevation={3}>
        <EtlForm
          isDisabled={addFileState?.loading || postSettingsState?.loading || false}
          handleUploadClick={handleUploadClick}
          handleFormSubmit={handleFormSubmit}
          templateRemove={handleDeleteClick}
          allSettingsState={allSettingsState}
        />
      </S.PaperContainer>
    </S.Container>
  );
};

export default St1;
