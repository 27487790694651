import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAsyncFn } from 'react-use';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { Divider } from '@mui/material';
import * as Sentry from '@sentry/browser';

import debounce from 'lodash/debounce';
import { getUsers, removeUser, postUsers, getUserById, putUsers } from 'http/users';
import { actions } from 'redux/users';
import { getError } from 'utils/error';
import convertingUndefinedToString from 'utils/convertingUndefinedToString';
import useConfirmationDialog from 'hooks/useConfirmationDialog';
import useModalState from 'hooks/useModalState';

import { UserDetails, Filter, User } from 'typings/entities/users';
import SubHeader from 'components/SubHeader';
import Loader from 'components/Loader';
import Modal from './FilterModal';
import UserProfileForm from './UserProfileForm';
import Table from './Table';
import * as S from './styled';

type Order = 'asc' | 'desc';

const UserList = () => {
  const { t } = useTranslation('common');
  const dispatch = useDispatch();
  const [selectedUserId, setSelectedUserId] = useState<number | null>(null);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);
  const [search, setSearch] = useState<string>('');
  const [sortDirection, setSortDirection] = useState<Order>('asc');
  const [nameField, setNameField] = useState<string>('');
  const [userId, setUserId] = useState<number>();
  const [filter, setFilter] = useState({});
  const delayedSetSearch = debounce((string: string) => setSearch(string), 500);
  const { isOpen, onToggle } = useModalState();

  // eslint-disable-next-line consistent-return
  const [usersListState, getUsersList] = useAsyncFn(async () => {
    try {
      const data = await getUsers({
        count: rowsPerPage,
        page,
        search,
        sortDirection,
        sortByField: nameField,
        ...filter,
      });
      dispatch(actions.setUsersList(data));

      return data;
    } catch (err: any) {
      Sentry.captureException(new Error(getError(err).error));
      toast.error(getError(err).error);
    }
  }, [page, rowsPerPage, search, sortDirection, nameField, filter]);

  const [, removeUsersList] = useAsyncFn(async () => {
    try {
      await removeUser(userId);
      getUsersList();

      toast.success(t('success'));
    } catch (err: any) {
      Sentry.captureException(new Error(getError(err).error));
      toast.error(getError(err).error);
    }
  }, [userId]);

  const [, createUser] = useAsyncFn(async (req: UserDetails) => {
    try {
      const newReq: any = convertingUndefinedToString(req);

      const data = {
        firstname: newReq.firstname,
        lastname: newReq.lastname,
        phone: newReq.phone,
        email: newReq.email,
        status: newReq.status,
        plainPassword: newReq.plainPassword,
      };

      await postUsers(data);
      setSelectedUserId(null);
      getUsersList();

      toast.success(t('success'));
    } catch (err: any) {
      Sentry.captureException(new Error(getError(err).error));
      toast.error(getError(err).error);
    }
  }, []);

  const [userState, getUserData] = useAsyncFn(
    // eslint-disable-next-line consistent-return
    async (id: number) => {
      try {
        return await getUserById(id);
      } catch (err: any) {
        Sentry.captureException(new Error(getError(err).error));
        toast.error(getError(err).error);
      }
    },
    [selectedUserId],
  );

  const [, updateUser] = useAsyncFn(
    async (req: User) => {
      try {
        const newReq: any = convertingUndefinedToString(req);

        const data = {
          firstname: newReq.firstname,
          lastname: newReq.lastname,
          phone: newReq.phone,
          email: newReq.email,
          status: newReq.status,
          plainPassword: newReq.plainPassword,
        };

        if (newReq.plainPassword.first === undefined) {
          delete data.plainPassword;
        }

        await putUsers(data, req.id);
        setSelectedUserId(null);
        getUsersList();

        toast.success(t('success'));
      } catch (err: any) {
        Sentry.captureException(new Error(getError(err).error));
        toast.error(getError(err).error);
      }
    },
    [rowsPerPage, page, search, sortDirection],
  );

  useEffect(() => {
    getUsersList();
  }, [getUsersList, rowsPerPage, page, search, sortDirection, nameField]);

  const handleRemoveUser = () => {
    removeUsersList();
    setSelectedUserId(null);
  };

  const { Dialog, onOpen } = useConfirmationDialog({
    bodyText: t('confirmationDialog.bodyText'),
    confirmationButtonText: t('confirmationDialog.confirmationButtonText'),
    onConfirmClick: handleRemoveUser,
  });

  const handleDeleteClick = (id: number) => {
    setUserId(id);
    onOpen();
  };

  const handleSort = (name: string, sort: Order) => {
    setSortDirection(sort);
    setNameField(name);
  };

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    delayedSetSearch(event.target.value);
  };

  const handleOpenModal = () => {
    onToggle();
  };

  const handleFilterChange = (props: Filter) => {
    setFilter(props);
    onToggle();
  };

  const handleResetFilter = () => {
    setFilter({});
    onToggle();
  };

  const handleSelectedUser = (id: number) => {
    getUserData(id);
    setSelectedUserId(id);
  };

  const handleFormSubmit = (req: any) => {
    if (req.id) {
      updateUser(req);
    } else {
      createUser(req);
    }
  };

  const handleAddNewUser = () => {
    setSelectedUserId(-1);
  };

  const handleFormCancel = () => {
    setSelectedUserId(null);
  };

  return (
    <S.PageContainer>
      {(usersListState.loading || userState.loading) && <Loader />}
      <Dialog />
      <Modal
        open={isOpen}
        close={onToggle}
        handleFilterChange={handleFilterChange}
        handleResetFilter={handleResetFilter}
      />
      <SubHeader
        title={t('userListPage.title')}
        textButton={t('userListPage.addClientButton')}
        onSearchChange={handleSearchChange}
        onFilterChange={handleOpenModal}
        onClick={handleAddNewUser}
      />
      <S.PaperContainer elevation={3}>
        <S.ContentBox>
          {usersListState.value && (
            <Table
              order={sortDirection}
              orderBy={nameField}
              usersList={usersListState.value.items}
              setRowsPerPage={setRowsPerPage}
              rowsPerPage={rowsPerPage}
              allRows={usersListState.value.allCount}
              setPage={setPage}
              page={page}
              onRemoveTableRow={handleDeleteClick}
              oneSorTablet={handleSort}
              handleSelectedUser={handleSelectedUser}
            />
          )}
        </S.ContentBox>
        <Divider orientation="vertical" variant="middle" flexItem />
        <S.ContentBox>
          {selectedUserId ? (
            <>
              <S.BlockTitle>{t('userListPage.userProfileTitle')}</S.BlockTitle>
              <UserProfileForm
                handleFormSubmit={handleFormSubmit}
                selectedUserId={selectedUserId}
                selectedUser={userState.value}
                handleFormCancel={handleFormCancel}
              />
            </>
          ) : (
            <S.EmptyField>
              {t('noRecords')}
              <br />
              {t('emptyField')}
            </S.EmptyField>
          )}
        </S.ContentBox>
      </S.PaperContainer>
    </S.PageContainer>
  );
};

export default UserList;
