import { http } from '../index';
import * as formatters from './formatters';

type Credentials = {
  username: string;
  password: string;
  _csrf_token: string | null;
  isAdmin: boolean;
};

type RestorePassword = {
  email: string;
  _token: string | undefined;
};

export const getToken = () => {
  return http.get('/api/get-token').then(formatters.token);
};

export const login = (credentials: Credentials) => {
  return http.post(`/api/login?isAdmin=${credentials.isAdmin}`, credentials).then(formatters.login);
};

export const loginAsClient = email => {
  return http.get(`/api/login?_switch_to_user=${email}`);
};

export const signOutAsClient = email => {
  return http.get(`/api/logout?_switch_to_user=${email}`);
};

export const restorePassword = (req: RestorePassword) => {
  return http.post('/api/reset-password', req);
};

export const getTokenRestorePassword = () => {
  return http.get('/api/reset-password/get-token').then(formatters.token);
};

export const resetPassword = (pass: any, token: string | string[] | null | undefined) => {
  return http.post(`/api/reset-password/reset/${token}`, pass);
};

export const signOut = () => {
  return http.get('/api/logout');
};
