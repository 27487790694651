import React, { FunctionComponent } from 'react';
import { useSelector } from 'react-redux';
import { Route, Redirect, RouteComponentProps } from 'react-router-dom';

import { authSelector } from 'redux/auth/selectors';
import { SUPER_ADMIN } from 'constants/roles';
import { getUser, getRole } from 'services/localStorage';
import { routes } from 'services/router';

type Props = {
  component: FunctionComponent<RouteComponentProps>;
  redirectTo?: string;
  path: string;
  exact?: boolean;
};

const defaultProps = {
  redirectTo: '',
  exact: false,
};

const PrivateRoute: React.FC<Props> = ({ component: Component, redirectTo = '', ...rest }: Props) => {
  const { isAuthenticated } = useSelector(authSelector);
  const isSuperAdmin = getRole() === SUPER_ADMIN;
  const isAuth = !!getUser();

  if (!isAuth && !isAuthenticated) {
    const path = isSuperAdmin ? routes.authModule.adminLogin : routes.authModule.login;

    return (
      <Redirect
        to={{
          pathname: redirectTo || path,
        }}
      />
    );
  }

  return <Route {...rest} render={(props: RouteComponentProps) => <Component {...props} />} />;
};

PrivateRoute.defaultProps = defaultProps;

export default PrivateRoute;
