import React, { useEffect, useState } from 'react';
import { useAsyncFn } from 'react-use';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useDispatch } from 'react-redux';
import debounce from 'lodash/debounce';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import * as Sentry from '@sentry/browser';

import { getPatients, getPatientById, removePatient, postPatient, putPatient } from 'http/patients';
import { routes } from 'services/router';
import { actions } from 'redux/patients';
import Loader from 'components/Loader';
import useConfirmationDialog from 'hooks/useConfirmationDialog';
import useModalState from 'hooks/useModalState';
import { getError } from 'utils/error';
import convertingUndefinedToString from 'utils/convertingUndefinedToString';
import SubHeader from 'components/SubHeader';
import { PatientDetails, Filter, Patient } from 'typings/entities/patients';
import PatientsTable from './PatientsTable';
import Modal from './FilterModal';
import Tabs from './Tabs';

import * as S from './styled';

type Order = 'asc' | 'desc';

const PatientsList = () => {
  const { t } = useTranslation('common');
  const dispatch = useDispatch();
  const history = useHistory();
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);
  const [selectedPatientId, setSelectedPatientId] = useState<number | null>(null);
  const [search, setSearch] = useState<string>('');
  const [sortDirection, setSortDirection] = useState<Order>('asc');
  const [nameField, setNameField] = useState<string>('');
  const [patientId, setPatientId] = useState<number>();
  const [filter, setFilter] = useState({});
  const delayedSetSearch = debounce((string: string) => setSearch(string), 500);
  const { isOpen, onToggle } = useModalState();
  const [selectedTab, setSelectedTab] = useState<string>('1');
  const [errorMessage, setErrorMessage] = useState<any>(undefined);

  // eslint-disable-next-line consistent-return
  const [patientsListState, getPatientsList] = useAsyncFn(async () => {
    try {
      const data = await getPatients({
        count: rowsPerPage,
        page,
        search,
        sortDirection,
        sortByField: nameField,
        ...filter,
      });
      dispatch(actions.setPatientsList(data));

      return data;
    } catch (err: any) {
      Sentry.captureException(new Error(getError(err).error));
      toast.error(getError(err).error);
    }
  }, [page, rowsPerPage, search, sortDirection, nameField, filter]);

  const [patientState, getPatientData] = useAsyncFn(
    // eslint-disable-next-line consistent-return
    async (id: number) => {
      try {
        return await getPatientById(id);
      } catch (err: any) {
        Sentry.captureException(new Error(getError(err).error));
        toast.error(getError(err).error);
      }
    },
    [selectedPatientId],
  );

  const [, removePatientList] = useAsyncFn(async () => {
    try {
      await removePatient(patientId);
      setSelectedPatientId(null);
      setPatientId(undefined);
      getPatientsList();

      toast.success(t('success'));
    } catch (err: any) {
      Sentry.captureException(new Error(getError(err).error));
      toast.error(getError(err).error);
    }
  }, [patientId]);

  // eslint-disable-next-line consistent-return
  const [, createPatient] = useAsyncFn(async (req: PatientDetails) => {
    try {
      const newReq: any = convertingUndefinedToString(req);
      const dateOfBirth = req.dateOfBirth ? moment(req.dateOfBirth).format('Y-M-D') : newReq.dateOfBirth;

      await postPatient({ ...newReq, dateOfBirth });
      getPatientsList();
      setSelectedPatientId(null);

      toast.success(t('success'));
    } catch (err: any) {
      Sentry.captureException(new Error(getError(err).error));
      const errors = getError(err);

      toast.error(errors.error);
      setErrorMessage(errors.errorsForm);
    }
  }, []);

  useEffect(() => {
    getPatientsList();
  }, [getPatientsList, rowsPerPage, page, search, sortDirection, nameField]);

  useEffect(() => {
    if (selectedPatientId === -1) {
      setSelectedTab('1');
    }
  }, [selectedPatientId, setSelectedTab]);

  // eslint-disable-next-line consistent-return
  const [, updatePatient] = useAsyncFn(
    async (req: Patient) => {
      try {
        const newReq: any = convertingUndefinedToString(req);
        const dateOfBirth = req.dateOfBirth ? moment(req.dateOfBirth).format('Y-M-D') : newReq.dateOfBirth;
        delete newReq.client;
        delete newReq.createdAt;
        delete newReq.id;

        await putPatient({ ...newReq, dateOfBirth }, req.id);
        getPatientsList();
        setSelectedPatientId(null);

        toast.success(t('success'));
      } catch (err: any) {
        Sentry.captureException(new Error(getError(err).error));
        const errors = getError(err);

        toast.error(errors.error);
        setErrorMessage(errors.errorsForm);
      }
    },
    [rowsPerPage, page, search, sortDirection],
  );

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    delayedSetSearch(event.target.value);
  };

  const handleRemovePatient = () => {
    removePatientList();
    setSelectedPatientId(null);
  };

  const { Dialog, onOpen } = useConfirmationDialog({
    bodyText: t('confirmationDialog.bodyTextPatient'),
    confirmationButtonText: t('confirmationDialog.confirmationButtonText'),
    onConfirmClick: handleRemovePatient,
  });

  const handleSort = (name: string, sort: Order) => {
    setSortDirection(sort);
    setNameField(name);
  };

  const handleDeleteClick = (id: number) => {
    setPatientId(id);
    onOpen();
  };

  const handleFormSubmit = req => {
    if (req.id) {
      updatePatient(req);
    } else {
      createPatient(req);
    }
  };

  const handleOpenModal = () => {
    onToggle();
  };

  const handleFilterChange = (props: Filter) => {
    setFilter(props);
    onToggle();
  };

  const handleResetFilter = () => {
    setFilter({});
    onToggle();
  };

  const handleSelectedPatient = (id: number) => {
    getPatientData(id);
    setSelectedPatientId(id);
  };

  const handleTabChange = (event: React.SyntheticEvent, newValue: string) => {
    setSelectedTab(newValue);
  };

  const handleAddNewPatient = () => {
    setSelectedPatientId(-1);
  };

  const handleFormCancel = () => {
    setSelectedPatientId(null);
  };

  const handleImport = () => {
    history.push(routes.dashboardModule.etl);
  };

  return (
    <S.Container>
      {(patientsListState.loading || patientState.loading) && <Loader />}
      <Dialog />
      <Modal
        open={isOpen}
        close={onToggle}
        handleFilterChange={handleFilterChange}
        handleResetFilter={handleResetFilter}
      />
      <SubHeader
        title={t('patientsPage.title')}
        textButton={t('patientsPage.addPatient')}
        onClick={handleAddNewPatient}
        onSearchChange={handleSearchChange}
        onFilterChange={handleOpenModal}
        importButton
        onImportClick={handleImport}
      />
      <S.PaperContainer elevation={3}>
        <S.ContentBox>
          {patientsListState.value && (
            <PatientsTable
              order={sortDirection}
              orderBy={nameField}
              patientsList={patientsListState.value.items}
              setRowsPerPage={setRowsPerPage}
              rowsPerPage={rowsPerPage}
              allRows={patientsListState.value.allCount}
              setPage={setPage}
              page={page}
              onRemoveTableRow={handleDeleteClick}
              oneSorTablet={handleSort}
              handleSelectedPatient={handleSelectedPatient}
            />
          )}
        </S.ContentBox>
        <S.ContentBox>
          {selectedPatientId ? (
            <Tabs
              errorMessage={errorMessage}
              selectedPatient={patientState.value}
              selectedPatientId={selectedPatientId}
              selectedTab={selectedTab}
              handleTabChange={handleTabChange}
              handleFormSubmit={handleFormSubmit}
              handleFormCancel={handleFormCancel}
            />
          ) : (
            <S.EmptyField>
              {t('noRecords')}
              <br />
              {t('emptyField')}
            </S.EmptyField>
          )}
        </S.ContentBox>
      </S.PaperContainer>
    </S.Container>
  );
};

export default PatientsList;
