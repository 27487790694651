import styled from 'styled-components';
import { Typography } from '@mui/material';

export const ContentBox = styled.div`
  height: 100%;
  flex-direction: column;
  display: flex;
`;

export const ContentContainer = styled.div`
  margin-left: 10px;
  margin-top: ${({ mt }: { mt?: boolean }) => (mt ? '0' : '40px')};
`;

export const Wrapper = styled.div`
  padding: 20px;
  width: ${({ largeSize }: { largeSize?: boolean }) => (largeSize ? '350px' : '200px')};
`;

export const ButtonWrapper = styled(Wrapper)`
  display: flex;
`;

export const ContentBlock = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-left: 10px;
  align-items: center;
  justify-content: space-between;
`;

export const ContentLabel = styled(Typography)`
  color: rgba(0, 0, 0, 0.6);
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
  font-weight: 400;
  font-size: 13px;
  line-height: 1.4375em;
`;

export const ContentText = styled(Typography)`
  margin-top: 10px;
`;

export const Text = styled(ContentBlock)`
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 20px;
`;

export const ContentDescriptionWrapper = styled(Text)`
  width: 100%;
`;

export const ButtonContainer = styled.div`
  margin: 30px;
`;
