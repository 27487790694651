import { http } from '../index';
import * as formatters from './formatters';

export const getSettings = () => {
  return http.get(`/api/etl/settings`).then(formatters.getSettings);
};

export const postSettings = req => {
  return http.post(`/api/etl/file-upload-and-settings`, req).then(formatters.postSettings);
};

export const postMapping = req => {
  return http.post(`/api/etl/mapping`, req);
};

export const postTransformation = req => {
  return http.post(`/api/etl/transformation`, req).then(formatters.postSettings);
};

export const postImport = req => {
  return http.post(`/api/etl/import`, req).then(formatters.postSettings);
};

export const postTemplate = req => {
  return http.post(`/api/etl/template`, req);
};

export const templateDelete = (id: number) => {
  return http.delete(`/api/etl/template/${id}`);
};
