import { useState, useEffect } from 'react';
import { useAsyncFn } from 'react-use';
import { useHistory, useLocation } from 'react-router-dom';
import queryString from 'query-string';

import { SUPER_ADMIN } from 'constants/roles';
import { setRole } from 'services/localStorage';
import { resetPassword } from 'http/auth';
import FormFilling from './FormFilling';
import { Password } from './FormFilling/Form/validationSchema';
import FormSent from './FormSent';

import * as S from './styled';

const ResetPassword = () => {
  const location = useLocation();

  const [resetPasswordToken, setResetPasswordToken] = useState<string | string[] | null>();
  const [isSentPassword, setIsSentPassword] = useState(false);

  const [, reset] = useAsyncFn(
    async (values: Password) => {
      if (!values) {
        return;
      }

      await resetPassword({ plainPassword: values }, resetPasswordToken);
      setIsSentPassword(!isSentPassword);
    },
    [resetPasswordToken],
  );

  useEffect(() => {
    const { token, isAdmin } = queryString.parse(location.search);
    setRole(isAdmin === '1' ? SUPER_ADMIN : '');
    setResetPasswordToken(token);
  }, [location.search]);

  return (
    <S.Container>
      <S.Wrapper>{!isSentPassword ? <FormFilling reset={reset} /> : <FormSent />}</S.Wrapper>
    </S.Container>
  );
};

export default ResetPassword;
