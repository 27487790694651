import { createSlice } from '@reduxjs/toolkit';

import initialState, * as handlers from './handlers';

const clients = createSlice({
  name: 'clients',
  initialState,
  reducers: {
    setClientsList: handlers.setClientsList,
  },
});

export const { actions } = clients;

export default clients.reducer;
