import styled from 'styled-components';
import { Link as RouterLink } from 'react-router-dom';
import { TextField } from '@mui/material';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const Wrapper = styled(Container)`
  margin-bottom: 20px;
  width: 300px;
`;

export const Error = styled.div`
  color: ${({ theme }) => theme.colors.red};
  padding: 10px 0;
  text-align: left;
  width: 100%;
`;

export const Link = styled(RouterLink)`
  font-size: 14px;
  text-decoration: none;
  color: ${({ theme }) => theme.colors.blue};
  margin-bottom: 20px;
`;

export const RequiredLabel = styled.div`
  font-size: 14px;
  margin-bottom: 20px;
`;

export const ButtonWrapper = styled.div`
  width: 100%;

  && button {
    width: 100%;
    max-width: none;
  }
`;

export const PasswordTextField = styled(TextField)`
  .css-1uvydh2::-ms-reveal,
  .css-nxo287-MuiInputBase-input-MuiOutlinedInput-input::-ms-reveal {
    display: none;
  }
`;
