import React, { useMemo, useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';
import { useTranslation } from 'react-i18next';
import { yupResolver } from '@hookform/resolvers/yup';
import { TextField, InputAdornment, IconButton } from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import Button from 'components/Button';
import { theme } from 'theme';

import validationSchema, { Password } from './validationSchema';
import * as S from './styled';

type LoginFormProps = {
  onSubmit: (params: Password) => void;
};

type InitialState = {
  newPassword: boolean;
  repeatNewPassword: boolean;
};

const LoginForm = ({ onSubmit }: LoginFormProps) => {
  const { t } = useTranslation('common');
  const schema = useMemo(() => validationSchema(t), [t]);
  const [visibility, setVisibility] = useState<InitialState>({
    newPassword: false,
    repeatNewPassword: false,
  });

  const {
    handleSubmit,
    control,
    formState: { isSubmitting, errors },
  } = useForm<Password>({
    resolver: yupResolver(schema),
  });

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <S.Container>
        <S.Wrapper>
          <Controller
            name="first"
            control={control}
            render={({ field: { onChange, value } }) => (
              <S.PasswordTextField
                id="first"
                type={visibility.newPassword ? 'text' : 'password'}
                value={value}
                onChange={onChange}
                label={t('passwordPlaceholder')}
                size="small"
                error={!!errors.first}
                InputLabelProps={{
                  shrink: true,
                  required: true,
                }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        onClick={() => {
                          setVisibility({
                            newPassword: !visibility.newPassword,
                            repeatNewPassword: visibility.repeatNewPassword,
                          });
                        }}
                        edge="end"
                      >
                        {visibility.newPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                fullWidth
              />
            )}
          />
          <S.Error>
            <ErrorMessage name="first" errors={errors} />
          </S.Error>
        </S.Wrapper>
        <S.Wrapper>
          <Controller
            name="second"
            control={control}
            render={({ field: { onChange, value } }) => (
              <S.PasswordTextField
                id="second"
                type={visibility.repeatNewPassword ? 'text' : 'password'}
                value={value}
                onChange={onChange}
                label={t('resetPassword.repeatPassword')}
                size="small"
                error={!!errors.second}
                disabled={isSubmitting}
                InputLabelProps={{
                  shrink: true,
                  required: true,
                }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        onClick={() => {
                          setVisibility({
                            newPassword: visibility.newPassword,
                            repeatNewPassword: !visibility.repeatNewPassword,
                          });
                        }}
                        edge="end"
                      >
                        {visibility.repeatNewPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                fullWidth
              />
            )}
          />
          <S.Error>
            <ErrorMessage name="second" errors={errors} />
          </S.Error>
        </S.Wrapper>
        <S.ButtonWrapper>
          <Button
            textButton={t('resetPassword.reset')}
            buttonProps={{
              bgColor: theme.colors.primary,
              hoverColor: theme.colors.primaryShadow,
              variant: 'contained',
              type: 'submit',
              disabled: isSubmitting,
            }}
          />
        </S.ButtonWrapper>
      </S.Container>
    </form>
  );
};

export default LoginForm;
