import { getRole, getUser, getLab } from 'services/localStorage';
import { SWITCH_TO_USER, SUPER_ADMIN } from 'constants/roles';
import logo from 'images/logo.png';
import ChangeLang from '../ChangeLang';
import Profile from '../Profile';

import * as S from './styled';

type Props = {
  isDashboard: boolean;
};

export default function Header({ isDashboard = true }: Props) {
  const role = getRole();

  const getLabLabel = data => {
    switch (data) {
      case SUPER_ADMIN:
        return null;
      case SWITCH_TO_USER: {
        const labLabel = getLab();
        return <S.LabNameLabel>{labLabel}</S.LabNameLabel>;
      }
      default: {
        const labData = getUser();
        return <S.LabNameLabel>{labData.client.name}</S.LabNameLabel>;
      }
    }
  };

  return (
    <S.Bar position="fixed" sx={{ zIndex: theme => theme.zIndex.drawer + 1, bgcolor: '#fff' }}>
      <S.ToolbarWrapper>
        <S.Logo src={logo} alt="" />
        <div>
          {getLabLabel(role)}
          {isDashboard && <Profile />}
          <ChangeLang />
        </div>
      </S.ToolbarWrapper>
    </S.Bar>
  );
}
