import { Dialog, DialogContent, DialogTitle, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useTranslation } from 'react-i18next';

import Table from '../../Table';
import * as S from '../../styled';

const Modal = ({ open, close, data }: any) => {
  const { t } = useTranslation('common');

  return (
    <Dialog onClose={close} open={open} fullWidth maxWidth="lg">
      <S.InvalidRecordsTitle sx={{ m: 1 }}>
        <IconButton onClick={close}>
          <CloseIcon />
        </IconButton>
      </S.InvalidRecordsTitle>
      <DialogContent>
        {data.length === 0 ? <S.NoResult>{t('noResults')}</S.NoResult> : <Table data={data} />}
      </DialogContent>
    </Dialog>
  );
};

export default Modal;
