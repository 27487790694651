import { UserDetails } from 'typings/entities/users';
import { http } from '../index';
import * as formatters from './formatters';

type Props = {
  count: number;
  page: number;
  search: string;
  sortDirection: string;
  sortByField: string;
};

export const getUsers = (params: Props) => {
  return http.get('/api/users/', { params }).then(formatters.users);
};

export const removeUser = (id: number | undefined) => {
  return http.delete(`/api/users/${id}`);
};

export const postUsers = (req: UserDetails) => {
  return http.post(`/api/users/`, req);
};

export const putUsers = (req: UserDetails, id: number) => {
  return http.put(`/api/users/${id}`, req);
};

export const getUserById = (id: number | undefined) => {
  return http.get(`/api/users/${id}`).then(formatters.userById);
};
