import styled, { DefaultTheme } from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  height: 100vh;
`;

export const ContentBox = styled.div`
  width: ${({ isLogo }: { isLogo?: boolean }) => (isLogo ? '35%' : '65%')};
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${({ isLogo, theme }: { isLogo?: boolean; theme: DefaultTheme }) =>
    isLogo ? theme.colors.white : 'rgb(97 218 251 / 15%)'};
`;

export const ChangeLangWrapper = styled.div`
  position: absolute;
  top: 20px;
  right: 20px;
`;

export const LogoWrapper = styled.div`
  width: 420px;
  max-width: 420px;
  padding: 20px;
`;

export const Logo = styled.img`
  width: 100%;
`;
