import styled from 'styled-components';
import { Typography, Button, DialogTitle } from '@mui/material';

export const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 10px;
  margin-bottom: 40px;
`;

export const Wrapper = styled.div`
  margin-bottom: 20px;
  margin-right: 20px;
`;

export const Error = styled.div`
  color: ${({ theme }) => theme.colors.red};
  text-align: left;
  margin-left: 20px;
`;

export const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-right: 10px;
  margin-top: 50px;
`;

export const ButtonWrapper = styled.div`
  margin-right: 10px;
  margin-left: 10px;
  margin-bottom: 10px;
`;

export const ModalTitle = styled(DialogTitle)`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const ContextContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

export const ContextText = styled(Typography)`
  margin-top: 20px;
  margin-left: 20px;
  width: 220px;
`;

export const ContextPicker = styled.div`
  width: 300px;
`;
