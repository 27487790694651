import React, { useEffect, useState, useMemo } from 'react';
import { Table, TableRow, TableHead, TableCell, MenuItem, TablePagination, TableBody } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { settingsForPostSelector } from 'redux/etl/selectors';

import * as S from './styled';

type Props = {
  data: any;
  fields?: any;
  isShow?: boolean;
  handleSelectedValuesSelects?: (selectedValues) => void;
};

const BasicTable = ({ data, fields = [], isShow = false, handleSelectedValuesSelects }: any) => {
  const { t } = useTranslation('common');
  const settingsForPost = useSelector(settingsForPostSelector);
  const { mapping } = settingsForPost;
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);
  const copyFields = useMemo(() => {
    if (isShow) {
      return [...fields, { id: 'ignore', description: 'Ignore' }];
    }

    return fields;
  }, [fields, isShow]);
  const [selectedValues, setSelectedValues] = useState<any>([]);
  const [remainderValues, setRemainderValues] = useState(copyFields);

  useEffect(() => {
    if (selectedValues.length > 0) {
      handleSelectedValuesSelects(selectedValues);
    }
  }, [handleSelectedValuesSelects, selectedValues]);

  const tableHead = useMemo(
    () => data.length && Object.keys(data[0]).map(key => ({ field: key, headerName: key })),
    [data],
  );

  const getDescription = selectedCellId => {
    if (selectedCellId === 'ignore') {
      return 'Ignore';
    }

    const field = fields.find(item => item.id === selectedCellId);
    return field.description;
  };

  useEffect(() => {
    if (isShow && mapping) {
      let remainderValuesTemplate = [...copyFields];
      const selectedValuesTemplate = tableHead
        .map((item: any) => {
          if (mapping.map[item.field]) {
            if (mapping.map[item.field] !== 'ignore') {
              remainderValuesTemplate = remainderValuesTemplate.filter(value => value.id !== mapping.map[item.field]);
            }

            return {
              cell: item.field,
              description: fields.find(field => field.id === mapping.map[item.field])?.description,
              selectId: mapping.map[item.field],
            };
          }

          return '';
        })
        .filter(item => item !== '');

      setRemainderValues(remainderValuesTemplate);
      setSelectedValues(selectedValuesTemplate);
    }
  }, [isShow, mapping, tableHead, fields, setSelectedValues, setRemainderValues, copyFields]);

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChangeSelect = (event: any, item) => {
    let newRemainderValues = [...remainderValues];
    let newSelectedValues = [...selectedValues];
    const selectedCell = newSelectedValues.find(value => value.cell === item.field);

    if (selectedCell) {
      if (selectedCell.selectId !== 'ignore') {
        newRemainderValues = [
          ...newRemainderValues,
          { id: selectedCell.selectId, description: getDescription(selectedCell.selectId) },
        ];
      }

      newSelectedValues = newSelectedValues.filter(value => value.cell !== selectedCell.cell);
    }

    if (event.target.value === 'ignore') {
      setRemainderValues(newRemainderValues);
    } else {
      setRemainderValues(newRemainderValues.filter(value => value.id !== event.target.value));
    }

    setSelectedValues([
      ...newSelectedValues,
      {
        cell: item.field,
        selectId: event.target.value,
        description: getDescription(event.target.value),
      },
    ]);
  };

  const getSelectedValues = item => {
    const result = selectedValues.find(value => value.cell === item.field);
    return String(result?.selectId);
  };

  const getFields = item => {
    const getSelectedField = selectedValues.find(value => value.cell === item.field);

    if (getSelectedField && getSelectedField.selectId !== 'ignore') {
      return [...remainderValues, { id: getSelectedField.selectId, description: getSelectedField.description }];
    }

    return remainderValues;
  };

  return (
    <S.PaperWrapper>
      {data.length ? (
        <>
          <S.Container>
            <Table aria-label="simple table">
              <TableHead>
                <TableRow>
                  {tableHead.map(item => (
                    <S.Cell
                      key={item.field}
                      sx={{
                        textTransform: 'uppercase',
                      }}
                    >
                      <>
                        {isShow && (
                          <S.TableSelect
                            onChange={e => handleChangeSelect(e, item)}
                            value={getSelectedValues(item)}
                            sx={{
                              width: 150,
                              height: 30,
                            }}
                          >
                            {getFields(item).map(field => (
                              <MenuItem key={field.id} value={String(field.id)} data-description={field.description}>
                                {field.description}
                              </MenuItem>
                            ))}
                          </S.TableSelect>
                        )}
                        {item.headerName}
                      </>
                    </S.Cell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {data.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map(row => (
                  <S.Row hover>
                    {tableHead.map(item => {
                      const value = typeof row[item.field] === 'object' ? row[item.field].description : row[item.field];

                      return <TableCell key={item.field}>{value}</TableCell>;
                    })}
                  </S.Row>
                ))}
              </TableBody>
            </Table>
          </S.Container>
          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            labelRowsPerPage={t('labelRowsPerPage')}
            component="div"
            count={data.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </>
      ) : (
        <S.EmptyField>{t('noSearchResult')}</S.EmptyField>
      )}
    </S.PaperWrapper>
  );
};

BasicTable.defaultProps = {
  isShow: false,
  fields: [],
  handleSelectedValuesSelects: () => {},
};

export default BasicTable;
