import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAsyncFn } from 'react-use';
import { toast } from 'react-toastify';
import { Divider } from '@mui/material';
import * as Sentry from '@sentry/browser';
import debounce from 'lodash/debounce';

import Loader from 'components/Loader';
import { getVirusesUser, getVirusByIdUser } from 'http/viruses';
import { getError } from 'utils/error';
import SubHeader from 'components/SubHeader';
import VirusUserForm from './VirusUserForm';
import Table from '../VirusTable';
import * as S from '../styled';

type Order = 'asc' | 'desc';

const UserVirusesList = () => {
  const { t } = useTranslation('common');
  const [selectedVirusId, setSelectedVirusId] = useState<number | null>(null);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);
  const [search, setSearch] = useState<string>('');
  const [sortDirection, setSortDirection] = useState<Order>('asc');
  const [nameField, setNameField] = useState<string>('');
  const delayedSetSearch = debounce((string: string) => setSearch(string), 500);

  // eslint-disable-next-line consistent-return
  const [virusesListState, getVirusesList] = useAsyncFn(async () => {
    try {
      const data = await getVirusesUser({
        count: rowsPerPage,
        page,
        search,
        sortDirection,
        sortByField: nameField,
      });

      return data;
    } catch (err: any) {
      Sentry.captureException(new Error(getError(err).error));
      toast.error(getError(err).error);
    }
  }, [page, rowsPerPage, search, sortDirection, nameField]);

  const [virusState, getVirusData] = useAsyncFn(
    // eslint-disable-next-line consistent-return
    async (id: number) => {
      try {
        return await getVirusByIdUser(id);
      } catch (err: any) {
        Sentry.captureException(new Error(getError(err).error));
        toast.error(getError(err).error);
      }
    },
    [selectedVirusId],
  );

  useEffect(() => {
    getVirusesList();
  }, [getVirusesList, rowsPerPage, page, search, sortDirection, nameField]);

  const handleSort = (name: string, sort: Order) => {
    setSortDirection(sort);
    setNameField(name);
  };

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    delayedSetSearch(event.target.value);
  };

  const handleSelectedUser = (id: number) => {
    getVirusData(id);
    setSelectedVirusId(id);
  };

  const handleFormCancel = () => {
    setSelectedVirusId(null);
  };

  return (
    <S.Container>
      {(virusesListState.loading || virusState.loading) && <Loader />}
      <SubHeader
        title={t('virusListPage.title')}
        onSearchChange={handleSearchChange}
        textButton=""
        onFilterChange={() => {}}
        onClick={() => {}}
        partialSubHeader
      />
      <S.PaperContainer elevation={3}>
        <S.ContentBox>
          {virusesListState.value && (
            <Table
              order={sortDirection}
              orderBy={nameField}
              virusesList={virusesListState.value.items}
              setRowsPerPage={setRowsPerPage}
              rowsPerPage={rowsPerPage}
              allRows={virusesListState.value.allCount}
              setPage={setPage}
              page={page}
              oneSorTablet={handleSort}
              onRemoveTableRow={() => {}}
              handleSelectedVirus={handleSelectedUser}
            />
          )}
        </S.ContentBox>
        <Divider orientation="vertical" variant="middle" flexItem />
        <S.ContentBox>
          {selectedVirusId ? (
            <>
              <S.BlockTitle>{t('virusListPage.virusFormTitle')}</S.BlockTitle>
              <VirusUserForm
                selectedVirusId={selectedVirusId}
                selectedVirus={virusState.value}
                handleFormCancel={handleFormCancel}
              />
            </>
          ) : (
            <S.EmptyField>
              {t('noRecords')}
              <br />
              {t('emptyField')}
            </S.EmptyField>
          )}
        </S.ContentBox>
      </S.PaperContainer>
    </S.Container>
  );
};

export default UserVirusesList;
