import { DefaultTheme } from 'styled-components';

const theme: DefaultTheme = {
  colors: {
    primary: '#44aff2',
    primaryShadow: '#8fdcfc',
    blue: '#1e5192',
    white: '#fff',
    grey: '#474747',
    greyShadow: '#eeeeee',
    primaryBlue: '#61dafb',
    red: '#e61e35',
    redShadow: '#fdf2f3',
    darkBlue: '#282c34',
    secondary: 'magenta',
  },
};

export { theme };
