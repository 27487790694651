import React, { useState, useEffect } from 'react';
import { useAsyncFn } from 'react-use';
import { toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { RadioGroup, FormControlLabel, Radio } from '@mui/material';
import ChevronLeftOutlinedIcon from '@mui/icons-material/ChevronLeftOutlined';
import ChevronRightOutlinedIcon from '@mui/icons-material/ChevronRightOutlined';
import Button from 'components/Button';
import * as Sentry from '@sentry/browser';

import * as http from 'http/etl';
import Loader from 'components/Loader';
import { theme } from 'theme';
import { pageNumberSelector, settingsForPostSelector, allSettingsSelector } from 'redux/etl/selectors';
import { actions } from 'redux/etl';
import { getError } from 'utils/error';
import Table from '../Table';

import * as S from '../styled';

const St3 = () => {
  const { i18n, t } = useTranslation('common');
  const dispatch = useDispatch();
  const pageNumber = useSelector(pageNumberSelector);
  const settingsForPost = useSelector(settingsForPostSelector);
  const allSettings = useSelector(allSettingsSelector);
  const { selectedTemplateType, mapping } = settingsForPost;
  const [selectedRadio, setSelectedRadio] = useState(mapping.emptyPatientNumber);
  const [selectedValuesSelects, setSelectedValuesSelects] = useState([]);

  // eslint-disable-next-line consistent-return
  const [postSettingsState, postSettings] = useAsyncFn(async () => {
    try {
      const res = await http.postSettings(settingsForPost);

      return res;
    } catch (err: any) {
      Sentry.captureException(new Error(getError(err).error));
      toast.error(getError(err).error);
    }
  }, [i18n.language]);

  // eslint-disable-next-line consistent-return
  const [postMappingState, postMapping] = useAsyncFn(async () => {
    try {
      const lengthFields = Object.keys(postSettingsState.value[0]).length;

      if (lengthFields !== selectedValuesSelects.length) {
        toast.error(t('etl.step3.errorFields'));
      } else {
        const map = {};
        selectedValuesSelects.forEach((item: any) => {
          map[item.cell] = item.selectId;
        });

        const data = {
          ...settingsForPost,
          mapping: {
            emptyPatientNumber: selectedRadio,
            map,
          },
        };

        const res = await http.postMapping(data);
        dispatch(actions.setSettingsForPost(data));
        dispatch(actions.setPageNumber(pageNumber + 1));
      }
    } catch (err: any) {
      Sentry.captureException(new Error(getError(err).error));
      toast.error(getError(err).error);
    }
  }, [selectedValuesSelects, selectedRadio]);

  useEffect(() => {
    postSettings();
  }, [postSettings]);

  const handleChangeRadio = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedRadio((event.target as HTMLInputElement).value);
  };

  const handleNextPageNumber = () => {
    postMapping();
  };

  const handleBackPageNumber = () => {
    dispatch(actions.setPageNumber(pageNumber - 1));
  };

  return (
    <S.Container>
      {(postSettingsState.loading || postMappingState.loading) && <Loader />}
      <S.TitleContainer>
        <S.MainTitle>{t('etl.step3.mainTitle')}</S.MainTitle>
      </S.TitleContainer>
      <S.PaperContainer elevation={3}>
        <S.Title>{t('etl.step3.title')}</S.Title>
        {selectedTemplateType.description !== 'Patient' && (
          <RadioGroup aria-label={t('etl.step3.selectOption')} onChange={handleChangeRadio} value={selectedRadio}>
            <S.RadioBox>
              <S.ContentRadioLabel>{t('etl.step3.selectOption')}</S.ContentRadioLabel>
            </S.RadioBox>
            <S.OptionContainer>
              {allSettings.emptyPatientNumbers.map(option => {
                return (
                  <FormControlLabel value={option.id} control={<Radio size="small" />} label={option.description} />
                );
              })}
            </S.OptionContainer>
          </RadioGroup>
        )}
        {postSettingsState?.value && (
          <>
            <S.TableContainer>
              <Table
                data={postSettingsState?.value}
                fields={selectedTemplateType.fields}
                isShow
                handleSelectedValuesSelects={setSelectedValuesSelects}
              />
            </S.TableContainer>
            <S.ContentContainer mx>
              <S.ButtonWrapper>
                <Button
                  textButton={t('back')}
                  buttonProps={{
                    bgColor: theme.colors.grey,
                    hoverColor: theme.colors.greyShadow,
                    variant: 'outlined',
                    disabled: postMappingState?.loading || false,
                    onClick: handleBackPageNumber,
                    startIcon: <ChevronLeftOutlinedIcon />,
                  }}
                />
              </S.ButtonWrapper>
              <S.ButtonWrapper>
                <Button
                  textButton={t('next')}
                  buttonProps={{
                    bgColor: theme.colors.primary,
                    hoverColor: theme.colors.primaryShadow,
                    variant: 'contained',
                    onClick: handleNextPageNumber,
                    disabled:
                      postMappingState?.loading ||
                      Object.keys(postSettingsState.value[0]).length !== selectedValuesSelects.length,
                    startIcon: <ChevronRightOutlinedIcon />,
                  }}
                />
              </S.ButtonWrapper>
            </S.ContentContainer>
          </>
        )}
      </S.PaperContainer>
    </S.Container>
  );
};

export default St3;
