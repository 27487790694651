import { string, object } from 'yup';

const emailSchema = t =>
  object().shape({
    email: string().trim().email(t('wrongEmailFormat')).required(t('requiredEmail')),
  });

export type Email = {
  email: string;
};

export default emailSchema;
