import { useTranslation } from 'react-i18next';
import { useEffect, useMemo } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { TextField } from '@mui/material';
import { ErrorMessage } from '@hookform/error-message';
import ClearOutlinedIcon from '@mui/icons-material/ClearOutlined';
import DoneOutlinedIcon from '@mui/icons-material/DoneOutlined';
import Button from 'components/Button';
import { VirusDetails, Virus } from 'typings/entities/viruses';
import { theme } from 'theme';

import validationSchema from './validationSchema';
import * as S from '../styled';

type Props = {
  handleFormSubmit: (req: VirusDetails) => void;
  handleFormCancel: () => void;
  selectedVirusId: number;
  selectedVirus: Virus | undefined;
};

const VirusAdminForm = ({ selectedVirus, selectedVirusId, handleFormCancel, handleFormSubmit }: Props) => {
  const { t } = useTranslation('common');
  const schema = useMemo(() => validationSchema(t), [t]);
  const defaultValues = useMemo(() => {
    return selectedVirusId === -1
      ? {
          name: '',
          virusId: '',
          description: '',
        }
      : {
          ...selectedVirus,
        };
  }, [selectedVirusId, selectedVirus]);

  const {
    handleSubmit,
    control,
    reset,
    formState: { isSubmitting, errors },
  } = useForm<VirusDetails>({
    defaultValues,
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    reset(defaultValues);
  }, [defaultValues, reset]);

  return (
    <form onSubmit={handleSubmit(handleFormSubmit)}>
      <S.ContentContainer>
        <S.ContentWrapper>
          <S.Text>
            <Controller
              name="virusId"
              control={control}
              render={({ field: { onChange, value } }) => (
                <TextField
                  InputLabelProps={{
                    shrink: true,
                    required: true,
                  }}
                  size="small"
                  onChange={onChange}
                  value={value}
                  error={!!errors.virusId}
                  label={t('virusListPage.virusIdLabel')}
                  disabled={isSubmitting}
                  fullWidth
                />
              )}
            />
            <S.Error>
              <ErrorMessage name="virusId" errors={errors} />
            </S.Error>
          </S.Text>
        </S.ContentWrapper>
        <S.ContentWrapper>
          <S.Text>
            <Controller
              name="name"
              control={control}
              render={({ field: { onChange, value } }) => (
                <TextField
                  InputLabelProps={{
                    shrink: true,
                    required: true,
                  }}
                  size="small"
                  onChange={onChange}
                  value={value}
                  error={!!errors.name}
                  label={t('virusListPage.virusNameLabel')}
                  disabled={isSubmitting}
                  fullWidth
                />
              )}
            />
            <S.Error>
              <ErrorMessage name="name" errors={errors} />
            </S.Error>
          </S.Text>
        </S.ContentWrapper>
      </S.ContentContainer>
      <S.ContentContainer mt>
        <S.ContentDescriptionWrapper>
          <Controller
            name="description"
            control={control}
            render={({ field: { onChange, value } }) => (
              <TextField
                InputLabelProps={{
                  shrink: true,
                }}
                size="small"
                onChange={onChange}
                value={value}
                error={!!errors.description}
                label={t('virusListPage.virusDescriptionLabel')}
                disabled={isSubmitting}
                fullWidth
                multiline
                rows={7}
              />
            )}
          />
          <S.Error>
            <ErrorMessage name="description" errors={errors} />
          </S.Error>
        </S.ContentDescriptionWrapper>
      </S.ContentContainer>
      <S.ContentContainer mt>
        <S.ContentWrapper largeSize>
          <S.ButtonContainer>
            <S.ButtonWrapper>
              <Button
                textButton={t('saveButton')}
                buttonProps={{
                  bgColor: theme.colors.primary,
                  hoverColor: theme.colors.primaryShadow,
                  variant: 'contained',
                  type: 'submit',
                  disabled: isSubmitting,
                  startIcon: <DoneOutlinedIcon />,
                }}
              />
            </S.ButtonWrapper>
            <S.ButtonWrapper>
              <Button
                textButton={t('cancelButton')}
                buttonProps={{
                  bgColor: theme.colors.grey,
                  hoverColor: theme.colors.greyShadow,
                  variant: 'outlined',
                  onClick: handleFormCancel,
                  startIcon: <ClearOutlinedIcon />,
                }}
              />
            </S.ButtonWrapper>
          </S.ButtonContainer>
        </S.ContentWrapper>
      </S.ContentContainer>
    </form>
  );
};

export default VirusAdminForm;
