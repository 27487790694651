import styled from 'styled-components';
import { Box, Tab, Typography } from '@mui/material';
import TabPanel from '@mui/lab/TabPanel';

export const Panel = styled(TabPanel)`
  height: 90%;
  margin-top: -25px;
  padding: 24px 0 24px 24px;
`;

export const BlockTitle = styled(Typography)`
  padding: 30px;
`;

export const EmptyField = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  height: 100%;
`;

export const PageTab = styled(Tab)`
  text-transform: none;
  color: ${({ theme }) => theme.colors.primaryShadow};
  &.Mui-selected {
    color: ${({ theme }) => theme.colors.primary};
  }
`;

export const BoxWrapper = styled(Box)`
  .css-1aquho2-MuiTabs-indicator {
    background-color: ${({ theme }) => theme.colors.primary};
  }
`;
