import React from 'react';

import * as S from './styled';

const App: React.FC = () => {
  return (
    <S.Container>
      <S.Header>
        <p>
          Edit <code>src/App.tsx</code> and save to reload.
        </p>
        <S.Link href="https://reactjs.org" target="_blank" rel="noopener noreferrer">
          Learn React
        </S.Link>
      </S.Header>
    </S.Container>
  );
};

export default App;
