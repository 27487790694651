import styled from 'styled-components';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import TableContainer from '@mui/material/TableContainer';
import IconButton from '@mui/material/IconButton';

export const Cell = styled(TableCell)`
  text-transform: uppercase;
`;

export const Container = styled(TableContainer)`
  max-height: 630px;
`;

export const Row = styled(TableRow)`
  height: 50px;
`;

export const IconButtonWrapper = styled(IconButton)`
  && {
    padding: 0;
  }
`;

export const EmptyField = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  height: 652px;
`;
