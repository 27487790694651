import { useAsyncFn } from 'react-use';
import { toast } from 'react-toastify';
import { AsyncFnReturn } from 'react-use/lib/useAsyncFn';
import * as Sentry from '@sentry/browser';

import * as httpAuth from 'http/auth';
import { SWITCH_TO_USER } from 'constants/roles';
import { getRole } from 'services/localStorage';
import { actions as authActions } from 'redux/auth';
import { actions as authEtl } from 'redux/etl';
import { getError } from 'utils/error';

export const useSignOut = (dispatch): AsyncFnReturn => {
  const role = getRole();
  const [signOutState, signOut] = useAsyncFn(async () => {
    try {
      if (role === SWITCH_TO_USER) {
        await httpAuth.signOutAsClient('_exit');
      } else {
        await httpAuth.signOut();
      }

      localStorage.removeItem('user');
      localStorage.removeItem('token');

      dispatch(authEtl.clear());
      dispatch(authActions.signOut());
    } catch (err: any) {
      Sentry.captureException(new Error(getError(err).error));
      toast.error(getError(err).error);
    }
  }, []);

  return [signOutState, signOut];
};
