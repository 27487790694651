import { string, object } from 'yup';
import { emailExp } from 'configs/password';

const loginSchema = t =>
  object().shape({
    username: string().trim().matches(emailExp, t('wrongEmailFormat')).required(t('requiredEmail')),
    password: string().required(t('requiredPassword')),
  });

export type LoginData = {
  username: string;
  password: string;
};

export default loginSchema;
