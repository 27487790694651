import { Response } from 'typings/entities/patients';

export type AuthState = {
  patientsList: Response | null;
};

const initialState: AuthState = {
  patientsList: null,
};

export const setPatientsList = (state: AuthState, { payload }: { payload: Response }) => ({
  ...state,
  patientsList: payload,
});

export default initialState;
