import styled from 'styled-components';
import { Button, DialogTitle, IconButton } from '@mui/material';

export const Wrapper = styled.div`
  margin-bottom: 20px;
  margin-right: 20px;
  text-align: center;
`;

export const TextContainer = styled.div`
  margin: 0 30px;
`;

export const IconButtonContainer = styled(IconButton)`
  margin-bottom: 30px;
`;

export const ButtonContainer = styled.div`
  display: flex;
  margin-top: 50px;
  justify-content: center;
`;

export const ModalTitle = styled(DialogTitle)`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
