import styled from 'styled-components';

export const Container = styled.div`
  background: ${({ theme }) => theme.colors.white};
  padding: 35px;
  border-radius: 20px;
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
`;

export const Title = styled.div`
  font-size: 40px;
  margin-bottom: 20px;
  text-transform: capitalize;
`;

export const SubTitle = styled.div`
  margin-bottom: 25px;
`;

export const ButtonWrapper = styled.div`
  width: 100%;

  && button {
    width: 100%;
    max-width: none;
  }
`;
