import { Redirect, Route, Switch } from 'react-router-dom';

import ChangeLang from 'components/ChangeLang';
import { routes } from 'services/router';
import logo from 'images/clinical_Virology_Log.png';
import Login from './Login';
import RestorePassword from './RestorePassword';
import ResetPassword from './ResetPassword';

import * as S from './styled';

const Auth = () => {
  return (
    <S.Container>
      <S.ContentBox>
        <Switch>
          <Route exact path={[routes.authModule.login, routes.authModule.adminLogin]} component={Login} />
          <Route exact path={routes.authModule.restorePassword} component={RestorePassword} />
          <Route exact path={routes.authModule.resetPassword} component={ResetPassword} />
          <Redirect to={routes.authModule.login} />
        </Switch>
      </S.ContentBox>
      <S.ContentBox isLogo>
        <S.ChangeLangWrapper>
          <ChangeLang />
        </S.ChangeLangWrapper>
        <S.LogoWrapper>
          <S.Logo src={logo} alt="" />
        </S.LogoWrapper>
      </S.ContentBox>
    </S.Container>
  );
};

export default Auth;
