import { routes } from 'services/router';
import { SUPER_ADMIN, ADMIN, USER, SWITCH_TO_USER } from 'constants/roles';

export const drawerInfoAdmin = t => [
  {
    id: 'administration',
    name: t('menu.administration'),
    icon: 'Settings',
    components: [
      {
        id: 1,
        roles: [SUPER_ADMIN],
        name: t('menu.clients'),
        path: routes.dashboardModule.clientList,
      },
      {
        id: 2,
        roles: [SUPER_ADMIN],
        name: t('menu.viruses'),
        path: routes.dashboardModule.virusesList,
      },
    ],
  },
];

export const drawerInfoUser = t => [
  {
    id: 'data',
    name: t('menu.data'),
    icon: 'Science',
    components: [
      {
        id: 5,
        roles: [USER, ADMIN, SWITCH_TO_USER],
        name: t('menu.patients'),
        path: routes.dashboardModule.patientList,
      },
      {
        id: 6,
        roles: [USER, ADMIN, SWITCH_TO_USER],
        name: t('menu.etl'),
        path: routes.dashboardModule.etl,
      },
    ],
  },
  {
    id: 'administration',
    name: t('menu.administration'),
    icon: 'Settings',
    components: [
      {
        id: 3,
        roles: [ADMIN, SWITCH_TO_USER],
        name: t('menu.users'),
        path: routes.dashboardModule.userList,
      },
      {
        id: 4,
        roles: [USER, ADMIN, SWITCH_TO_USER],
        name: t('menu.viruses'),
        path: routes.dashboardModule.virusesList,
      },
    ],
  },
];
