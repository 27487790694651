import { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Controller, useForm } from 'react-hook-form';
import ClearOutlinedIcon from '@mui/icons-material/ClearOutlined';
import { TextField } from '@mui/material';

import Button from 'components/Button';
import { Virus } from 'typings/entities/viruses';
import { theme } from 'theme';

import * as S from './styled';

type Props = {
  handleFormCancel: () => void;
  selectedVirusId: number;
  selectedVirus: Virus | undefined;
};

type Form = {
  name: string;
  virusId: string;
  description: string;
};

const VirusUserForm = ({ selectedVirus, selectedVirusId, handleFormCancel }: Props) => {
  const { t } = useTranslation('common');

  const defaultValues = useMemo(
    () => ({
      ...selectedVirus,
    }),
    [selectedVirus],
  );

  const { control, reset } = useForm<Form>({
    defaultValues,
  });

  useEffect(() => {
    reset(defaultValues);
  }, [defaultValues, reset]);

  return (
    <form>
      <S.ContentBox>
        <S.ContentContainer mt>
          <S.ContentBlock>
            <S.Text>
              <Controller
                name="virusId"
                control={control}
                render={({ field: { onChange, value } }) => (
                  <TextField
                    InputLabelProps={{
                      shrink: true,
                      required: true,
                    }}
                    size="small"
                    onChange={onChange}
                    value={value}
                    label={t('virusListPage.virusIdLabel')}
                    disabled
                    fullWidth
                  />
                )}
              />
            </S.Text>
            <S.Text>
              <Controller
                name="name"
                control={control}
                render={({ field: { onChange, value } }) => (
                  <TextField
                    InputLabelProps={{
                      shrink: true,
                      required: true,
                    }}
                    size="small"
                    onChange={onChange}
                    value={value}
                    label={t('virusListPage.virusNameLabel')}
                    disabled
                    fullWidth
                  />
                )}
              />
            </S.Text>
          </S.ContentBlock>
        </S.ContentContainer>
        <S.ContentContainer>
          <S.ContentBlock>
            <S.ContentDescriptionWrapper>
              <Controller
                name="description"
                control={control}
                render={({ field: { onChange, value } }) => (
                  <TextField
                    InputLabelProps={{
                      shrink: true,
                    }}
                    size="small"
                    onChange={onChange}
                    value={value}
                    label={t('virusListPage.virusDescriptionLabel')}
                    disabled
                    fullWidth
                    multiline
                    rows={7}
                  />
                )}
              />
            </S.ContentDescriptionWrapper>
          </S.ContentBlock>
        </S.ContentContainer>
        <S.ButtonContainer>
          <Button
            textButton={t('closeButton')}
            buttonProps={{
              bgColor: theme.colors.grey,
              hoverColor: theme.colors.greyShadow,
              variant: 'outlined',
              onClick: handleFormCancel,
              startIcon: <ClearOutlinedIcon />,
            }}
          />
        </S.ButtonContainer>
      </S.ContentBox>
    </form>
  );
};

export default VirusUserForm;
