import styled from 'styled-components';
import { Paper } from '@mui/material';

type RadioContainerProps = {
  mt?: boolean;
  ml?: boolean;
};

export const Container = styled.div`
  padding: 25px;
`;

export const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 10px;
  margin-bottom: 40px;
  min-height: 775px;
`;

export const PaperContainer = styled(Paper)`
  display: flex;
  flex-wrap: wrap;
`;

export const Wrapper = styled.div`
  margin-bottom: 20px;
  margin-right: 20px;
`;

export const ContentBox = styled.div`
  margin-top: ${({ mt }: { mt?: boolean }) => (mt ? '50px' : '20px')};
  margin-left: 30px;
`;

export const Title = styled.div`
  margin-bottom: 20px;
  font-size: 28px;
  font-weight: 700;
`;

export const RadioContainer = styled.div<RadioContainerProps>`
  display: flex;
  flex-direction: row;
  margin-top: ${({ mt }) => (mt ? '10px' : '5px')};
  margin-left: ${({ ml }) => (ml ? '0' : '30px')};
`;

export const Error = styled.div`
  color: ${({ theme }) => theme.colors.red};
  text-align: left;
  margin-left: 20px;
`;

export const ButtonContainer = styled.div`
  position: absolute;
  bottom: 0;
  margin-left: 30px;
  margin-bottom: 40px;
  margin-top: 20px;
`;
