import styled from 'styled-components';
import { Typography } from '@mui/material';

export const ContentContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
  justify-content: space-between;
  margin: 20px 0;
  height: 100%;
`;

export const CommentContainer = styled.div`
  margin-bottom: 10px;
  width: 100%;
`;

export const Wrapper = styled.div`
  margin-bottom: 20px;
  width: 200px;
  min-width: 100px;

  .css-1u3bzj6-MuiFormControl-root-MuiTextField-root {
    width: 100%;
  }

  @media screen and (max-width: 1200px) {
    width: 100%;
  }
`;

export const ContentRadioLabel = styled(Typography)`
  width: 300px;
  margin: -10px 0;
  color: rgba(0, 0, 0, 0.6);
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
  font-weight: 400;
  font-size: 13px;
  line-height: 1.4375em;
`;

export const RadioContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 5px;
`;

export const Error = styled.div`
  color: ${({ theme }) => theme.colors.red};
  text-align: left;
  margin-left: 20px;
`;

export const ButtonWrapper = styled.div`
  margin-right: 10px;
  margin-bottom: 10px;
`;

export const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
`;
