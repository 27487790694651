import React from 'react';
import * as S from './styled';

type Props = {
  textButton: string;
  buttonProps: {
    bgColor: string;
    hoverColor?: string;
    variant: 'text' | 'outlined' | 'contained';
    type?: 'button' | 'submit' | 'reset' | undefined;
    onClick?: () => void;
    disabled?: boolean;
    startIcon?: React.ReactElement;
    endIcon?: React.ReactElement;
  };
};

const Button = ({ buttonProps, textButton }: Props) => {
  return <S.ButtonField {...buttonProps}>{textButton}</S.ButtonField>;
};

export default Button;
