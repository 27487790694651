import { createSlice } from '@reduxjs/toolkit';

import initialState, * as handlers from './handlers';

const etl = createSlice({
  name: 'etl',
  initialState,
  reducers: {
    setSettingsForPost: handlers.setSettingsForPost,
    setAllSettings: handlers.setAllSettings,
    setPageNumber: handlers.setPageNumber,
    setTransformation: handlers.setTransformation,
    setImportData: handlers.setImportData,
    setSelectedTemplate: handlers.setSelectedTemplate,
    clear: handlers.clear,
  },
});

export const { actions } = etl;

export default etl.reducer;
