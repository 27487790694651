import { NewExamination } from 'typings/entities/examination';
import { http } from '../index';
import * as formatters from './formatters';

type Props = {
  id: number;
  count: number;
  page: number;
  search: string;
  sortDirection: string;
  sortByField: string;
};

export const getExaminations = (params: Props) => {
  return http.get(`/api/examinations/patient/${params.id}`, { params }).then(formatters.examinations);
};

export const getExaminationMethod = () => {
  return http.get(`/api/examination-methods/`).then(formatters.examinationMethod);
};

export const postExaminations = (req: NewExamination, id: number | null) => {
  return http.post(`/api/examinations/patient/${id}`, req);
};

export const putExamination = (req: NewExamination, id: number | undefined) => {
  return http.put(`/api/examinations/${id}`, req);
};

export const getExaminationById = (id: number) => {
  return http.get(`/api/examinations/${id}`).then(formatters.examination);
};

export const deleteExaminationById = (id: number | undefined) => {
  return http.delete(`/api/examinations/${id}`);
};
