import styled from 'styled-components';
import { Box, Paper, Tab } from '@mui/material';
import TabPanel from '@mui/lab/TabPanel';

export const Container = styled.div`
  padding: 25px 20px 20px 25px;
`;

export const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const PaperContainer = styled(Paper)`
  display: flex;
  flex-wrap: wrap;
`;

export const ContentBox = styled.div`
  width: 49%;
`;

export const EmptyField = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  height: 100%;
`;

export const Panel = styled(TabPanel)`
  margin-top: -25px;
  height: 90%;
`;

export const PageTab = styled(Tab)`
  text-transform: none;
  color: ${({ theme }) => theme.colors.primaryShadow};
  &.Mui-selected {
    color: ${({ theme }) => theme.colors.primary};
  }
`;

export const BoxWrapper = styled(Box)`
  .css-1aquho2-MuiTabs-indicator {
    background-color: ${({ theme }) => theme.colors.primary};
  }
`;
