import { string, object } from 'yup';
import { dateExp } from 'configs/password';
import { isSameOrAfterCurrent, isSameOrBeforeCurrent } from 'utils/dateChecking';

const ExaminationSchema = t => {
  return object().shape({
    sampleNumber: string().trim().required(t('required')),
    result: string().trim().required(t('required')),
    virus: string().trim().required(t('required')),
    date: string()
      .nullable()
      .required(t('required'))
      .matches(dateExp, { message: t('wrongEmailFormat'), excludeEmptyString: false })
      .test('maxCurrent', t('maxCurrentDate'), function (value) {
        return isSameOrBeforeCurrent(value);
      })
      .test('minCurrent', t('minCurrentDate'), function (value) {
        return isSameOrAfterCurrent(value);
      }),
  });
};
export default ExaminationSchema;
