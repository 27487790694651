import React, { useEffect } from 'react';
import { MenuItem, TextField } from '@mui/material';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import DoneOutlinedIcon from '@mui/icons-material/DoneOutlined';
import Button from 'components/Button';
import { theme } from 'theme';

import * as S from '../../styled';

const Form = ({ handleFormSubmit, allSettings, isDisabled, defaultValues }: any) => {
  const { t } = useTranslation('common');

  const {
    handleSubmit,
    control,
    reset,
    formState: { isSubmitting },
  } = useForm<any>({});

  useEffect(() => {
    reset(defaultValues);
  }, [defaultValues, reset]);

  return (
    <form onSubmit={handleSubmit(handleFormSubmit)}>
      <S.OptionContainer>
        <S.Wrapper>
          <Controller
            name="encode"
            control={control}
            render={({ field: { onChange, value } }) => (
              <>
                <TextField
                  select
                  label={t('etl.step2.encoding')}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  size="small"
                  onChange={onChange}
                  value={String(value)}
                  sx={{
                    width: 195,
                    height: 40,
                  }}
                >
                  {allSettings?.encodes.map((encode: any) => (
                    <MenuItem key={encode.id} value={String(encode.id)}>
                      {encode.description}
                    </MenuItem>
                  ))}
                </TextField>
              </>
            )}
          />
        </S.Wrapper>
        <S.Wrapper>
          <Controller
            name="delimiter"
            control={control}
            render={({ field: { onChange, value } }) => (
              <>
                <TextField
                  select
                  label={t('etl.step2.delimiter')}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  size="small"
                  onChange={onChange}
                  value={String(value)}
                  sx={{
                    width: 195,
                    height: 40,
                  }}
                >
                  {allSettings?.delimiters.map((delimiter: any) => (
                    <MenuItem key={delimiter.id} value={String(delimiter.id)}>
                      {delimiter.description}
                    </MenuItem>
                  ))}
                </TextField>
              </>
            )}
          />
        </S.Wrapper>
        <S.Wrapper mr>
          <Controller
            name="enclosure"
            control={control}
            render={({ field: { onChange, value } }) => (
              <>
                <TextField
                  select
                  label={t('etl.step2.enclosing')}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  size="small"
                  onChange={onChange}
                  value={String(value)}
                  sx={{
                    width: 195,
                    height: 40,
                  }}
                >
                  {allSettings?.enclosures.map((enclosure: any) => (
                    <MenuItem key={enclosure.id} value={String(enclosure.id)}>
                      {enclosure.description}
                    </MenuItem>
                  ))}
                </TextField>
              </>
            )}
          />
        </S.Wrapper>
        <S.Wrapper>
          <Button
            textButton={t('etl.step2.apply')}
            buttonProps={{
              bgColor: theme.colors.primary,
              hoverColor: theme.colors.primaryShadow,
              variant: 'contained',
              type: 'submit',
              disabled: isDisabled,
              startIcon: <DoneOutlinedIcon />,
            }}
          />
        </S.Wrapper>
      </S.OptionContainer>
    </form>
  );
};

export default Form;
