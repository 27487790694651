import React from 'react';
import { useTranslation } from 'react-i18next';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';

import { Client, ClientDetails } from 'typings/entities/clients';
import UsersForm from '../Users';
import ClientsForm from '../ClientForm';

import * as S from './styled';

type Props = {
  selectedClientId: number;
  selectedTab: string;
  handleTabChange: (event: React.SyntheticEvent, newValue: string) => void;
  handleFormSubmit: (req: ClientDetails) => void;
  handleFormCancel: () => void;
  handleLoginAsClientSubmit: (email: string, name: string) => void;
  selectedClient: Client | undefined;
};

const Tabs = ({
  selectedClientId,
  selectedTab,
  handleTabChange,
  handleFormSubmit,
  selectedClient,
  handleFormCancel,
  handleLoginAsClientSubmit,
}: Props) => {
  const { t } = useTranslation('common');

  return (
    <Box sx={{ width: '100%', height: '100%', typography: 'body1' }}>
      <TabContext value={selectedTab}>
        <S.BoxWrapper>
          <TabList onChange={handleTabChange} centered>
            <S.PageTab label={t('clientListPage.clientDetails')} value="1" />
            <S.PageTab label={t('clientListPage.clientUsers')} value="2" disabled={selectedClientId === -1} />
          </TabList>
        </S.BoxWrapper>
        <S.Panel value="1">
          <ClientsForm
            handleFormSubmit={handleFormSubmit}
            selectedClientId={selectedClientId}
            selectedClient={selectedClient}
            handleFormCancel={handleFormCancel}
            handleLoginAsClientSubmit={handleLoginAsClientSubmit}
          />
        </S.Panel>
        <S.Panel value="2">
          <UsersForm selectedClientId={selectedClientId} />
        </S.Panel>
      </TabContext>
    </Box>
  );
};

export default Tabs;
