import { TextField, RadioGroup, FormControlLabel, Radio, Typography } from '@mui/material';
import { Controller, useForm } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';
import { useTranslation } from 'react-i18next';
import DeleteIcon from '@mui/icons-material/Delete';
import IconButton from '@mui/material/IconButton';
import ChevronRightOutlinedIcon from '@mui/icons-material/ChevronRightOutlined';
import Button from 'components/Button';
import { yupResolver } from '@hookform/resolvers/yup';
import { theme } from 'theme';

import validationSchema from './validationSchema';

import * as S from '../styled';

const EtlForm = ({ handleUploadClick, handleFormSubmit, templateRemove, isDisabled, allSettingsState }: any) => {
  const { t } = useTranslation('common');

  const defaultValues = {
    importTemplateType: '1',
    templateId: '-1',
  };

  const {
    handleSubmit,
    control,
    formState: { isSubmitting, errors },
  } = useForm<any>({
    defaultValues,
    resolver: yupResolver(validationSchema(t)),
  });

  return (
    <form style={{ position: 'relative', height: '100%' }} onSubmit={handleSubmit(handleFormSubmit)}>
      <S.ContentContainer>
        <S.Wrapper>
          <S.ContentBox mt>
            <Typography>{t('etl.step1.types')}</Typography>
          </S.ContentBox>
          <Controller
            name="importTemplateType"
            control={control}
            render={({ field }) => (
              <RadioGroup aria-label={t('etl.step1.types')} {...field}>
                <S.RadioContainer>
                  {allSettingsState?.value?.templateTypes.map(template => {
                    return (
                      <FormControlLabel
                        value={template.id}
                        control={<Radio size="small" />}
                        label={template.description}
                      />
                    );
                  })}
                </S.RadioContainer>
              </RadioGroup>
            )}
          />
        </S.Wrapper>
        <S.Wrapper>
          <S.ContentBox>
            <Typography>{t('etl.step1.upload')}</Typography>
          </S.ContentBox>
          <S.ContentBox>
            <Controller
              name="documentId"
              control={control}
              render={({ field: { onChange, value } }) => (
                <TextField
                  InputLabelProps={{
                    shrink: true,
                    required: true,
                  }}
                  size="small"
                  error={!!errors.documentId}
                  onChange={e => {
                    onChange(e);
                    handleUploadClick(e);
                  }}
                  value={value}
                  type="file"
                  inputProps={{ accept: '.csv' }}
                  label={t('etl.step1.document')}
                />
              )}
            />
            <S.Error>
              <ErrorMessage name="documentId" errors={errors} />
            </S.Error>
          </S.ContentBox>
        </S.Wrapper>
        <S.Wrapper>
          <S.ContentBox>
            <Typography>{t('etl.step1.hint.pleaseNotice')}</Typography>
            <Typography>
              <ul>
                <li>{t('etl.step1.hint.maximumImportDuration')}</li>
                <li>{t('etl.step1.hint.maximumNumberOfImportedFiles')}</li>
                <li>{t('etl.step1.hint.dateFormat')}</li>
                <li>{t('etl.step1.hint.encodingIsCaseSensitive')}</li>
              </ul>
            </Typography>
          </S.ContentBox>
        </S.Wrapper>
        <S.Wrapper>
          <Controller
            name="templateId"
            control={control}
            render={({ field }) => (
              <RadioGroup aria-label={t('etl.step1.templates')} {...field}>
                <S.ContentBox>{t('etl.step1.templates')}</S.ContentBox>
                <S.ContentBox>
                  <FormControlLabel value="-1" control={<Radio size="small" />} label={t('etl.step1.createNew')} />
                  {allSettingsState?.value?.templates.map(template => {
                    return (
                      <S.RadioContainer ml mt>
                        <FormControlLabel value={template.id} control={<Radio size="small" />} label={template.name} />
                        <IconButton onClick={() => templateRemove(template.id)}>
                          <DeleteIcon />
                        </IconButton>
                      </S.RadioContainer>
                    );
                  })}
                </S.ContentBox>
              </RadioGroup>
            )}
          />
        </S.Wrapper>
      </S.ContentContainer>
      <S.ButtonContainer>
        <Button
          textButton={t('next')}
          buttonProps={{
            bgColor: theme.colors.primary,
            hoverColor: theme.colors.primaryShadow,
            variant: 'contained',
            type: 'submit',
            disabled: isDisabled,
            startIcon: <ChevronRightOutlinedIcon />,
          }}
        />
      </S.ButtonContainer>
    </form>
  );
};

export default EtlForm;
