import { createSlice } from '@reduxjs/toolkit';

import initialState, * as handlers from './handlers';

const users = createSlice({
  name: 'users',
  initialState,
  reducers: {
    setUsersList: handlers.setUsersList,
  },
});

export const { actions } = users;

export default users.reducer;
