import React, { useEffect, useState } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TablePagination from '@mui/material/TablePagination';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableSortLabel from '@mui/material/TableSortLabel';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import DeleteIcon from '@mui/icons-material/Delete';

import { getRole } from 'services/localStorage';
import { useTranslation } from 'react-i18next';
import { USER, ADMIN, SWITCH_TO_USER } from 'constants/roles';

import * as S from './styled';

type Order = 'asc' | 'desc';

type Props<T extends { id: number }> = {
  setRowsPerPage: (value: number) => void;
  rowsPerPage: number;
  setPage: (value: number) => void;
  page: number;
  allRows: number;
  tableBody: T[];
  tableHead: { field: string; headerName: string }[];
  isShowEdit: boolean;
  isShowRemove: boolean;
  order: Order;
  orderBy: string;
  onSetSelectedRow: (id: number) => void;
  onRemoveTableRow?: (id: number) => void;
  oneSorTablet: (id: string, sort: Order) => void;
};

const BasicTable = <T extends { id: number }>({
  tableHead,
  tableBody,
  setRowsPerPage,
  rowsPerPage,
  setPage,
  allRows,
  page,
  order,
  orderBy,
  onRemoveTableRow = () => {},
  oneSorTablet,
  onSetSelectedRow,
  isShowEdit = false,
  isShowRemove = false,
}: Props<T>) => {
  const { t } = useTranslation('common');
  const role = getRole();
  const [isRole, setIsRole] = useState<boolean>(false);
  const [selectedRow, setSelectedRow] = useState<number>();

  useEffect(() => {
    setIsRole(tableHead.some(item => item.field === 'roles'));
  }, [tableHead]);

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleRequestSort = (event: React.MouseEvent<unknown>, property: string) => {
    const isAsc = orderBy === property && order === 'asc';
    oneSorTablet(property, isAsc ? 'desc' : 'asc');
  };

  const createSortHandler = (property: string) => (event: React.MouseEvent<unknown>) => {
    handleRequestSort(event, property);
  };

  const handleRemove = (id: number) => (event: React.MouseEvent<unknown>) => {
    event.stopPropagation();
    onRemoveTableRow(id);
  };

  const handleSelectedRow = (id: number) => {
    onSetSelectedRow(id);
    setSelectedRow(id);
  };

  const getDeletButton = row => {
    if (isShowRemove) {
      if (isRole && row.roles !== USER && (role === ADMIN || role === SWITCH_TO_USER)) {
        return <TableCell />;
      }

      return (
        <TableCell>
          <S.IconButtonWrapper onClick={handleRemove(row.id)}>
            <DeleteIcon />
          </S.IconButtonWrapper>
        </TableCell>
      );
    }

    return <></>;
  };

  return (
    <Paper>
      {tableBody.length ? (
        <>
          <S.Container>
            <Table aria-label="simple table">
              <TableHead>
                <TableRow>
                  {tableHead.map(item => (
                    <S.Cell
                      key={item.field}
                      sortDirection={orderBy === item.field ? order : false}
                      sx={{
                        textTransform: 'uppercase',
                      }}
                    >
                      <TableSortLabel
                        active={orderBy === item.field}
                        direction={orderBy === item.field ? order : 'asc'}
                        onClick={createSortHandler(item.field)}
                      >
                        {item.headerName}
                      </TableSortLabel>
                    </S.Cell>
                  ))}
                  {isShowEdit && <TableCell />}
                  {isShowRemove && <TableCell />}
                </TableRow>
              </TableHead>
              <TableBody>
                {tableBody.map(row => (
                  <S.Row onClick={() => handleSelectedRow(row.id)} hover selected={selectedRow === row.id}>
                    {tableHead.map(item => {
                      const value =
                        typeof row[item.field] === 'object'
                          ? row[item.field].virusId || row[item.field].description
                          : row[item.field];

                      return <TableCell key={item.field}>{value}</TableCell>;
                    })}
                    {getDeletButton(row)}
                  </S.Row>
                ))}
              </TableBody>
            </Table>
          </S.Container>
          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={allRows}
            rowsPerPage={rowsPerPage}
            labelRowsPerPage={t('labelRowsPerPage')}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </>
      ) : (
        <S.EmptyField>{t('noSearchResult')}</S.EmptyField>
      )}
    </Paper>
  );
};

BasicTable.defaultProps = {
  onRemoveTableRow: () => {},
};

export default BasicTable;
