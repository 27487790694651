import React, { useMemo, useEffect } from 'react';
import { TextField, RadioGroup, Radio } from '@mui/material';
import { Controller, useForm } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';
import { yupResolver } from '@hookform/resolvers/yup';
import { useTranslation } from 'react-i18next';
import ClearOutlinedIcon from '@mui/icons-material/ClearOutlined';
import DoneOutlinedIcon from '@mui/icons-material/DoneOutlined';
import LoginOutlinedIcon from '@mui/icons-material/LoginOutlined';
import Button from 'components/Button';

import { ClientDetails, Client } from 'typings/entities/clients';
import { theme } from 'theme';
import validationSchema from './validationSchema';

import * as S from './styled';

type Props = {
  handleFormSubmit: (req: ClientDetails) => void;
  handleLoginAsClientSubmit: (email: string, name: string) => void;
  handleFormCancel: () => void;
  selectedClientId: number | null;
  selectedClient: Client | undefined;
};

const ClientsForm = ({
  handleFormSubmit,
  selectedClient,
  selectedClientId,
  handleFormCancel,
  handleLoginAsClientSubmit,
}: Props) => {
  const { t } = useTranslation('common');
  const schema = useMemo(() => validationSchema(t), [t]);
  const defaultValues = useMemo(() => {
    return selectedClientId === -1
      ? {
          email: '',
          country: '',
          city: '',
          street: '',
          zipcode: '',
          geodata: '',
          status: '1',
          name: '',
        }
      : {
          ...selectedClient,
          status: String(selectedClient?.status.id),
        };
  }, [selectedClientId, selectedClient]);

  const {
    handleSubmit,
    control,
    reset,
    formState: { isSubmitting, errors },
  } = useForm<ClientDetails>({
    defaultValues,
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    reset(defaultValues);
  }, [defaultValues, reset]);

  return (
    <form onSubmit={handleSubmit(handleFormSubmit)}>
      <S.ContentContainer>
        <S.Container>
          <S.Wrapper>
            <Controller
              name="name"
              control={control}
              render={({ field: { onChange, value } }) => (
                <TextField
                  InputLabelProps={{
                    shrink: true,
                    required: true,
                  }}
                  size="small"
                  onChange={onChange}
                  value={value}
                  error={!!errors.name}
                  label={t('clientListPage.labNameLabel')}
                  disabled={isSubmitting}
                />
              )}
            />
            <S.Error>
              <ErrorMessage name="name" errors={errors} />
            </S.Error>
          </S.Wrapper>
          <S.Wrapper>
            <Controller
              name="email"
              control={control}
              render={({ field: { onChange, value } }) => (
                <TextField
                  InputLabelProps={{
                    shrink: true,
                    required: true,
                  }}
                  size="small"
                  type="email"
                  onChange={onChange}
                  value={value}
                  error={!!errors.email}
                  label={t('clientListPage.emailLabel')}
                  disabled={isSubmitting}
                />
              )}
            />
            <S.Error>
              <ErrorMessage name="email" errors={errors} />
            </S.Error>
          </S.Wrapper>
        </S.Container>
        <S.Container>
          <S.Wrapper>
            <Controller
              name="country"
              control={control}
              render={({ field: { onChange, value } }) => (
                <TextField
                  InputLabelProps={{
                    shrink: true,
                    required: true,
                  }}
                  size="small"
                  onChange={onChange}
                  value={value}
                  error={!!errors.country}
                  label={t('clientListPage.countryLabel')}
                  disabled={isSubmitting}
                />
              )}
            />
            <S.Error>
              <ErrorMessage name="country" errors={errors} />
            </S.Error>
          </S.Wrapper>
          <S.Wrapper>
            <Controller
              name="city"
              control={control}
              render={({ field: { onChange, value } }) => (
                <TextField
                  InputLabelProps={{
                    shrink: true,
                    required: true,
                  }}
                  size="small"
                  onChange={onChange}
                  value={value}
                  error={!!errors.city}
                  label={t('clientListPage.cityLabel')}
                  disabled={isSubmitting}
                />
              )}
            />
            <S.Error>
              <ErrorMessage name="city" errors={errors} />
            </S.Error>
          </S.Wrapper>
        </S.Container>
        <S.Container>
          <S.Wrapper>
            <Controller
              name="zipcode"
              control={control}
              render={({ field: { onChange, value } }) => (
                <TextField
                  InputLabelProps={{
                    shrink: true,
                    required: true,
                  }}
                  size="small"
                  onChange={onChange}
                  value={value}
                  error={!!errors.zipcode}
                  label={t('clientListPage.zipcodeLabel')}
                  disabled={isSubmitting}
                />
              )}
            />
            <S.Error>
              <ErrorMessage name="zipcode" errors={errors} />
            </S.Error>
          </S.Wrapper>
          <S.Wrapper>
            <Controller
              name="street"
              control={control}
              render={({ field: { onChange, value } }) => (
                <TextField
                  InputLabelProps={{
                    shrink: true,
                    required: true,
                  }}
                  size="small"
                  onChange={onChange}
                  value={value}
                  error={!!errors.street}
                  label={t('clientListPage.streetLabel')}
                  disabled={isSubmitting}
                />
              )}
            />
            <S.Error>
              <ErrorMessage name="street" errors={errors} />
            </S.Error>
          </S.Wrapper>
        </S.Container>
        <S.Container>
          <S.Wrapper>
            <Controller
              name="geodata"
              control={control}
              render={({ field: { onChange, value } }) => (
                <TextField
                  InputLabelProps={{
                    shrink: true,
                  }}
                  size="small"
                  onChange={onChange}
                  value={value}
                  label={t('clientListPage.geodataLabel')}
                  disabled={isSubmitting}
                />
              )}
            />
            <S.Error>
              <ErrorMessage name="geodata" errors={errors} />
            </S.Error>
          </S.Wrapper>
        </S.Container>
        <S.Container>
          <S.Wrapper isRadio>
            <Controller
              render={({ field }) => (
                <RadioGroup aria-label="status" {...field}>
                  <S.ContentRadioLabel>{t('clientListPage.radioLabel')}</S.ContentRadioLabel>
                  <S.RadioContainer>
                    <S.RadioControlLabel
                      value="1"
                      control={<Radio size="small" />}
                      label={t('clientListPage.activeStatus')}
                    />
                    <S.RadioControlLabel
                      value="2"
                      control={<Radio size="small" />}
                      label={t('clientListPage.inactiveStatus')}
                    />
                  </S.RadioContainer>
                </RadioGroup>
              )}
              name="status"
              control={control}
            />
            <S.Error style={{ width: '300px' }}>
              <ErrorMessage name="status" errors={errors} />
            </S.Error>
          </S.Wrapper>
        </S.Container>
        <S.ButtonContainer>
          <S.ButtonWrapper>
            <Button
              textButton={t('saveButton')}
              buttonProps={{
                bgColor: theme.colors.primary,
                hoverColor: theme.colors.primaryShadow,
                variant: 'contained',
                type: 'submit',
                disabled: isSubmitting,
                startIcon: <DoneOutlinedIcon />,
              }}
            />
          </S.ButtonWrapper>
          <S.ButtonWrapper>
            <Button
              textButton={t('cancelButton')}
              buttonProps={{
                bgColor: theme.colors.grey,
                hoverColor: theme.colors.greyShadow,
                variant: 'outlined',
                onClick: handleFormCancel,
                disabled: isSubmitting,
                startIcon: <ClearOutlinedIcon />,
              }}
            />
          </S.ButtonWrapper>
          <S.ButtonWrapper>
            {selectedClientId !== -1 && selectedClient && (
              <Button
                textButton={t('loginAsClient')}
                buttonProps={{
                  bgColor: theme.colors.primary,
                  hoverColor: theme.colors.primaryShadow,
                  variant: 'contained',
                  onClick: () => handleLoginAsClientSubmit(selectedClient?.lab_admin, selectedClient?.name),
                  disabled: isSubmitting,
                  startIcon: <LoginOutlinedIcon />,
                }}
              />
            )}
          </S.ButtonWrapper>
        </S.ButtonContainer>
      </S.ContentContainer>
    </form>
  );
};

export default ClientsForm;
