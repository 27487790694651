import { string, object, ref } from 'yup';

import { passwordExp } from 'configs/password';

const passwordSchema = t =>
  object().shape({
    first: string()
      .min(8, t('minPassword'))
      .max(16, t('maxPassword'))
      .matches(passwordExp, t('weakPassword'))
      .required(t('requiredPassword')),
    second: string()
      .oneOf([ref('first')], t('matchPassword'))
      .required(t('required')),
  });

export type Password = {
  second: string;
  first: string;
};

export default passwordSchema;
