import { ErrorType, ValidationMessages } from 'typings/error';

type ErrorMessageType = {
  error?: string;
  errors?: ValidationMessages;
  errorsForm?: { key: string; message: string }[];
};

export const getError = (error: ErrorType): ErrorMessageType => {
  let errorMessage;
  let errorsForm;
  const errorsMessage = error?.response?.data?.errors;

  if (typeof error?.response?.data?.error === 'object') {
    errorsForm = Object.keys(error?.response?.data?.error).map(item => ({
      key: item,
      message: error?.response?.data?.error?.[item],
    }));
  } else {
    errorMessage = error?.response?.data?.error;
  }

  return {
    errorsForm,
    error: errorMessage,
    errors: errorsMessage,
  };
};
