import DialogContent from '@mui/material/DialogContent';
import Dialog from '@mui/material/Dialog';
import { useTranslation } from 'react-i18next';
import { theme } from 'theme';
import CloseIcon from '@mui/icons-material/Close';
import ClearOutlinedIcon from '@mui/icons-material/ClearOutlined';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import Button from '../Button';

import * as S from './styled';

type Props = {
  bodyText: string;
  confirmationButtonText: string;
  isOpen: boolean;
  onConfirmClick: () => void;
  onCancelClick: () => void;
};

export function ConfirmationDialog(props: Props) {
  const { t } = useTranslation('common');
  const { bodyText, isOpen, onConfirmClick, onCancelClick, confirmationButtonText } = props;

  return (
    <Dialog
      sx={{ '& .MuiDialog-paper': { width: '80%', maxHeight: 435 } }}
      maxWidth="xs"
      open={isOpen}
      onClose={onCancelClick}
    >
      <S.ModalTitle sx={{ m: 1 }}>
        <S.Wrapper>
          <S.TextContainer>{bodyText}</S.TextContainer>
        </S.Wrapper>
        <S.IconButtonContainer onClick={onCancelClick}>
          <CloseIcon />
        </S.IconButtonContainer>
      </S.ModalTitle>
      <DialogContent>
        <S.ButtonContainer>
          <S.Wrapper>
            <Button
              textButton={confirmationButtonText}
              buttonProps={{
                bgColor: theme.colors.red,
                hoverColor: theme.colors.redShadow,
                variant: 'outlined',
                onClick: onConfirmClick,
                startIcon: <DeleteOutlinedIcon />,
              }}
            />
          </S.Wrapper>
          <S.Wrapper>
            <Button
              textButton={t('cancelButton')}
              buttonProps={{
                bgColor: theme.colors.grey,
                hoverColor: theme.colors.greyShadow,
                variant: 'outlined',
                onClick: onCancelClick,
                startIcon: <ClearOutlinedIcon />,
              }}
            />
          </S.Wrapper>
        </S.ButtonContainer>
      </DialogContent>
    </Dialog>
  );
}
