import { Redirect, Route, Switch } from 'react-router-dom';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';

import { routes } from 'services/router';
import Header from 'components/Header';
import Drawer from 'components/Drawer';
import EditProfile from './EditProfile';
import ClientList from './ClientList';
import ETL from './ETL';
import PatientList from './PatientList';
import UserList from './UserList';
import VirusesList from './VirusesList';

const Dashboard = () => (
  <Box sx={{ display: 'flex', position: 'relative' }}>
    <Header isDashboard />
    <Drawer />
    <Box component="main" sx={{ flexGrow: 1 }}>
      <Toolbar />
      <Switch>
        <Route exact path={routes.dashboardModule.editProfile} component={EditProfile} />
        <Route exact path={routes.dashboardModule.clientList} component={ClientList} />
        <Route exact path={routes.dashboardModule.userList} component={UserList} />
        <Route exact path={routes.dashboardModule.etl} component={ETL} />
        <Route exact path={routes.dashboardModule.patientList} component={PatientList} />
        <Route exact path={routes.dashboardModule.virusesList} component={VirusesList} />
        <Redirect to={routes.dashboardModule.editProfile} />
      </Switch>
    </Box>
  </Box>
);

export default Dashboard;
