import styled from 'styled-components';
import { Button, DialogTitle } from '@mui/material';

export const ModalTitle = styled(DialogTitle)`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
`;

export const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
`;

export const RadioWrapper = styled(ButtonContainer)`
  margin-bottom: 20px;
`;

export const ButtonWrapper = styled.div`
  margin-left: 10px;
`;
