import { getRole } from 'services/localStorage';
import { SUPER_ADMIN } from 'constants/roles';
import UserVirusesList from './UserVirusesList';
import AdminVirusesList from './AdminVirusesList';

function VirusesList() {
  const role = getRole();
  const isSuperAdmin = role === SUPER_ADMIN;

  return isSuperAdmin ? <AdminVirusesList /> : <UserVirusesList />;
}

export default VirusesList;
