import { useTranslation } from 'react-i18next';
import Table from 'components/Table';
import { Examination } from 'typings/entities/examination';

type Props = {
  examinationsList: Examination[];
  setRowsPerPage: (value: number) => void;
  rowsPerPage: number;
  setPage: (value: number) => void;
  onRemoveTableRow: (id: number) => void;
  handleSelectedExamination: (id: number) => void;
  oneSorTablet: (id: string, sort: Order) => void;
  page: number;
  allRows: number;
  order: Order;
  orderBy: string;
};

type Order = 'asc' | 'desc';

const ExaminationTable = ({
  examinationsList,
  setRowsPerPage,
  rowsPerPage,
  setPage,
  allRows,
  order,
  page,
  orderBy,
  onRemoveTableRow,
  oneSorTablet,
  handleSelectedExamination,
}: Props) => {
  const { t } = useTranslation('common');

  const tableHead = [
    { field: 'sampleNumber', headerName: t('examination.sampleNumber') },
    { field: 'virus', headerName: t('examination.virusID') },
    { field: 'date', headerName: t('examination.examinationDate') },
  ];

  return (
    <Table<Examination>
      onSetSelectedRow={handleSelectedExamination}
      tableHead={tableHead}
      tableBody={examinationsList}
      setRowsPerPage={setRowsPerPage}
      rowsPerPage={rowsPerPage}
      setPage={setPage}
      page={page}
      order={order}
      orderBy={orderBy}
      allRows={allRows}
      onRemoveTableRow={onRemoveTableRow}
      isShowRemove
      isShowEdit={false}
      oneSorTablet={oneSorTablet}
    />
  );
};

export default ExaminationTable;
