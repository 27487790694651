import styled from 'styled-components';
import { FormControl, IconButton, Typography } from '@mui/material';

export const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
`;

export const PageHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
`;

export const Title = styled.p`
  margin: auto 0;
  font-style: normal;
  font-weight: 700;
  font-size: ${({ titleSize }: { titleSize?: boolean }) => (titleSize ? '18px' : '28px')};
`;

export const FormContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
`;

export const ImportButtonContainer = styled.div`
  margin-left: auto;
`;

export const BlockTitle = styled(Typography)`
  padding: 30px;
`;

export const FormControlWrapper = styled(FormControl)`
  margin-bottom: 10px;
`;

export const IconButtonWrapper = styled(IconButton)`
  margin-bottom: 10px;
`;

export const Container = styled.div`
  display: flex;
  flex-direction: row;
`;

export const ButtonWrapper = styled.div`
  margin-left: ${({ ml }: { ml?: boolean }) => (ml ? '10px' : '0')};
  margin-bottom: 10px;
`;

export const ContentWrapper = styled(Container)`
  margin: 0 0 20px 20px;
`;

export const Wrapper = styled(Container)`
  width: 200px;
`;

export const Text = styled(Container)`
  flex-direction: column;
  width: 100%;
`;

export const ContentRadioLabel = styled(Typography)`
  width: 300px;
  margin: -10px 0;
  color: rgba(0, 0, 0, 0.6);
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
  font-weight: 400;
  font-size: 13px;
  line-height: 1.4375em;
`;

export const RadioContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 5px;
`;

export const Error = styled.div`
  color: ${({ theme }) => theme.colors.red};
  text-align: left;
  width: 100%;
`;
