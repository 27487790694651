import styled from 'styled-components';
import { DialogTitle, Paper, Typography } from '@mui/material';

export const TransformationWrapper = styled.div`
  margin: 20px;
  padding: 20px;
  border: 1px solid;
  border-radius: 5px;
`;

export const NoResult = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 872px;
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 25px;
`;

export const ContentContainer = styled.div`
  display: flex;
  align-items: center;
  margin: ${({ mx }: { mx?: boolean }) => (mx ? '0 10px 20px' : '0 10px 10px')};
`;

export const OptionContainer = styled.div`
  display: flex;
  align-items: center;
  margin: 0 20px 10px;
`;

export const Wrapper = styled.div`
  margin-bottom: 20px;
  margin-right: ${({ mr }: { mr?: boolean }) => (mr ? '10px' : '20px')};
`;

export const ContentRadioLabel = styled(Typography)`
  margin: -10px 0;
  color: rgba(0, 0, 0, 0.6);
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
  font-weight: 400;
  font-size: 13px;
  line-height: 1.4375em;
`;

export const Title = styled.div`
  margin: 30px 20px 20px;
  font-size: 20px;
  font-weight: bold;
`;

export const RadioContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 5px;
`;

export const Error = styled.div`
  color: ${({ theme }) => theme.colors.red};
  text-align: left;
  margin-left: 20px;
`;

export const SubTitle = styled.div`
  display: flex;
  padding-right: 20px;
  align-items: end;
  justify-content: space-between;
  margin-bottom: 30px;
  font-weight: bold;
`;

export const TitleContainer = styled.div`
  margin-bottom: 20px;
`;

export const MainTitle = styled.div`
  font-style: normal;
  font-weight: 700;
  font-size: 28px;
`;

export const TableContainer = styled.div`
  margin: 0 20px;
`;

export const PaperContainer = styled(Paper)`
  width: calc(100vw - 280px);
`;

export const RadioBox = styled.div`
  margin: 5px 20px 10px;
`;

export const WrapperRule = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const InvalidRecordsContainer = styled.div`
  margin-left: 10px;
  margin-bottom: 15px;
`;

export const ButtonWrapper = styled.div`
  margin-left: 10px;
`;

export const ModalTitle = styled(DialogTitle)`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const RuleContainer = styled.div`
  margin-left: 15px;
`;

export const InvalidRecordsTitle = styled(DialogTitle)`
  display: flex;
  justify-content: right;
  align-items: center;
`;
