import { useTranslation } from 'react-i18next';
import Box from '@mui/material/Box';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';

import { PatientDetails, Patient } from 'typings/entities/patients';
import PatientForm from '../PatientForm';
import Examination from '../Examination';

import * as S from '../styled';

type Props = {
  selectedPatientId: number | null;
  selectedTab: string;
  errorMessage: any;
  handleTabChange: (event: React.SyntheticEvent, newValue: string) => void;
  handleFormSubmit: (req: PatientDetails) => void;
  handleFormCancel: () => void;
  selectedPatient: Patient | undefined;
};

const Tabs = ({
  selectedPatientId,
  selectedTab,
  handleTabChange,
  handleFormSubmit,
  selectedPatient,
  handleFormCancel,
  errorMessage,
}: Props) => {
  const { t } = useTranslation('common');

  return (
    <Box sx={{ width: '100%', height: '100%', typography: 'body1' }}>
      <TabContext value={selectedTab}>
        <S.BoxWrapper>
          <TabList onChange={handleTabChange} centered>
            <S.PageTab label={t('patientsPage.patientDetails')} value="1" />
            <S.PageTab label={t('patientsPage.examinations')} value="2" disabled={selectedPatientId === -1} />
          </TabList>
        </S.BoxWrapper>
        <S.Panel value="1">
          <PatientForm
            handleFormSubmit={handleFormSubmit}
            selectedPatientId={selectedPatientId}
            selectedPatient={selectedPatient}
            handleFormCancel={handleFormCancel}
            errorMessage={errorMessage}
          />
        </S.Panel>
        <S.Panel value="2">
          <Examination selectedPatientId={selectedPatientId} selectedPatientNumber={selectedPatient?.number} />
        </S.Panel>
      </TabContext>
    </Box>
  );
};

export default Tabs;
