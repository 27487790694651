import { SessionUser } from 'typings/entities/user';

const I18NEXT_LNG = 'i18nextLng';
const USER = 'user';
const TOKEN = 'token';
const ROLE = 'role';
const LAB = 'lab';
const MENU = 'menu';
const MENU_ITEM = 'menu_item';

export const clearStorage = () => localStorage.clear();

export const setOpenedMenu = (openedMenu: string[] | null): void =>
  localStorage.setItem(MENU, JSON.stringify(openedMenu));

export const getOpenedMenu = (): void => JSON.parse(localStorage.getItem(MENU) || JSON.stringify(''));

export const setSelectedMenuItemId = (id: number | null): void => localStorage.setItem(MENU_ITEM, JSON.stringify(id));

export const getSelectedMenuItemId = (): void => JSON.parse(localStorage.getItem(MENU_ITEM) || JSON.stringify(null));

export const setRole = (role: string | string[] | null): void => localStorage.setItem(ROLE, JSON.stringify(role));

export const getRole = (): string => JSON.parse(localStorage.getItem(ROLE) || JSON.stringify(''));

export const setUser = (user): void => localStorage.setItem(USER, JSON.stringify(user));

export const getUser = (): SessionUser => JSON.parse(localStorage.getItem(USER) || JSON.stringify(''));

export const setLab = (name: string | null): void => localStorage.setItem(LAB, JSON.stringify(name));

export const getLab = (): string => JSON.parse(localStorage.getItem(LAB) || JSON.stringify(''));

export const setToken = (token: string): void => localStorage.setItem(TOKEN, token);

export const getToken = (): string | null => localStorage.getItem(TOKEN);

export const getI18nextLng = (): string | null => localStorage.getItem(I18NEXT_LNG);
