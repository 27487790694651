import { useState, useCallback, useMemo, useEffect } from 'react';
import {
  Collapse,
  ListItemButton,
  ListItemText,
  Divider,
  List,
  Toolbar,
  Drawer,
  Box,
  ListItemIcon,
} from '@mui/material';
import { ExpandLess, ExpandMore } from '@mui/icons-material';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import ScienceOutlinedIcon from '@mui/icons-material/ScienceOutlined';

import {
  getRole,
  getOpenedMenu,
  setOpenedMenu,
  setSelectedMenuItemId,
  getSelectedMenuItemId,
} from 'services/localStorage';
import { SUPER_ADMIN } from 'constants/roles';
import { drawerInfoAdmin, drawerInfoUser } from './config';

export default function Menu() {
  const { t } = useTranslation('common');
  const openedMenu = useMemo(() => getOpenedMenu(), []);
  const selectedMenuItemId = getSelectedMenuItemId();
  const [openIds, setOpenIds] = useState<any>(openedMenu);
  const [selectedMenuItem, setSelectedMenuItem] = useState<any>(selectedMenuItemId);
  const history = useHistory();
  const role = getRole();
  const isSuperAdmin = useMemo(() => role === SUPER_ADMIN, [role]);
  const config = useMemo(() => (isSuperAdmin ? drawerInfoAdmin(t) : drawerInfoUser(t)), [isSuperAdmin, t]);

  useEffect(() => {
    const id = getSelectedMenuItemId();
    setSelectedMenuItem(id);
  }, [setSelectedMenuItem, selectedMenuItemId]);

  const handleClick = useCallback(
    id => {
      if (openIds.includes(id)) {
        setOpenedMenu(openIds.filter(item => item !== id));
        setOpenIds(openIds.filter(item => item !== id));
      }

      if (!openIds.includes(id)) {
        setOpenedMenu([...openIds, id]);
        setOpenIds([...openIds, id]);
      }
    },
    [openIds],
  );

  const handleMenuItemClick = useCallback(
    item => {
      setSelectedMenuItem(item.id);
      setSelectedMenuItemId(item.id);
      history.push(item.path);
    },
    [history],
  );

  const getAvatarIcon = data => {
    const { icon } = data;
    switch (icon) {
      case 'Settings':
        return <SettingsOutlinedIcon />;
      case 'Science':
        return <ScienceOutlinedIcon />;
      default:
        return null;
    }
  };

  return (
    <Box sx={{ display: 'flex' }}>
      <Drawer
        variant="permanent"
        sx={{
          width: '240px',
          flexShrink: 0,
          [`& .MuiDrawer-paper`]: { width: '240px', boxSizing: 'border-box' },
        }}
      >
        <Toolbar />
        <Box sx={{ overflow: 'auto' }}>
          <List sx={{ width: '100%', maxWidth: 360 }} component="nav">
            {config.map(data => (
              <div key={data.id}>
                <ListItemButton onClick={() => handleClick(data.id)}>
                  <ListItemIcon>{getAvatarIcon(data)}</ListItemIcon>
                  <ListItemText primary={data.name} />
                  {openIds.includes(data.id) ? <ExpandLess /> : <ExpandMore />}
                </ListItemButton>
                <Collapse in={openIds.includes(data.id)} timeout="auto" unmountOnExit>
                  {data.components.map(
                    item =>
                      role &&
                      item?.roles.includes(role) && (
                        <List component="div" disablePadding key={item.name}>
                          <ListItemButton
                            sx={{ pl: 4 }}
                            onClick={() => handleMenuItemClick(item)}
                            selected={Number(selectedMenuItem) === item.id}
                          >
                            <ListItemText primary={item.name} />
                          </ListItemButton>
                        </List>
                      ),
                  )}
                </Collapse>
                <Divider />
              </div>
            ))}
          </List>
        </Box>
      </Drawer>
    </Box>
  );
}
