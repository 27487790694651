import styled from 'styled-components';
import { Paper, TextField, Typography } from '@mui/material';

type ContentContainerProps = {
  largeSize?: boolean;
  ml?: boolean;
};

export const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 25px;
`;

export const PageHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
`;

export const Title = styled.p`
  margin: auto 0;
  font-style: normal;
  font-weight: 700;
  font-size: 28px;
`;

export const PaperContainer = styled(Paper)``;

export const BlockTitle = styled(Typography)`
  padding: 30px;
`;

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  width: 60%;
  margin-left: 10px;
`;

export const Wrapper = styled(Container)`
  margin-bottom: 20px;
  width: 200px;
`;

export const Text = styled(Container)`
  flex-direction: column;
  width: 100%;
`;

export const Error = styled.div`
  color: ${({ theme }) => theme.colors.red};
  text-align: left;
  width: 100%;
`;

export const ContentWrapper = styled(Container)<ContentContainerProps>`
  width: ${({ largeSize }) => (largeSize ? '350px' : '200px')};
  margin: ${({ ml }) => (ml ? '0 0 20px 100px' : '0 0 20px 20px')};
`;

export const ButtonWrapper = styled.div`
  margin-right: 10px;
`;

export const PasswordTextField = styled(TextField)`
  .css-b52kj1::-ms-reveal,
  .css-19qh8xo-MuiInputBase-input-MuiOutlinedInput-input::-ms-reveal {
    display: none;
  }
`;
