import React from 'react';
import { Dialog, DialogContent, IconButton, Radio, RadioGroup } from '@mui/material';
import ClearOutlinedIcon from '@mui/icons-material/ClearOutlined';
import DoneOutlinedIcon from '@mui/icons-material/DoneOutlined';
import RestartAltOutlinedIcon from '@mui/icons-material/RestartAltOutlined';
import Button from 'components/Button';
import CloseIcon from '@mui/icons-material/Close';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Filter } from 'typings/entities/users';
import { theme } from 'theme';
import * as S from '../styled';

type Props = {
  open: boolean;
  close: () => void;
  handleFilterChange: (props: Filter) => void;
  handleResetFilter: () => void;
};

const Modal = ({ open, close, handleFilterChange, handleResetFilter }: Props) => {
  const { t } = useTranslation('common');

  const {
    handleSubmit,
    control,
    reset,
    formState: { isSubmitting, errors },
  } = useForm();

  const onReset = () => {
    reset();
    handleResetFilter();
  };

  return (
    <Dialog onClose={close} open={open} fullWidth maxWidth="sm">
      <S.ModalTitle>
        {t('userListPage.filter')}
        <IconButton onClick={close}>
          <CloseIcon />
        </IconButton>
      </S.ModalTitle>
      <DialogContent>
        <form onSubmit={handleSubmit(handleFilterChange)}>
          <Controller
            render={({ field }) => (
              <RadioGroup aria-label="status" {...field}>
                <S.RadioContainer>
                  <S.RadioLabel>{t('userListPage.activeStatusLabel')}</S.RadioLabel>
                  <S.RadioControlLabel value="1" control={<Radio />} label={t('userListPage.activeStatus')} />
                  <S.RadioControlLabel value="2" control={<Radio />} label={t('userListPage.inactiveStatus')} />
                </S.RadioContainer>
              </RadioGroup>
            )}
            name="status"
            control={control}
          />
          <Controller
            render={({ field }) => (
              <RadioGroup aria-label="role" {...field}>
                <S.RadioContainer>
                  <S.RadioLabel>{t('userListPage.roleLabel')}</S.RadioLabel>
                  <S.RadioControlLabel value="Admin" control={<Radio />} label={t('userListPage.adminRole')} />
                  <S.RadioControlLabel value="User" control={<Radio />} label={t('userListPage.userRole')} />
                </S.RadioContainer>
              </RadioGroup>
            )}
            name="role"
            control={control}
          />
          <S.ButtonContainer>
            <S.ButtonWrapper>
              <Button
                textButton={t('saveButton')}
                buttonProps={{
                  bgColor: theme.colors.primary,
                  hoverColor: theme.colors.primaryShadow,
                  variant: 'contained',
                  type: 'submit',
                  disabled: isSubmitting,
                  startIcon: <DoneOutlinedIcon />,
                }}
              />
            </S.ButtonWrapper>
            <S.ButtonWrapper>
              <Button
                textButton={t('clientListPage.resetButton')}
                buttonProps={{
                  bgColor: theme.colors.red,
                  hoverColor: theme.colors.redShadow,
                  variant: 'outlined',
                  onClick: onReset,
                  disabled: isSubmitting,
                  startIcon: <RestartAltOutlinedIcon />,
                }}
              />
            </S.ButtonWrapper>
            <S.ButtonWrapper>
              <Button
                textButton={t('cancelButton')}
                buttonProps={{
                  bgColor: theme.colors.grey,
                  hoverColor: theme.colors.greyShadow,
                  variant: 'outlined',
                  onClick: close,
                  disabled: isSubmitting,
                  startIcon: <ClearOutlinedIcon />,
                }}
              />
            </S.ButtonWrapper>
          </S.ButtonContainer>
        </form>
      </DialogContent>
    </Dialog>
  );
};

export default Modal;
