import React from 'react';
import { useTranslation } from 'react-i18next';

import AdminDetailsForm from './AdminDetailsForm';
import PasswordForm from '../User/PassForm';
import * as S from '../styled';

const UserProfile = () => {
  const { t } = useTranslation('common');

  return (
    <S.PageContainer>
      <S.PageHeader>
        <S.Title> {t('profilePage.title')}</S.Title>
      </S.PageHeader>
      <S.PaperContainer elevation={3}>
        <S.BlockTitle>{t('profilePage.editDetails')}</S.BlockTitle>
        <AdminDetailsForm />
        <S.BlockTitle sx={{ paddingTop: 10 }}>{t('profilePage.editPassword')}</S.BlockTitle>
        <PasswordForm />
      </S.PaperContainer>
    </S.PageContainer>
  );
};

export default UserProfile;
