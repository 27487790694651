import React, { useState, useMemo, useEffect } from 'react';
import { useAsyncFn } from 'react-use';
import { toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import ChevronLeftOutlinedIcon from '@mui/icons-material/ChevronLeftOutlined';
import ChevronRightOutlinedIcon from '@mui/icons-material/ChevronRightOutlined';
import DoneOutlinedIcon from '@mui/icons-material/DoneOutlined';
import Button from 'components/Button';
import * as Sentry from '@sentry/browser';

import * as http from 'http/etl';
import Loader from 'components/Loader';
import { pageNumberSelector, settingsForPostSelector, allSettingsSelector } from 'redux/etl/selectors';
import { actions } from 'redux/etl';
import { getError } from 'utils/error';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import { theme } from 'theme';

import useModalState from 'hooks/useModalState';
import FirstRule from './FirstRule';
import SecondRule from './SecondRule';
import ThirdRule from './ThirdRule';
import Modal from './Modal';

import * as S from '../styled';

const St4 = () => {
  const { t } = useTranslation('common');
  const dispatch = useDispatch();
  const pageNumber = useSelector(pageNumberSelector);
  const settingsForPost = useSelector(settingsForPostSelector);
  const allSettings = useSelector(allSettingsSelector);
  const { isOpen, onToggle } = useModalState();
  const [nameSelectedField, setNameSelectedField] = useState<string>('');
  const [state, setState] = useState<any>([]);
  const fields = useMemo(
    () =>
      Object.keys(settingsForPost.mapping.map)
        .map(item => ({
          name: item,
          newName: settingsForPost.mapping.map[item],
        }))
        .filter(item => item.newName !== 'ignore'),
    [settingsForPost],
  );

  useEffect(() => {
    setState(fields);
  }, [fields]);

  useEffect(() => {
    const defaultValues: any = [];
    if (settingsForPost?.transformation) {
      fields.forEach((field: any) => {
        if (settingsForPost.transformation[field.newName]) {
          const rule = settingsForPost.transformation[field.newName];

          const column: any = {
            name: field.name,
          };

          if (rule?.converted) {
            // eslint-disable-next-line
            column['firstRule'] = [];

            rule?.converted.forEach(item => {
              column.firstRule.push({
                id: item.id,
                selectedDateFrom: item.from,
              });
            });
          }

          if (rule?.replace) {
            // eslint-disable-next-line
            column['secondRule'] = [];
            // eslint-disable-next-line
            column['thirdRule'] = [];

            rule?.replace.forEach((item: any) => {
              if (item.type === 'secondRule') {
                column.secondRule.push(item);
              } else {
                column.thirdRule.push(item);
              }
            });
          }

          defaultValues.push(column);
        }
      });

      setState(defaultValues);
    }
  }, [settingsForPost, fields]);

  // eslint-disable-next-line consistent-return
  const [postTransformationState, postTransformation] = useAsyncFn(async () => {
    try {
      const transformation = {};
      state.forEach((item: any) => {
        const mapName = settingsForPost.mapping.map[item.name];
        transformation[mapName] = {
          converted: [],
          replace: [],
        };

        if (item?.firstRule && item.firstRule.length > 0) {
          const newConverted = item.firstRule.map(rule => ({ id: rule.id, from: rule.selectedDateFrom }));
          transformation[mapName].converted.push(...newConverted);
        }

        if (item?.secondRule && item.secondRule.length > 0) {
          transformation[mapName].replace.push(...item.secondRule);
        }

        if (item?.thirdRule && item.thirdRule.length > 0) {
          transformation[mapName].replace.push(...item.thirdRule);
        }
      });

      const res = await http.postTransformation({ ...settingsForPost, transformation });

      dispatch(actions.setTransformation(res));
      dispatch(actions.setSettingsForPost({ ...settingsForPost, transformation }));
      dispatch(actions.setPageNumber(pageNumber + 1));
    } catch (err: any) {
      Sentry.captureException(new Error(getError(err).error));
      toast.error(getError(err).error);
    }
  }, [state]);

  const onOpenModal = (nameField: string) => {
    setNameSelectedField(nameField);
    onToggle();
  };

  const onCloseModal = () => {
    onToggle();
  };

  const handleAddRule = (props: any) => {
    if (state.find((item: any) => item?.name === nameSelectedField)) {
      const column = state.map((item: any) => {
        if (item?.name === nameSelectedField) {
          const key = Object.keys(props)[0];

          if (item[key]) {
            const newData = [...item[key], ...props[key]];

            return { ...item, [key]: newData };
          }

          return { ...item, ...props };
        }

        return item;
      });

      setState(column);
      onCloseModal();
      return;
    }

    setState([...state, { name: nameSelectedField, ...props }]);
    onCloseModal();
  };

  const handleDateFromChange = (nameFilde, rule, id) => e => {
    const column = state.map((item: any) => {
      if (item?.name === nameFilde) {
        const arr = item[rule].map(i => {
          if (i.id === id) {
            return { ...i, selectedDateFrom: e.target.value };
          }

          return i;
        });

        return { ...item, [rule]: arr };
      }

      return item;
    });

    setState(column);
  };

  const handleNextPageNumber = () => {
    postTransformation();
  };

  const handleBackPageNumber = () => {
    dispatch(actions.setPageNumber(pageNumber - 1));
  };

  const handleReplaceConditionsChange = (nameFilde, rule, id) => e => {
    const column = state.map((item: any) => {
      if (item?.name === nameFilde) {
        const arr = item[rule].map(i => {
          if (i.id === id) {
            return { ...i, condition: e.target.value };
          }

          return i;
        });

        return { ...item, [rule]: arr };
      }

      return item;
    });

    setState(column);
  };

  const handleCurrentValueChange = (nameFilde, rule, id) => e => {
    const column = state.map((item: any) => {
      if (item?.name === nameFilde) {
        const arr = item[rule].map(i => {
          if (i.id === id) {
            return { ...i, compare: e.target.value };
          }

          return i;
        });

        return { ...item, [rule]: arr };
      }

      return item;
    });

    setState(column);
  };

  const handleTargetValueChange = (nameFilde, rule, id) => e => {
    const column = state.map((item: any) => {
      if (item?.name === nameFilde) {
        const arr = item[rule].map(i => {
          if (i.id === id) {
            return { ...i, replace: e.target.value };
          }

          return i;
        });

        return { ...item, [rule]: arr };
      }

      return item;
    });

    setState(column);
  };

  const isSelectedRule = (field, rule) => {
    return state.find(item => item.name === field)?.[rule];
  };

  const getDateRule = (field, rule, id) => {
    return state.find(item => item.name === field)?.[rule].find(item => item.id === id);
  };

  const removeRule = (nameFilde, rule, id) => {
    const newState = state.map(item => {
      if (item.name !== nameFilde) {
        return item;
      }

      return { ...item, [rule]: item[rule].filter(i => i.id !== id) };
    });

    setState(newState);
  };

  return (
    <S.Container>
      {postTransformationState.loading && <Loader />}
      <Modal
        open={isOpen}
        close={onCloseModal}
        handleAddRule={handleAddRule}
        isSelectedRule={isSelectedRule}
        nameSelectedField={nameSelectedField}
      />
      <S.TitleContainer>
        <S.MainTitle>{t('etl.step4.title')}</S.MainTitle>
      </S.TitleContainer>
      <S.PaperContainer elevation={3}>
        <S.Title>{t('etl.step4.subTitle')}</S.Title>
        {state.map((field: any) => (
          <S.TransformationWrapper>
            <S.Title>{field.name}</S.Title>
            <S.Container>
              {isSelectedRule(field.name, 'firstRule') &&
                field.firstRule.map(item => (
                  <S.WrapperRule>
                    <FirstRule
                      handleDateFromChange={handleDateFromChange(field.name, 'firstRule', item.id)}
                      data={getDateRule(field.name, 'firstRule', item.id)}
                    />
                    <IconButton onClick={() => removeRule(field.name, 'firstRule', item.id)}>
                      <DeleteIcon />
                    </IconButton>
                  </S.WrapperRule>
                ))}
              {isSelectedRule(field.name, 'secondRule') &&
                field.secondRule.map(item => (
                  <S.WrapperRule>
                    <SecondRule
                      handleReplaceConditionsChange={handleReplaceConditionsChange(field.name, 'secondRule', item.id)}
                      handleCurrentValueChange={handleCurrentValueChange(field.name, 'secondRule', item.id)}
                      handleTargetValueChange={handleTargetValueChange(field.name, 'secondRule', item.id)}
                      replaceConditions={allSettings.replaceConditions}
                      data={getDateRule(field.name, 'secondRule', item.id)}
                    />
                    <IconButton onClick={() => removeRule(field.name, 'secondRule', item.id)}>
                      <DeleteIcon />
                    </IconButton>
                  </S.WrapperRule>
                ))}
              {isSelectedRule(field.name, 'thirdRule') &&
                field.thirdRule.map(item => (
                  <S.WrapperRule>
                    <ThirdRule
                      handleReplaceConditionsChange={handleReplaceConditionsChange(field.name, 'thirdRule', item.id)}
                      handleCurrentValueChange={handleCurrentValueChange(field.name, 'thirdRule', item.id)}
                      handleTargetValueChange={handleTargetValueChange(field.name, 'thirdRule', item.id)}
                      replaceConditions={allSettings.replaceConditionsForStrings}
                      data={getDateRule(field.name, 'thirdRule', item.id)}
                    />
                    <IconButton onClick={() => removeRule(field.name, 'thirdRule', item.id)}>
                      <DeleteIcon />
                    </IconButton>
                  </S.WrapperRule>
                ))}
            </S.Container>
            <S.RuleContainer>
              <Button
                textButton={t('etl.step4.addRule')}
                buttonProps={{
                  bgColor: theme.colors.primary,
                  hoverColor: theme.colors.primaryShadow,
                  variant: 'contained',
                  disabled: false,
                  onClick: () => onOpenModal(field.name),
                  startIcon: <DoneOutlinedIcon />,
                }}
              />
            </S.RuleContainer>
          </S.TransformationWrapper>
        ))}
        <S.ContentContainer mx>
          <S.ButtonWrapper>
            <Button
              textButton={t('back')}
              buttonProps={{
                bgColor: theme.colors.grey,
                hoverColor: theme.colors.greyShadow,
                variant: 'outlined',
                disabled: postTransformationState.loading || false,
                onClick: handleBackPageNumber,
                startIcon: <ChevronLeftOutlinedIcon />,
              }}
            />
          </S.ButtonWrapper>
          <S.ButtonWrapper>
            <Button
              textButton={t('next')}
              buttonProps={{
                bgColor: theme.colors.primary,
                hoverColor: theme.colors.primaryShadow,
                variant: 'contained',
                onClick: handleNextPageNumber,
                disabled: postTransformationState.loading || false,
                startIcon: <ChevronRightOutlinedIcon />,
              }}
            />
          </S.ButtonWrapper>
        </S.ContentContainer>
      </S.PaperContainer>
    </S.Container>
  );
};

export default St4;
