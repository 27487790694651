import React, { useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import { useAsyncFn } from 'react-use';
import { toast } from 'react-toastify';
import { TextField } from '@mui/material';
import { Controller, useForm } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';
import { yupResolver } from '@hookform/resolvers/yup';
import { useTranslation } from 'react-i18next';
import ClearOutlinedIcon from '@mui/icons-material/ClearOutlined';
import DoneOutlinedIcon from '@mui/icons-material/DoneOutlined';
import * as Sentry from '@sentry/browser';

import Button from 'components/Button';
import { putUserProfileDetails } from 'http/profile';
import { UserProfileDetails } from 'typings/entities/profile';
import { getError } from 'utils/error';
import { getUser, setUser } from 'services/localStorage';
import convertingUndefinedToString from 'utils/convertingUndefinedToString';
import { theme } from 'theme';

import validationSchema from './validationSchema';
import * as S from '../../styled';

const UserDetailsForm = () => {
  const profileData = getUser();
  const history = useHistory();
  const { t } = useTranslation('common');
  const schema = useMemo(() => validationSchema(t), [t]);

  const [, updateUserProfileDetails] = useAsyncFn(async (req: UserProfileDetails) => {
    try {
      const newReq: any = convertingUndefinedToString(req);

      const data = {
        firstname: newReq.firstname,
        lastname: newReq.lastname,
        phone: newReq?.phone,
        email: newReq.email,
      };

      const result = await putUserProfileDetails(data);
      setUser(result.data);
    } catch (err: any) {
      Sentry.captureException(new Error(getError(err).error));
      toast.error(getError(err).error);
    }
  }, []);

  const defaultValues = {
    firstname: profileData.firstname,
    email: profileData.email,
    lastname: profileData.lastname,
    phone: profileData.phone,
  };

  const {
    handleSubmit,
    control,
    formState: { isSubmitting, errors },
  } = useForm<UserProfileDetails>({
    resolver: yupResolver(schema),
    defaultValues,
  });

  const handleEditProfileInfo = (req: any) => {
    updateUserProfileDetails(req);
  };

  const handleFormCancel = () => {
    history.goBack();
  };

  return (
    <form onSubmit={handleSubmit(handleEditProfileInfo)}>
      <S.Container>
        <S.Wrapper>
          <S.Text>
            <Controller
              name="firstname"
              control={control}
              render={({ field: { onChange, value } }) => (
                <TextField
                  InputLabelProps={{
                    shrink: true,
                    required: true,
                  }}
                  size="small"
                  onChange={onChange}
                  value={value}
                  error={!!errors.firstname}
                  label={t('profilePage.firstNameLabel')}
                  disabled={isSubmitting}
                />
              )}
            />
            <S.Error>
              <ErrorMessage name="firstname" errors={errors} />
            </S.Error>
          </S.Text>
        </S.Wrapper>
        <S.Wrapper>
          <S.Text>
            <Controller
              name="email"
              control={control}
              render={({ field: { onChange, value } }) => (
                <TextField
                  InputLabelProps={{
                    shrink: true,
                    required: true,
                  }}
                  size="small"
                  type="email"
                  onChange={onChange}
                  value={value}
                  error={!!errors.email}
                  label={t('profilePage.emailLabel')}
                  disabled={isSubmitting}
                />
              )}
            />
            <S.Error>
              <ErrorMessage name="email" errors={errors} />
            </S.Error>
          </S.Text>
        </S.Wrapper>
      </S.Container>
      <S.Container>
        <S.Wrapper>
          <S.Text>
            <Controller
              name="lastname"
              control={control}
              render={({ field: { onChange, value } }) => (
                <TextField
                  InputLabelProps={{
                    shrink: true,
                    required: true,
                  }}
                  size="small"
                  onChange={onChange}
                  value={value}
                  error={!!errors.lastname}
                  label={t('profilePage.lastNameLabel')}
                  disabled={isSubmitting}
                />
              )}
            />
            <S.Error>
              <ErrorMessage name="lastname" errors={errors} />
            </S.Error>
          </S.Text>
        </S.Wrapper>
        <S.Wrapper>
          <S.Text>
            <Controller
              name="phone"
              control={control}
              render={({ field: { onChange, value } }) => (
                <TextField
                  InputLabelProps={{
                    shrink: true,
                  }}
                  size="small"
                  onChange={onChange}
                  value={value}
                  error={!!errors.phone}
                  label={t('profilePage.phoneLabel')}
                  disabled={isSubmitting}
                />
              )}
            />
            <S.Error>
              <ErrorMessage name="phone" errors={errors} />
            </S.Error>
          </S.Text>
        </S.Wrapper>
      </S.Container>
      <S.Container>
        <S.ContentWrapper largeSize>
          <S.ButtonWrapper>
            <Button
              textButton={t('saveButton')}
              buttonProps={{
                bgColor: theme.colors.primary,
                hoverColor: theme.colors.primaryShadow,
                variant: 'contained',
                disabled: isSubmitting,
                type: 'submit',
                startIcon: <DoneOutlinedIcon />,
              }}
            />
          </S.ButtonWrapper>
          <S.ButtonWrapper>
            <Button
              textButton={t('cancelButton')}
              buttonProps={{
                bgColor: theme.colors.grey,
                hoverColor: theme.colors.greyShadow,
                variant: 'outlined',
                disabled: isSubmitting,
                onClick: handleFormCancel,
                startIcon: <ClearOutlinedIcon />,
              }}
            />
          </S.ButtonWrapper>
        </S.ContentWrapper>
      </S.Container>
    </form>
  );
};

export default UserDetailsForm;
