import { SessionUser } from 'typings/entities/user';

export type AuthState = {
  isAuthenticated: boolean;
  profile: SessionUser | null;
  isSuperAdmin: boolean;
};

const initialState: AuthState = {
  isAuthenticated: false,
  profile: null,
  isSuperAdmin: false,
};

export const authenticate = (state: AuthState, { payload }: { payload: SessionUser }) => ({
  ...state,
  isAuthenticated: true,
  profile: payload,
});

export const setRoleSuperAdmin = (state: AuthState) => ({
  ...state,
  isSuperAdmin: true,
});

export const signOut = (state: AuthState) => ({
  ...state,
  isAuthenticated: false,
  profile: null,
});

export default initialState;
