import styled from 'styled-components';
import { Paper, Typography, DialogTitle, FormControlLabel } from '@mui/material';

export const Container = styled.div`
  padding: 25px;
`;

export const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const PaperContainer = styled(Paper)`
  display: flex;
  flex-wrap: wrap;
`;

export const ContentBox = styled.div`
  width: 49%;
`;

export const RadioLabel = styled(Typography)`
  width: 75px;
  margin: auto 20px;
`;

export const ContentRadioLabel = styled(Typography)`
  width: 300px;
  margin: -10px 0;
  color: rgba(0, 0, 0, 0.6);
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
  font-weight: 400;
  font-size: 13px;
  line-height: 1.4375em;
`;

export const RadioContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 5px;
  margin-left: -5px;
`;

export const RadioControlLabel = styled(FormControlLabel)`
  width: 120px;
`;

export const Error = styled.div`
  color: ${({ theme }) => theme.colors.red};
  text-align: left;
  margin-left: ${({ ml }: { ml?: boolean }) => (ml ? '20px' : '0')};
`;

export const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-right: 10px;
  margin-top: 50px;
`;

export const ModalTitle = styled(DialogTitle)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 10px;
`;

export const EmptyField = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  height: 100%;
`;

export const ButtonWrapper = styled.div`
  margin-right: 10px;
  margin-left: 10px;
  margin-bottom: 10px;
`;
