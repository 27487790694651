import { useState } from 'react';
import { useAsyncFn } from 'react-use';
import { toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import ClearOutlinedIcon from '@mui/icons-material/ClearOutlined';
import DoneOutlinedIcon from '@mui/icons-material/DoneOutlined';
import PageviewOutlinedIcon from '@mui/icons-material/PageviewOutlined';
import * as Sentry from '@sentry/browser';

import Button from 'components/Button';
import * as http from 'http/etl';
import Loader from 'components/Loader';
import { theme } from 'theme';
import { settingsForPostSelector, resultDataSelector } from 'redux/etl/selectors';
import useModalState from 'hooks/useModalState';
import { actions } from 'redux/etl';
import { getError } from 'utils/error';
import { getRole } from 'services/localStorage';
import { SWITCH_TO_USER } from 'constants/roles';
import Table from '../Table';
import Modal from './Modal';

import * as S from '../styled';

const St6 = () => {
  const { t } = useTranslation('common');
  const dispatch = useDispatch();
  const { isOpen, onToggle } = useModalState();
  const resultData = useSelector(resultDataSelector);
  const settingsForPost = useSelector(settingsForPostSelector);
  const role = getRole();
  const isSuperAdmin = role === SWITCH_TO_USER;
  const [isSaveTemplate, setIsSaveTemplate] = useState(true);

  const [postTemplateState, postTemplate] = useAsyncFn(
    async name => {
      try {
        const res = await http.postTemplate({
          name,
          conditions: settingsForPost,
          fileType: settingsForPost.fileUpload.importTemplateType,
        });

        onToggle();
      } catch (err: any) {
        Sentry.captureException(new Error(getError(err).error));
        toast.error(getError(err).error);
      }
    },
    [dispatch, actions, isOpen],
  );

  const onClose = () => {
    dispatch(actions.clear());
    dispatch(actions.setPageNumber(1));
  };

  const handleAddTemplate = (name: string) => {
    postTemplate(name);
  };

  const handleModal = (bool: boolean) => {
    setIsSaveTemplate(bool);
    onToggle();
  };

  return (
    <S.Container>
      {postTemplateState.loading && <Loader />}
      <Modal
        open={isOpen}
        close={handleModal}
        isSaveTemplate={isSaveTemplate}
        onAddTemplate={handleAddTemplate}
        sql={resultData.resultData}
      />
      <S.TitleContainer>
        <S.MainTitle>{t('etl.step6.title')}</S.MainTitle>
      </S.TitleContainer>
      <S.PaperContainer elevation={3}>
        <S.SubTitle>
          <S.Title>{t('etl.step6.importStatus')}</S.Title>
          <div>
            {resultData.added} {t('etl.step6.recordsImported')}
            <br />
            {resultData.errors} {t('etl.step6.recordsIgnored')}
          </div>
        </S.SubTitle>
        {resultData && (
          <>
            {resultData.data.length === 0 ? (
              <S.NoResult>{t('noResults')}</S.NoResult>
            ) : (
              <S.TableContainer>
                <Table data={resultData.data} />
              </S.TableContainer>
            )}
            <S.ContentContainer mx>
              <S.ButtonWrapper>
                <Button
                  textButton={t('closeButton')}
                  buttonProps={{
                    bgColor: theme.colors.grey,
                    hoverColor: theme.colors.greyShadow,
                    variant: 'outlined',
                    disabled: false,
                    onClick: onClose,
                    startIcon: <ClearOutlinedIcon />,
                  }}
                />
              </S.ButtonWrapper>
              <S.ButtonWrapper>
                <Button
                  textButton={t('etl.step6.saveTemplate')}
                  buttonProps={{
                    bgColor: theme.colors.primary,
                    hoverColor: theme.colors.primaryShadow,
                    variant: 'contained',
                    disabled: false,
                    onClick: () => handleModal(true),
                    startIcon: <DoneOutlinedIcon />,
                  }}
                />
              </S.ButtonWrapper>
              {isSuperAdmin && (
                <S.ButtonWrapper>
                  <Button
                    textButton={t('etl.step6.viewDebugInfo')}
                    buttonProps={{
                      bgColor: theme.colors.primary,
                      hoverColor: theme.colors.primaryShadow,
                      variant: 'contained',
                      disabled: false,
                      onClick: () => handleModal(false),
                      startIcon: <PageviewOutlinedIcon />,
                    }}
                  />
                </S.ButtonWrapper>
              )}
            </S.ContentContainer>
          </>
        )}
      </S.PaperContainer>
    </S.Container>
  );
};

export default St6;
